import React from 'react';
import { connect } from 'react-redux';
// import CopyExportButton from '../atoms/CopyExportButton';
import CsvExportButton from '../atoms/CsvExportButton';
import ExcelExportButton from '../atoms/ExcelExportButton';
// import PdfExportButton from '../atoms/PdfExportButton';
import { format } from '@jmdigital/iservice3-utils';

const ExportButtons = ({
    currentPage,
    dataArrayOfObjects,
    entityName,
    fields,
    filters,
    searchTerm,
    size = 'sm',
}) => {
    const visibleColumns = fields.reduce((all, current) => {
        if (current.selector && !current.omit) all.push(current.selector);
        return all;
    }, []);

    const exportValueGetters = fields.reduce((all, current) => {
        if (Object.keys(current).includes('exportValueGetter'))
            all[current.selector] = current.exportValueGetter;
        return all;
    }, {});

    const headers = fields.reduce((all, current) => {
        if (current.selector && !current.omit) all.push(current.selector);
        return all;
    }, []);

    const filterOutObjectsNotInFields = dataArrayOfObjects?.map((object) =>
        visibleColumns.reduce((all, current, index) => {
            all[headers[index]] = exportValueGetters[current]
                ? exportValueGetters[current](object)
                : object[current] === null ||
                  object[current] === undefined ||
                  object[current] === NaN
                ? ''
                : object[current];
            return all;
        }, {})
    );

    const parsedFilters = filters
        ?.map((filter) => {
            const filterValue =
                filter.name === 'dateRange'
                    ? filter.value
                          .map((date) => {
                              return format.date(date).replace(/\//g, '-');
                          })
                          .join(' to ')
                    : filter.value;
            return `${filter.label}=${filterValue}`;
        })
        .join('__');

    const filename = `${entityName.toUpperCase()}__Current Page=${currentPage}${
        searchTerm ? `__Search Term=${searchTerm}` : ''
    }${filters?.length ? `__${parsedFilters}` : ''}`;

    return dataArrayOfObjects.length ? (
        <>
            <span className="m-1">Export:</span>
            {/* <CopyExportButton
                dataArrayOfObjects={filterOutObjectsNotInFields}
                filename={filename}
                size={size}
            /> */}
            <ExcelExportButton
                dataArrayOfObjects={filterOutObjectsNotInFields}
                filename={filename}
                size={size}
            />
            <CsvExportButton
                dataArrayOfObjects={filterOutObjectsNotInFields}
                filename={filename}
                size={size}
            />
            {/* <PdfExportButton
                dataArrayOfObjects={filterOutObjectsNotInFields}
                filename={filename}
                size={size}
            /> */}
        </>
    ) : null;
};

const mapStateToProps = (state) => ({
    currentPage: state.member.currentPage,
    filters: state.member.filters,
    searchTerm: state.member.searchTerm,
});

export default connect(mapStateToProps)(ExportButtons);
