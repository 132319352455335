import React, { useEffect, useState, useRef } from 'react';
import AsyncSelect from 'react-select/async';

const SearchableAsyncSelect = ({
    cancelAutoFocus,
    canCreateNew,
    className,
    containerStyles,
    customStyles,
    defaultInputValue,
    defaultValue,
    getOptions,
    getOptionsParams,
    optionsReducer,
    pageLimit = 10,
    placeholder = 'Search',
    reset = null,
    setIsModalOpen,
    setNewName,
    setNewPhone,
    handleSelectedOption,
    sortField,
    ...rest
}) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState();
    const timeoutRef = useRef();
    const selectRef = useRef();
    const pageRef = useRef(1);

    useEffect(() => {
        getDefaultOptions();
    }, []);

    useEffect(() => {
        selectRef.current.select.select.clearValue();
    }, [reset]);

    const getDefaultOptions = async () => {
        try {
            const response = await loadOptions();
            setOptions(response);
        } catch (error) {
            console.error(error);
        }
    };

    const loadOptions = async (
        value,
        page = 1,
        setCurrentPage = () => null
    ) => {
        if (value) setCurrentPage(1);
        setIsLoading(true);
        clearTimeout(timeoutRef.current);
        const promise = new Promise(async (resolve, reject) => {
            timeoutRef.current = await setTimeout(async () => {
                const params = {
                    page,
                    searchTerm: value,
                    sortDirection: 'ASC',
                    sortField,
                    ...getOptionsParams,
                };
                !!pageLimit && (params.pageLimit = pageLimit);
                try {
                    const response = await getOptions(params);
                    if (!response?.data?.results) throw false;
                    if (response.data.currentPage < page) {
                        pageRef.current = page;
                        resolve([]);
                    }
                    const options = response.data.results.reduce(
                        optionsReducer,
                        []
                    );
                    if (canCreateNew) {
                        options.unshift({
                            label: 'Create New',
                            value: 'CREATE NEW',
                        });
                    }
                    setIsLoading(false);
                    page > response.data.totalPages &&
                        setCurrentPage(response.data.totalPages);
                    resolve(options);
                } catch (error) {
                    setIsLoading(false);
                    console.error(error);
                    reject();
                }
            }, 1000);
        });
        return await promise;
    };

    const loadNextPage = async () => {
        pageRef.current++;
        const responseOptions = await loadOptions(
            null,
            pageRef.current,
            (page) => {
                pageRef.current = page;
            }
        );
        if (!responseOptions.length) return;
        setOptions((prevState) => [...prevState, ...responseOptions]);
    };

    return (
        <AsyncSelect
            autoFocus={!cancelAutoFocus}
            cacheOptions
            className={className}
            defaultInputValue={defaultInputValue}
            defaultOptions={options}
            defaultValue={defaultValue}
            isLoading={isLoading}
            loadOptions={(value) =>
                loadOptions(value, 1, (page) => (pageRef.current = page))
            }
            onInputChange={setInputValue}
            onChange={(selected) => {
                if (selected?.value === 'CREATE NEW') {
                    if (
                        inputValue &&
                        inputValue.length ===
                            inputValue.replace(/[^\+\(\)\-\d]/g, '').length
                    ) {
                        setNewPhone(inputValue.replace(/\D/g, ''));
                    } else {
                        setNewName(inputValue);
                    }

                    setIsModalOpen(true);
                } else if (selected) {
                    handleSelectedOption(selected.value);
                }
            }}
            onMenuScrollToBottom={loadNextPage}
            placeholder={placeholder}
            ref={selectRef}
            {...rest}
        />
    );
};
export default SearchableAsyncSelect;
