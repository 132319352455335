import { format } from '@jmdigital/iservice3-utils';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import CustomerForm from './CustomerForm';
import CustomModalBody from '../atoms/CustomModalBody';
import CustomModalHeader from '../atoms/CustomModalHeader';
import SearchableAsyncSelect from '../atoms/SearchableAsyncSelect';
import { getCustomers } from '../../../redux/actions/member';

const CustomerAsyncSelect = ({
    cancelAutoFocus,
    canCreateNew,
    getCustomers,
    pageLimit = 10,
    placeholder = 'Search',
    reset = null,
    handleSelectCustomer,
    sortField,
    ...rest
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newName, setNewName] = useState();
    const [newPhone, setNewPhone] = useState();

    const customersReducer = (total, customer) => {
        const label = `${customer.name} - ${format.phoneNumber(
            customer.phone
        )}`;
        total.push({ label, value: customer });
        return total;
    };

    return (
        <>
            <Modal isOpen={isModalOpen} keyboard scrollable size="md">
                <CustomModalHeader
                    title="Create New Customer"
                    toggle={() => setIsModalOpen(false)}
                />
                <CustomModalBody>
                    <CustomerForm
                        createOnly
                        handleSelectCustomer={handleSelectCustomer}
                        newName={newName}
                        newPhone={newPhone}
                        toggle={() => setIsModalOpen(false)}
                    />
                </CustomModalBody>
            </Modal>
            <SearchableAsyncSelect
                cancelAutoFocus={cancelAutoFocus}
                canCreateNew={canCreateNew}
                getOptions={getCustomers}
                optionsReducer={customersReducer}
                pageLimit={pageLimit}
                placeholder={placeholder}
                reset={reset}
                setIsModalOpen={setIsModalOpen}
                setNewName={setNewName}
                setNewPhone={setNewPhone}
                handleSelectedOption={handleSelectCustomer}
                sortField={sortField}
                {...rest}
            />
        </>
    );
};

export default connect(null, { getCustomers })(CustomerAsyncSelect);
