import React from 'react';
import { ModalFooter } from 'reactstrap';

const styles = {
    defaultBg: {
        backgroundColor: '#fff',
    },
};

const CustomModalFooter = ({ children, additionalProps }) => {
    return (
        <ModalFooter style={styles.defaultBg} {...additionalProps}>
            {children}
        </ModalFooter>
    );
};

export default CustomModalFooter;
