import React from 'react';
import { Button } from 'reactstrap';

export const convertArrayOfObjectsToCSV = (arrayOfObjects) => {
    const columns = Object.keys(arrayOfObjects[0]);
    const headers = columns
        .map((column) => column.replace(/#/g, 'Number'))
        .map((column) => `"${column}"`);

    return arrayOfObjects.reduce((total, current) => {
        return (
            total +
            columns
                .map(
                    (key, index) =>
                        `"${current[key]}"` +
                        (index < columns.length - 1 ? ',' : '\n')
                )
                .join('')
        );
    }, headers.join(',') + '\n');
};

export const downloadCSV = (arrayOfObjects, filename) => {
    if (!Array.isArray(arrayOfObjects) || !arrayOfObjects.length) return;
    const link = document.createElement('a');
    const csv = `data:text/csv;charset=utf-8,${convertArrayOfObjectsToCSV(
        arrayOfObjects
    )}`;

    link.href = encodeURI(csv);
    link.download = filename;
    link.click();
};

const CsvExportButton = ({ dataArrayOfObjects, filename, size }) => {
    return (
        <Button.Ripple
            className="mr-1"
            color="primary"
            size={size}
            onClick={() => downloadCSV(dataArrayOfObjects, `${filename}.csv`)}
        >
            CSV
        </Button.Ripple>
    );
};

export default CsvExportButton;
