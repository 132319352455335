import React from 'react';
import { Button, Modal } from 'reactstrap';
import CustomModalBody from '../atoms/CustomModalBody';
import CustomModalFooter from '../atoms/CustomModalFooter';
import CustomModalHeader from '../atoms/CustomModalHeader';

const ConfirmCancelModal = ({ handleCancel, isOpen, toggle }) => {
    return (
        <Modal isOpen={isOpen} keyboard scrollable toggle={toggle}>
            <CustomModalHeader
                title="Are you sure you want to discard these changes?"
                toggle={toggle}
            />
            <CustomModalBody>
                You will lose any unsaved changes.
            </CustomModalBody>
            <CustomModalFooter>
                <Button className="mr-1" color="secondary" onClick={toggle}>
                    No, Continue Editing
                </Button>
                <Button color="danger" onClick={handleCancel}>
                    Yes, Discard Changes
                </Button>
            </CustomModalFooter>
        </Modal>
    );
};

export default ConfirmCancelModal;
