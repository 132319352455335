import React from 'react';
import { CheckCircle, Edit, Eye, Plus, Trash2, XSquare } from 'react-feather';

/*
 *   Props:
 *       type: string ('edit', 'delete', 'add', 'check', default = 'ok')
 *       handleClick: function
 *
 *   If handleClick is not provided, this button is invisible to clicks
 *   In other words, the underlying element will get the click
 */

const IconButton = ({ className, type, handleClick }) => {
    const style = handleClick
        ? { cursor: 'pointer' }
        : { pointerEvents: 'none' };

    return (
        <div
            className={'d-flex ' + (className || '')}
            onClick={handleClick}
            style={style}
        >
            <span className="mr-2 text-primary" color="primary" style={style}>
                {type === 'edit' ? (
                    <span
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Edit style={style} />
                        <b>Edit</b>
                    </span>
                ) : type === 'delete' ? (
                    <span
                        className="text-danger"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Trash2 style={style} />
                        <b>Delete</b>
                    </span>
                ) : type === 'add' ? (
                    <>
                        <Plus style={style} /> Add
                    </>
                ) : type === 'check' ? (
                    <CheckCircle style={style} />
                ) : type === 'close' ? (
                    <>
                        <XSquare style={style} />
                    </>
                ) : type === 'openDetails' ? (
                    <>
                        <Eye style={style} />
                    </>
                ) : (
                    <>
                        <CheckCircle style={style} /> OK
                    </>
                )}
            </span>
        </div>
    );
};
export default IconButton;
