import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Award } from 'react-feather';
import { Col, Input, Row } from 'reactstrap';

const defaultColor = '#fffff';

const ColorPicker = ({
    disabled,
    field,
    handleChange,
    isChanged,
    isValid,
    required,
    value,
}) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value || defaultColor);
    }, [value]);

    if (disabled) {
        return <h3>Color Picker Disabled</h3>;
    }

    const { selector } = field;
    const color = value || defaultColor;

    return (
        <Row>
            <Col className="d-flex flex-column justify-content-between">
                <HexColorPicker
                    color={color}
                    onChange={(hexValue) => {
                        handleChange(selector, hexValue);
                    }}
                />

                <div
                    style={{
                        borderLeft: '24px solid #000',
                        borderLeftColor: color,
                        color,
                        marginTop: '20px',
                        paddingLeft: '10px',
                    }}
                >
                    Current color is {color}
                </div>
                <Input
                    disabled={disabled}
                    invalid={!isValid}
                    className={`form-control ${
                        !isValid
                            ? 'border-danger'
                            : isChanged
                            ? 'border-warning'
                            : ''
                    }`}
                    onChange={({ target }) => {
                        const isValidHex = /#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/.test(
                            target.value
                        );

                        if (isValidHex) {
                            handleChange(selector, target.value);
                        } else {
                            setInputValue(target.value);
                        }
                    }}
                    maxLength={7}
                    required={required}
                    type="text"
                    value={inputValue}
                />
            </Col>
            <Col>
                <div
                    className="h-100"
                    style={{
                        background:
                            'radial-gradient(circle, rgb(174, 233, 238) 0%, rgb(98, 120, 185) 80%)',
                        overflow: 'hidden',
                    }}
                >
                    <div style={{ height: '33%', padding: '5%' }}>
                        <h4 style={{ color }}>test test test test</h4>
                    </div>
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '28%' }}
                    >
                        <Award size={'100%'} color={color} />
                    </div>
                    <div className="text-center" style={{ height: '33%' }}>
                        <h4
                            style={{
                                color,
                                textShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px',
                            }}
                        >
                            test
                        </h4>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default ColorPicker;
