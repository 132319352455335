import { format } from '@jmdigital/iservice3-utils';
import { handleError } from './index';
import { showErrorToast, showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';

export const createPaymentRequest = (repairOrderId, amountCents) => async (
    dispatch
) => {
    dispatch({ type: 'CREATE_PAYMENT_REQUEST' });
    dispatch({ type: 'LOADING_START' });

    try {
        const amount = amountCents / 100;
        const body = { repairOrderId, amount };
        const response = await clientApi.post(Endpoints.paymentsRO, body);

        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });
            showToast({
                message: `Payment request for ${format.money(amount)} created`,
            });

            dispatch(getPayments(repairOrderId));
            dispatch({ type: 'RO_OUTDATED' });
        } else {
            throw new Error(
                `Failed to create payment request.${CONTACT_MESSAGE}`
            );
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const confirmPayment = (repairOrderPaymentId, notBatched) => async (
    dispatch
) => {
    dispatch({ type: 'CONFIRM_PAYMENT' });
    const endpoint = `${Endpoints.paymentsRO}/confirm`;

    try {
        const body = { repairOrderPaymentId };
        const response = await clientApi.post(endpoint, body);

        if (response.status === 200) {
            dispatch({ type: 'PAYMENT_CONFIRMED' });
            // Cannot mark ROs stale here because confirmation is done in batches
            // Instead it is dispatched after awaiting the Promise.all() in the component

            // Except if guaranteed to be a single confirm
            if (notBatched) {
                dispatch({ type: 'RO_OUTDATED' });
            }
        } else {
            // makePayment doesn't dispatch outdated
            dispatch({ type: 'RO_OUTDATED' });

            throw new Error(`Failed to confirm payment.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const deletePaymentRequest = (
    repairOrderId,
    repairOrderPaymentId
) => async (dispatch) => {
    dispatch({ type: 'DELETE_PAYMENT_REQUEST' });
    dispatch({ type: 'LOADING_START' });

    try {
        const endpoint = `${Endpoints.paymentsRO}/${repairOrderPaymentId}`;
        const response = await clientApi.delete(endpoint);

        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });
            showToast({ message: 'Successfully deleted' });

            dispatch({ type: 'RECORD_DELETED' });
            dispatch(getPayments(repairOrderId));

            // RO status may have changed
            dispatch({ type: 'RO_OUTDATED' });
        } else {
            throw new Error(`Failed to delete video.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const getPayments = (repairOrderId) => async (dispatch) => {
    dispatch({ type: 'GET_PAYMENTS' });
    dispatch({ type: 'LOADING_START' });

    try {
        const params = { repairOrderId };
        const response = await clientApi.get(Endpoints.paymentsRO, { params });

        if (response.status === 200) {
            dispatch({ type: 'RECEIVE_PAYMENTS', data: response.data.results });
            dispatch({ type: 'LOADING_END' });
        } else {
            throw new Error(`Failed to get payments.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const makePayment = (repairOrderPaymentId, paymentToken, card) => async (
    dispatch
) => {
    dispatch({ type: 'MAKE_PAYMENT' });
    dispatch({ type: 'LOADING_START' });

    try {
        const endpoint = `${Endpoints.paymentsRO}/pay`;
        const body = { repairOrderPaymentId, paymentToken };

        if (card) {
            body.cardNumber = card.number;
            body.cardType = card.cardType;
        }

        const response = await clientApi.post(endpoint, body);

        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });
            showToast({ message: 'Payment success' });

            dispatch(confirmPayment(repairOrderPaymentId, true));

            // Do not dispatch RO_OUTDATED to avoid double GET ROs request following confirmPayment
        } else {
            throw new Error(`Failed to send payment.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const paymentFormErrored = () => (dispatch) => {
    showErrorToast({
        message:
            'Unable to contact payments server. To enable payments, try refreshing the page in a few minutes. If you continue to see this message, please contact support.',
    });
    dispatch({ type: 'PAYMENT_FORM_ERRORED' });
};

export const paymentFormLoaded = () => (dispatch) => {
    dispatch({ type: 'PAYMENT_FORM_LOADED' });
};

export const refundPayment = (
    repairOrderId,
    repairOrderPaymentId,
    amountCents
) => async (dispatch) => {
    dispatch({ type: 'REFUND_PAYMENT' });
    dispatch({ type: 'LOADING_START' });

    try {
        const endpoint = `${Endpoints.paymentsRO}/refund`;
        const amount = (amountCents / 100).toString();
        const body = {
            repairOrderPaymentId,
            amount,
        };
        const response = await clientApi.post(endpoint, body);
        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });
            showToast({ message: `${format.money(amount)} refunded` });

            dispatch(getPayments(repairOrderId));
            dispatch({ type: 'RO_OUTDATED' });
        } else {
            throw new Error(`Failed to refund payment.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const sendPaymentReceipt = (repairOrderPaymentId, email) => async (
    dispatch
) => {
    dispatch({ type: 'SEND_PAYMENT_RECEIPT' });
    dispatch({ type: 'LOADING_START' });

    try {
        const endpoint = `${Endpoints.paymentsRO}/send-receipt`;
        const body = { repairOrderPaymentId, email };
        const response = await clientApi.post(endpoint, body);

        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });
            showToast({ message: 'Receipt sent' });
        } else {
            throw new Error(`Failed to send receipt.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const sendPaymentRequest = (
    repairOrderId,
    repairOrderPaymentId
) => async (dispatch) => {
    dispatch({ type: 'SEND_PAYMENT_REQUEST' });
    dispatch({ type: 'LOADING_START' });

    try {
        const endpoint = `${Endpoints.paymentsRO}/send`;
        const body = { repairOrderPaymentId };
        const response = await clientApi.post(endpoint, body);

        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });
            showToast({ message: 'Payment request sent' });

            dispatch(getPayments(repairOrderId));
            dispatch({ type: 'RO_OUTDATED' });
        } else {
            throw new Error(
                `Failed to send payment request.${CONTACT_MESSAGE}`
            );
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};
