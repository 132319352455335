import React, { useState } from 'react';
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Media,
} from 'reactstrap';
import {
    ChevronDown,
    Circle,
    LifeBuoy,
    MessageSquare,
    PlusSquare,
    Power,
    User,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import AccountInformationModal from '../../../components/custom/modals/AccountInformationModal';
import SupportFormModal from '../../../components/custom/modals/SupportFormModal';
import ChatModal from '../../../pages/chat/ChatModal';
import { signout } from '../../../redux/actions/auth';
import { chatChannels } from '../../../utility/constants';
import {
    toggleChatModal,
    updateMessageType,
    getRecords,
} from '../../../redux/actions/member';

const UserDropdown = ({ 
    signout, 
    toggleMemberModal, 
    toggleSupportModal, 
    settings 
}) => {
    return (
        <DropdownMenu className="w-100" right>
            <DropdownItem href="#" onClick={toggleMemberModal} tag="a">
                <User className="mr-50" size={14} />
                <span className="align-middle">Edit Account</span>
            </DropdownItem>
            <DropdownItem divider />

            <DropdownItem href="#" onClick={toggleSupportModal} tag="a">
                <LifeBuoy className="mr-50" size={14} />
                <span className="align-middle">Support</span>
            </DropdownItem>
            <DropdownItem divider />

            <DropdownItem href="#" onClick={signout} tag="a">
                <Power className="mr-50" size={14} />
                <span className="align-middle">Log Out</span>
            </DropdownItem>
        </DropdownMenu>
    );
};

const NavbarUser = ({
    isChatModalOpen,
    messageType,
    toggleChatModal,
    updateMessageType,
    unreadMessageCounts,
    userName,
    userRole,
    settings,
    ...additionalProps
}) => {
    const [memberModalIsOpen, setMemberModalIsOpen] = useState(false);
    const [supportModalIsOpen, setSupportModalIsOpen] = useState(false);
    const [userProfileModalIsOpen, setUserProfileModalIsOpen] = useState(false);

    const toggleMemberModal = () => {
        setMemberModalIsOpen(!memberModalIsOpen);
    };
    const toggleSupportModal = () => {
        setSupportModalIsOpen(!supportModalIsOpen);
    };

    const toggleUserProfileModal = () => {
        setUserProfileModalIsOpen(!userProfileModalIsOpen);
    };

    const totalUnreadMessages = Object.values(unreadMessageCounts).reduce(
        (all, current) => +all + +current,
        0
    );

    const showChatChannel = (channel, settings) => {
        let iServiceActivated = settings?.iServiceActivated === '1';
        return channel.permissions.includes(userRole) &&
            channel.name === 'Service SMS' ? iServiceActivated : true;
    };
    
    
    return (
        <ul className="nav navbar-nav navbar-nav-user float-right">
            <SupportFormModal
                isOpen={supportModalIsOpen}
                toggleModal={toggleSupportModal}
            />
            <AccountInformationModal
                isOpen={memberModalIsOpen}
                toggle={toggleMemberModal}
            />
            <ChatModal
                isOpen={isChatModalOpen}
                messageType={messageType}
                toggle={toggleChatModal}
            />
            <UncontrolledDropdown
                className="dropdown-notification nav-item"
                tag="li"
            >
                <DropdownToggle
                    className="nav-link nav-link-label py-0"
                    tag="a"
                >
                    <MessageSquare
                        size={33}
                        style={{ margin: 0, padding: 0 }}
                    />
                    <span
                        style={{
                            fontSize: 10,
                            left: '64%',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translate(-64%, -50%)',
                        }}
                    >
                        <b>{totalUnreadMessages}</b>
                    </span>
                    {totalUnreadMessages > 0 && (
                        <Circle
                            className="badge-up bg-danger rounded text-danger"
                            size={13}
                            style={styles.notificationBadge}
                        />
                    )}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-media" right tag="ul">
                    <li className="dropdown-menu-header">
                        <div className="dropdown-header mt-0">
                            <h3 className="text-white">Chat Notifications</h3>
                            <span className="notification-title">{''}</span>
                        </div>
                    </li>
                    <PerfectScrollbar
                        className="media-list overflow-hidden position-relative"
                        options={{
                            wheelPropagation: false,
                        }}
                    >
                        {chatChannels.map(
                            (channel) =>
                                showChatChannel(channel, settings) && (
                                    <div
                                        className="d-flex justify-content-between"
                                        key={channel.id}
                                        onClick={(event) => {
                                            toggleChatModal();
                                            updateMessageType(channel.name);
                                        }}
                                    >
                                        <Media className="d-flex align-items-start">
                                            <Media left href="#">
                                                <PlusSquare
                                                    className={
                                                        channel.id === 1
                                                            ? 'font-medium-5 info'
                                                            : channel.id === 3
                                                            ? 'font-medium-5 success'
                                                            : 'font-medium-5 primary'
                                                    }
                                                    size={21}
                                                />
                                            </Media>
                                            <Media body>
                                                <Media
                                                    className={
                                                        channel.id === 1
                                                            ? 'media-heading info'
                                                            : channel.id === 3
                                                            ? 'media-heading success'
                                                            : 'media-heading primary'
                                                    }
                                                    heading
                                                    tag="h6"
                                                >
                                                    {channel.name}
                                                </Media>
                                                <p className="notification-text">
                                                    View Conversations
                                                </p>
                                            </Media>
                                            <b style={{ marginRight: 3 }}>
                                                {
                                                    unreadMessageCounts[
                                                        channel.key
                                                    ]
                                                }
                                            </b>
                                            {unreadMessageCounts[channel.key] >
                                                0 && (
                                                <small>
                                                    <Circle
                                                        className="bg-danger float-right rounded text-danger"
                                                        size={10}
                                                    />
                                                </small>
                                            )}
                                        </Media>
                                    </div>
                                )
                        )}
                    </PerfectScrollbar>
                </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown
                className="align-items-center d-flex dropdown-user nav-item"
                tag="li"
            >
                <DropdownToggle
                    className="dropdown-user-link nav-link py-0"
                    style={{ marginTop: '-5px' }}
                    tag="a"
                >
                    <div className="d-none d-sm-flex user-nav align-items-center">
                        <span className="text-bold-600 user-name m-0">
                            {userName}
                        </span>
                    </div>
                    <ChevronDown size={20} />
                </DropdownToggle>
                <UserDropdown
                    {...additionalProps}
                    toggleSupportModal={toggleSupportModal}
                    toggleMemberModal={toggleMemberModal}
                    toggleUserProfileModal={toggleUserProfileModal}
                />
            </UncontrolledDropdown>
        </ul>
    );
};

const styles = {
    notificationBadge: {
        position: 'absolute',
        right: '-3px',
        top: '18px',
    },
};
const mapStateToProps = (state) => ({
    isChatModalOpen: state.member.isChatModalOpen,
    messageType: state.member.messageType,
    unreadMessageCounts: state.member.unreadMessageCounts,
    username: state.auth?.login?.username,
    userRole: state.auth.login.userRole,
    settings: state.member.settings,
});

export default connect(mapStateToProps, {
    getRecords,
    signout,
    toggleChatModal,
    updateMessageType,
})(NavbarUser);
