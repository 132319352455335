import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import CustomForm from './CustomForm';
import ConfirmCancelModal from '../modals/ConfirmCancelModal';
import CustomModalHeader from '../atoms/CustomModalHeader';
import CustomModalBody from '../atoms/CustomModalBody';

const AutoFormModal = ({
    cancelFlag,
    createRecord,
    deleteRecord,
    entityName,
    fields,
    isOpen,
    requestParams,
    selectedRow,
    setCancelFlag,
    toggle,
    updateRecord,
}) => {
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const handleCancel = () => {
        setShowConfirmCancel(false);
        toggle();
    };
    const handleDelete = (record) => {
        deleteRecord(record);
        toggle();
    };

    return (
        <>
            <ConfirmCancelModal
                isOpen={showConfirmCancel}
                handleCancel={handleCancel}
                toggle={() => setShowConfirmCancel(false)}
            />
            <Modal
                isOpen={isOpen}
                scrollable
                toggle={() =>
                    cancelFlag ? setShowConfirmCancel(true) : handleCancel()
                }
            >
                <CustomModalHeader
                    title={`${entityName} > ${
                        selectedRow ? `Edit` : 'Create New'
                    }`}
                    toggle={() =>
                        cancelFlag ? setShowConfirmCancel(true) : handleCancel()
                    }
                ></CustomModalHeader>
                <CustomModalBody>
                    <CustomForm
                        cancelFlag={cancelFlag}
                        fields={fields}
                        handleCancel={toggle}
                        handleDelete={!!selectedRow && handleDelete}
                        handleSubmit={(record) =>
                            selectedRow
                                ? updateRecord(record)
                                : createRecord(record)
                        }
                        record={selectedRow}
                        requestParams={requestParams}
                        setCancelFlag={setCancelFlag}
                        setShowConfirmCancel={setShowConfirmCancel}
                        toggle={toggle}
                    />
                </CustomModalBody>
            </Modal>
        </>
    );
};

export default AutoFormModal;
