import { handleError } from './index';
import { showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';

export const deleteMpi = (id) => async (dispatch) => {
    dispatch({ type: 'DELETE_RECORD' });
    try {
        const response = await clientApi.delete(`${Endpoints.mpi}/${id}`);
        if (response.status === 200) {
            showToast({ message: 'Successfully deleted' });
            dispatch({ type: 'RECORD_DELETED' });
            dispatch({ type: 'RO_OUTDATED' });
            return true;
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const deleteMpiTemplateGroup = (id) => async (dispatch) => {
    dispatch({ type: 'DELETE_RECORD' });
    try {
        const response = await clientApi.delete(`${Endpoints.mPIGroup}/${id}`);
        if (response.status === 200) {
            showToast({ message: 'Successfully deleted' });
            dispatch({ type: 'RECORD_DELETED' });
            return true;
        } else {
            throw new Error(`Failed to delete record.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const deleteMpiTemplateItem = (id) => async (dispatch) => {
    dispatch({ type: 'DELETE_RECORD' });
    try {
        const response = await clientApi.delete(`${Endpoints.mPIItem}/${id}`);
        if (response.status === 200) {
            showToast({ message: 'Successfully deleted' });
            dispatch({ type: 'RECORD_DELETED' });
            return true;
        } else {
            throw new Error(`Failed to delete record.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const getMpiTemplates = () => async (dispatch) => {
    dispatch({ type: 'GET_MPI_TEMPLATES' });
    try {
        const endpoint = Endpoints.mpiTemplates;
        const response = await clientApi.get(endpoint);
        if (response.status === 200) {
            dispatch({
                type: 'RECEIVE_MPI_TEMPLATES',
                data: response.data,
            });
            return response;
        } else {
            throw new Error(`Failed to get MPI templates.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return [];
    }
};

export const getMpiTemplateById = (templateId) => async (dispatch) => {
    dispatch({ type: 'GET_MPI_TEMPLATE_BY_ID' });
    try {
        const response = await clientApi.get(
            `${Endpoints.mpiTemplates}/${templateId}`
        );
        if (response.status === 200) {
            dispatch({
                type: 'RECEIVE_MPI_TEMPLATE_BY_ID',
                data: response.data,
            });
        } else {
            throw new Error(`Failed to get MPI template.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const setSelectedMpiTemplate = (template) => async (dispatch) => {
    dispatch({ type: 'UPDATE_SELECTED_MPI_TEMPLATE', data: template });
};
export const submitMpiResults = (mpiResults, roId) => async (dispatch) => {
    dispatch({ type: 'SUBMIT_MPI_RESULTS' });
    try {
        const response = await clientApi.post(Endpoints.mpi, {
            results: JSON.stringify(mpiResults),
            repairOrderID: roId,
        });
        if (response.status === 200) {
            showToast({ message: 'Submitted successfully' });
            dispatch({ type: 'MPI_RESULTS_SUBMITTED' });
            dispatch({ type: 'RO_OUTDATED' });
        } else {
            throw new Error(`Failed to submit MPI results.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const submitMpiTemplate = (template) => async (dispatch) => {
    dispatch({ type: 'CREATE_RECORD' });
    try {
        let response;
        if (template.id) {
            response = await clientApi.put(
                `${Endpoints.mpiTemplates}/${template.id}`,
                template
            );
        } else {
            response = await clientApi.post(Endpoints.mpiTemplates, template);
        }
        dispatch({ type: 'RECORD_CREATED' });
        if (response.status === 200) {
            showToast({ message: 'Success' });
            return response;
        }
        throw new Error('Failed to save/update');
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const submitMpiTemplateGroup = (group) => async (dispatch) => {
    dispatch({ type: 'CREATE_RECORD' });
    try {
        let response;
        if (group.id) {
            response = await clientApi.put(
                `${Endpoints.mPIGroup}/${group.id}`,
                group
            );
        } else {
            response = await clientApi.post(Endpoints.mPIGroup, {
                ...group,
                id: group.templateId,
            });
        }
        dispatch({ type: 'RECORD_CREATED' });
        if (response.status === 200) {
            showToast({ message: 'Group created' });
            return response;
        }
        throw new Error('Failed to save/update');
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const submitMpiTemplateItem = (item) => async (dispatch) => {
    dispatch({ type: 'CREATE_RECORD' });
    try {
        let response;
        if (item.id) {
            response = await clientApi.put(
                `${Endpoints.mPIItem}/${item.id}`,
                item
            );
        } else {
            response = await clientApi.post(Endpoints.mPIItem, {
                ...item,
                id: item.groupId,
            });
        }
        dispatch({ type: 'RECORD_CREATED' });
        if (response.status === 200) {
            showToast({ message: 'Item created' });
            return response;
        }
        throw new Error('Failed to save/update');
    } catch (error) {
        dispatch(handleError(error));
    }
};
