import React, { useEffect, useState } from 'react';

const ImageFileInput = ({ field, handleChange, value, isValid, required }) => {
    const [imgSrc, setImgSrc] = useState('');
    const [isBrokenUrl, setIsBrokenUrl] = useState(false);

    useEffect(() => {
        setIsBrokenUrl(false);
        if (typeof value === 'object') {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImgSrc(e.target.result);
            };
            reader.readAsDataURL(value);
        }
        if (value && typeof value === 'string') {
            // String values should be URLs
            setImgSrc(value);
        }
    }, [value]);
    return (
        <div>
            {isBrokenUrl ? (
                <p className="text-danger">
                    (existing image link is broken; try uploading again)
                </p>
            ) : !!imgSrc ? (
                <div className="w-100 text-center">
                    <img
                        alt=""
                        className="mt-1"
                        onError={() => setIsBrokenUrl(true)}
                        src={imgSrc}
                        style={{ height: 'auto', maxWidth: '500px' }}
                    />
                </div>
            ) : null}
            {(!imgSrc || !field?.formOptions?.createOnly) && (
                <input
                    accept="image/gif, image/jpeg, image/png"
                    className={!isValid ? 'border-danger form-control' : ''}
                    id={field.selector}
                    onChange={(e) => {
                        const file = e.target.files[0];
                        handleChange(field.selector, file);
                    }}
                    required={required}
                    type="file"
                />
            )}
        </div>
    );
};

export default ImageFileInput;
