import { format } from '@jmdigital/iservice3-utils';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import {
    refundPayment,
    sendPaymentRequest,
} from '../../../../redux/actions/member/payments-nonro';
import ConfirmationModal from '../../modals/ConfirmationModal';
import PaymentModal from '../../modals/PaymentModal';
import RefundModal from '../../modals/RefundModal';

// NON RO ONLY TODO CHANGE NAME
const PaymentActions = ({
    record,
    refundPayment,
    requestParams,
    sendPaymentRequest,
    toggle,
}) => {
    const [openConfirmModalName, setOpenConfirmModalName] = useState('');
    const [refundAmountCents, setRefundAmountCents] = useState('');
    if (!record) return null;

    const fullyRefunded =
        record.refundedAmount == record.amount &&
        record.refundedAmount != '0.00';

    return (
        <>
            <ConfirmationModal
                handleConfirm={async () => {
                    await sendPaymentRequest(record.id, requestParams.type);

                    setOpenConfirmModalName('');
                    toggle();
                }}
                isOpen={openConfirmModalName === 'sendToCustomer'}
                message={`You are sending a payment request of ${format.money(
                    record?.amount
                )} to the customer.`}
                title={'Send Payment Request?'}
                toggle={() => {
                    setOpenConfirmModalName('');
                }}
            />
            <PaymentModal
                isOpen={openConfirmModalName === 'makePayment'}
                payment={record}
                repairOrder={{}}
                toggle={() => {
                    setOpenConfirmModalName('');
                }}
                toggleParent={toggle}
                type={requestParams.type}
            />
            <RefundModal
                amountCents={refundAmountCents}
                handleConfirm={async () => {
                    await refundPayment(
                        record.id,
                        refundAmountCents,
                        requestParams.type
                    );
                    setRefundAmountCents(0);
                    setOpenConfirmModalName('');
                    toggle();
                }}
                handleChange={(integerCents) => {
                    setRefundAmountCents(integerCents);
                }}
                isOpen={openConfirmModalName === 'refundPayment'}
                // isReadOnly={isReadOnly}
                payment={record}
                toggle={() => {
                    setRefundAmountCents(0);
                    setOpenConfirmModalName('');
                }}
                type="non-ro"
            />
            <Button
                className="mb-1"
                block
                color="success"
                onClick={() => {
                    setOpenConfirmModalName('sendToCustomer');
                }}
            >
                Send to Customer
            </Button>
            <Row>
                <Col>
                    <Button
                        block
                        disabled={!!record.transactionId}
                        color="primary"
                        onClick={() => {
                            setOpenConfirmModalName('makePayment');
                        }}
                    >
                        Pay
                    </Button>
                </Col>
                <Col>
                    <Button
                        block
                        color="primary"
                        disabled={fullyRefunded || !record.transactionId}
                        onClick={() => {
                            setOpenConfirmModalName('refundPayment');
                        }}
                    >
                        Refund
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default connect(null, { refundPayment, sendPaymentRequest })(
    PaymentActions
);
