import React from 'react';
import { X } from 'react-feather';
import Toggle from 'react-toggle';

const SwitchFilterItem = ({ item, handleChange, handleDelete, noBorder }) => {
    return (
        <div
            className="border-primary rounded d-flex align-items-center mr-1"
            style={{
                height: '48px',
                padding: '0 0.25em 0 0.25em',
                marginBottom: '5px',
            }}
        >
            <label className="d-flex align-items-center mr-1">
                <Toggle
                    className="mr-1"
                    checked={item.value === true}
                    onChange={() => {
                        handleChange(item, { ...item, value: !item.value });
                    }}
                />
                <span>{`${item.label}`}</span>
            </label>
            {!!handleDelete && (
                <X
                    className="filter-item-x font-weight-bold"
                    onClick={() => handleDelete(item)}
                    size={24}
                />
            )}
        </div>
    );
};

export default SwitchFilterItem;
