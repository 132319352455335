import React from 'react';
import { format } from '@jmdigital/iservice3-utils';

const ChatMessage = ({ isOutgoing, message, user = {} }) => {
    const userName =
        user?.firstName || user?.lastName
            ? `${user?.firstName || ''} ${user?.lastName || ''}`
            : user?.phone;
    const date = format.parseDate(message.date);
    const localeString = date
        ? date.toLocaleString('en-US', {
              timeZoneName: 'short',
          })
        : '';
    return (
        <div
            className={`position-relative ${isOutgoing ? 'sent' : 'received'}`}
        >
            <div
                style={
                    isOutgoing
                        ? styles.chatNameAndTimeSent
                        : styles.chatNameAndTime
                }
            >
                <div style={styles.chatName}>{userName}</div>
                <div style={styles.chatTimestamp}>{localeString}</div>
            </div>
            <div style={isOutgoing ? styles.sentMessage : styles.chatMessage}>
                <p className="m-0">{message.message}</p>
            </div>
        </div>
    );
};

export default ChatMessage;

const styles = {
    sentMessage: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: '500',
        color: '#ffffff',
        padding: '8px',
        width: 'fit-content',
        borderRadius: '10px',
        marginBottom: '30px',
        marginLeft: 'auto',
        backgroundColor: '#1e90ff',
    },
    chatMessage: {
        position: 'relative',
        fontSize: '16px',
        fontWeight: '500',
        padding: '8px',
        width: 'fit-content',
        borderRadius: '10px',
        backgroundColor: '#f5f5f5',
        marginBottom: '30px',
    },
    chatNameAndTime: {
        display: 'flex',
    },
    chatNameAndTimeSent: {
        display: 'flex',
        position: 'relative',
        color: '#ffffff',
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: '5px',
    },
    chatName: {
        fontWeight: '800',
        fontSize: 'x-small',
        color: '#000000',
    },
    chatTimestamp: {
        marginLeft: '10px',
        fontSize: 'xx-small',
        color: '#000000',
    },
};
