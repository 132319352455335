import React from 'react';

const CheckboxInput = ({
    disabled,
    field,
    handleChange,
    isChanged,
    value,
    required,
}) => {
    const isValid =
        typeof value === 'boolean' ||
        value === 'true' ||
        value === 'false' ||
        value === '1' ||
        value === '0';
    const addtlClasses = !isValid
        ? 'border-danger'
        : isChanged
        ? 'border-warning'
        : '';

    return (
        <>
            <hr className={`m-0 p-0 ${addtlClasses}`} />
            <input
                checked={value === true || value === 'true' || value === '1'}
                className="ml-1"
                disabled={disabled}
                id={field.selector}
                onChange={(e) => handleChange(field.selector, e.target.checked)}
                required={required}
                style={{ height: '3em', transform: 'scale(2)' }}
                type="checkbox"
            />
        </>
    );
};

export default CheckboxInput;
