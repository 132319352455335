import React, { useState } from 'react';
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Mail, MessageSquare, Phone, User } from 'react-feather';
import CustomModalBody from '../atoms/CustomModalBody';
import CustomModalFooter from '../atoms/CustomModalFooter';
import CustomModalHeader from '../atoms/CustomModalHeader';
import validator from '../../../utility/helpers/validation';
import { requestSupport } from '../../../redux/actions/member';
import { showErrorToast } from '../../../utility/toast';
import { format } from '@jmdigital/iservice3-utils';

const SupportFormModal = ({ isOpen, toggleModal, requestSupport }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

    const handleSubmit = async () => {
        setHasAttemptedSubmit(true);
        if (!email || !message || !name || !phone) {
            showErrorToast('Please fill out all fields');
            return;
        }
        const data ={
            name,
            phone, 
            email,
            message
        }

        const result = await requestSupport(data);
        if (result) {
            toggleModal()
            setEmail('');
            setMessage('');
            setName('');
            setPhone('');
            setHasAttemptedSubmit(false);
        };
        return result;

    };
    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggleModal}>
            <Form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit();
                }}
            >
                <CustomModalHeader
                    title="iService Technical Support"
                    scrollable
                    toggle={toggleModal}
                />
                <CustomModalBody>
                    <p>
                        Please use the form below for any technical questions.
                    </p>
                    <FormGroup className="mb-0">
                        <Row>
                            <Col sm="4" xs="12">
                                <FormGroup className="form-label-group has-icon-left position-relative">
                                    <Input
                                        invalid={hasAttemptedSubmit && !name}
                                        onChange={({ target }) =>
                                            setName(target.value)
                                        }
                                        placeholder="Name"
                                        required
                                        type="name"
                                        value={name}
                                    />
                                    <div className="form-control-position">
                                        <User size={15} />
                                    </div>
                                    <Label>Name</Label>
                                </FormGroup>
                            </Col>
                            <Col sm="4" xs="12">
                                <FormGroup className="form-label-group has-icon-left position-relative">
                                    <Input
                                        invalid={
                                            hasAttemptedSubmit &&
                                            (!phone ||
                                                !validator({
                                                    type: 'phone',
                                                    value: phone,
                                                }))
                                        }
                                        onChange={({ target }) =>
                                            setPhone(target.value)
                                        }
                                        placeholder="Phone"
                                        required
                                        type="tel"
                                        value={phone}
                                    />
                                    <div className="form-control-position">
                                        <Phone size={15} />
                                    </div>
                                    <Label>Phone</Label>
                                </FormGroup>
                            </Col>
                            <Col sm="4" xs="12">
                                <FormGroup className="form-label-group has-icon-left position-relative">
                                    <Input
                                        invalid={
                                            hasAttemptedSubmit &&
                                            (!email ||
                                                !validator({
                                                    type: 'email',
                                                    value: email,
                                                }))
                                        }
                                        onChange={({ target }) =>
                                            setEmail(target.value)
                                        }
                                        placeholder="Email"
                                        required
                                        type="email"
                                        value={email}
                                    />
                                    <div className="form-control-position">
                                        <Mail size={15} />
                                    </div>
                                    <Label>Email</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className="form-label-group has-icon-left position-relative">
                        <Input
                            invalid={hasAttemptedSubmit && !message}
                            onChange={({ target }) => setMessage(target.value)}
                            placeholder="Message"
                            required
                            type="textarea"
                            value={message}
                        />
                        <div className="form-control-position">
                            <MessageSquare size={15} />
                        </div>
                        <Label>Message</Label>
                    </FormGroup>
                </CustomModalBody>
                <CustomModalFooter>
                    <Button color="danger" onClick={toggleModal}>
                        Close
                    </Button>
                    <Button color="primary" type="submit">
                        Send
                    </Button>
                </CustomModalFooter>
            </Form>
        </Modal>
    );
};

export default connect(null, { requestSupport })( SupportFormModal );
