import { showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';
import { handleError } from './index';

export const addImage = (
    repairOrderID,
    image,
    name,
    note,
    internal = true
) => async (dispatch) => {
    dispatch({ type: 'CREATE_RECORD' });
    const data = {
        repairOrderID,
        image,
        name,
        note,
        internal
    }
    try {
        const response = await clientApi.post(Endpoints.repairOrderImage, data);
        if (response.status === 200) {
            dispatch({ type: 'REPAIR_ORDER_IMAGE_ADDED' });

            showToast({ message: 'Image added' });
            dispatch(getRepairOrderImages(repairOrderID));
            return true;
        } else throw new Error(`Failed to add note.${CONTACT_MESSAGE}`);
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const deleteImage = (id, repairOrderID) => async (dispatch) => {
    dispatch({ type: 'DELETE_RECORD' });

    try {
        const response = await clientApi.delete(
            `${Endpoints.repairOrderImage}/${id}`
        );

        if (response.status === 200) {
            showToast({ message: 'Successfully deleted' });
            dispatch({ type: 'RECORD_DELETED' });
            dispatch(getRepairOrderImages(repairOrderID));
        } else {
            throw new Error(`Failed to delete image.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const editImageDetails = (image, name, note) => async (dispatch) => {
    dispatch({ type: 'UPDATE_RECORD' });
    const { id } = image;
    const updated = {
        name,
        note,
    }
    try {
        const response = await clientApi.patch(
            `${Endpoints.repairOrderImage}/${id}`,
            updated
        );
        if (response.status === 200) {
            showToast({ message: 'Successfully updated' });
            dispatch({ type: 'REPAIR_ORDER_IMAGE_UPDATED' });
            return response;
        } else {
            throw new Error(`Failed to update image details.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const getRepairOrderImages = (repairOrderId, internal = true) => async (
    dispatch
) => {
    dispatch({ type: 'GET_REPAIR_ORDER_IMAGES' });
    const params = {
        repairOrderId,
        internal,
    };
    try {
        const endpoint = Endpoints.repairOrderImage;
        const response = await clientApi.get(endpoint, { params });
        if (response.status === 200) {
            dispatch({
                type: 'RECEIVE_REPAIR_ORDER_IMAGES',
                data: response.data,
            });
            return response;
        } else {
            throw new Error(
                `Failed to get Repair Order Images. ${CONTACT_MESSAGE}`
            );
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};
