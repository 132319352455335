import React from 'react';
import { Input } from 'reactstrap';

const SearchInput = ({
    disabled,
    handleChange,
    handleSearch,
    placeholder,
    searchTerm,
}) => {
    const handleKeyUp = ({ keyCode }) => {
        if (keyCode === 13) handleSearch();
    };
    return (
        <Input
            disabled={!!disabled}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            placeholder={placeholder || 'Search'}
            value={searchTerm}
        />
    );
};

export default SearchInput;
