import { getRoById, handleError } from './index';
import { store } from '../../storeConfig/store';
import { showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';

export const createNote = ({ data, stateProp }) => async (dispatch) => {
    dispatch({ type: 'CREATE_RECORD' });
    try {
        const response = await clientApi.post(Endpoints[stateProp], data);
        if (response.status === 200) {
            dispatch({ type: 'RECORD_CREATED' });
            dispatch(getRoById(store.getState().member.selectedRow.id));

            showToast({ message: 'Note added' });
            return true;
        } else throw new Error(`Failed to add note.${CONTACT_MESSAGE}`);
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const deleteNote = (id) => async (dispatch) => {
    dispatch({ type: 'DELETE_RECORD' });
    try {
        const response = await clientApi.delete(`${Endpoints.roNotes}/${id}`);
        if (response.status === 200) {
            showToast({ message: 'Successfully deleted' });
            dispatch({ type: 'RECORD_DELETED' });
            dispatch(getRoById(store.getState().member.selectedRow.id));
        } else {
            throw new Error(`Failed to delete record.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};
