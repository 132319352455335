import React from 'react';
import * as Icon from 'react-feather';
import { AuthorizedRoutes, Permissions, UserRoles } from '../utility/constants';

const iPayNavigationConfig = [
    {
        children: [
            {
                icon: <Icon.DollarSign size={20} />,
                id: 'financeReporting',
                navLink: AuthorizedRoutes.FINANCE_REPORTING,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Finance Report',
                type: 'item',
            },
            {
                icon: <Icon.BarChart2 size={20} />,
                id: 'partsReporting',
                navLink: AuthorizedRoutes.PARTS_REPORTING,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Parts Report',
                type: 'item',
            },
            {
                icon: <Icon.CreditCard size={20} />,
                id: 'ipay-report',
                navLink: AuthorizedRoutes.IPAY_REPORTING,
                permissions: Permissions.iPayReport,
                title: 'iPay Report',
                type: 'item',
            },
        ],
        icon: <Icon.CreditCard size={20} />,
        id: 'ipay',
        permissions: Permissions.iPayReport,
        title: 'iPay',
        type: 'collapse',
    },
];

export default iPayNavigationConfig;
