import mock from '../mock';

export const searchResult = [
    {
        groupTitle: 'Pages',
        searchLimit: 4,
        data: [
            {
                id: 1,
                target: 'advisor-dashboard',
                title: 'Advisor Dashboard',
                link: '/iservice/advisor',
                icon: 'User',
            },
            {
                id: 2,
                target: 'technician-dashboard',
                title: 'Technician Dashboard',
                link: '/iservice/technician',
                icon: 'Eye',
            },
            {
                id: 3,
                target: 'parts-dashboard',
                title: 'Parts Dashboard',
                link: '/iservice/parts',
                icon: 'Filter',
            },
        ],
    },
    // {
    //   groupTitle: "Users",
    //   searchLimit: 4,
    //   data: [
    //     {
    //       id: 1,
    //       target: "home",
    //       title: "John Doe",
    //       link: "/",
    //       icon: "User"
    //     },
    //   ]
    // }
];

mock.onGet('/api/main-search/data').reply(200, {
    searchResult,
});
