import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap';
import DashboardRepairOrderFilter from '../organisms/DashboardRepairOrderFilter';
import DataTable from 'react-data-table-component';
import { Search } from 'react-feather';
import { connect } from 'react-redux';
import AutoFormModal from './AutoFormModal';
import ExportButtons from '../molecules/ExportButtons';
import {
    createRecord,
    deleteRecord,
    getRecords,
    setFilters,
    toggleCrudModal,
    updateRecord,
    updateTableParameters,
} from '../../../redux/actions/member/index';
import '../../../assets/scss/components/auto-crud.scss';
import CustomPagination from '../atoms/CustomPagination';
import SearchInput from '../atoms/SearchInput';

const PAGE_LIMIT = 50;

const AutoCrud = ({
    additionalProps,
    bodyClassNames,
    children,
    customAddButton,
    createRecord,
    currentPage,
    data,
    defaultSortDirection,
    defaultSortField,
    deleteRecord,
    disableAdd,
    disableEdit,
    disableExport,
    disableSearch,
    entityName,
    fields,
    filterOptions,
    filters,
    forceClear,
    form,
    getRecords,
    headerChildren,
    highlightOnHover = true,
    isCrudModalOpen,
    pagination = true,
    pointerOnHover = true,
    requestParams,
    searchTerm,
    setFilters,
    sortDirection,
    sortField,
    staleRepairOrderIds,
    stateProp,
    title,
    toggleCrudModal,
    totalsRow,
    updateRecord,
    updateTableParameters,
}) => {
    const [initialized, setInitialized] = useState(false);
    const [cancelFlag, setCancelFlag] = useState(null);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    const conditionalRowStyles = [
        {
            when: (row) =>
                (row.hasOwnProperty('mobileConfirmed') &&
                    !row.mobileConfirmed) ||
                (row.primaryCustomer?.hasOwnProperty('mobileConfirmed') &&
                    !row.primaryCustomer.mobileConfirmed),
            style: { backgroundColor: 'pink' },
        },
        {
            // Unpaid transaction, not sent to customer
            when: (row) =>
                row.hasOwnProperty('transactionId') &&
                !row.transactionId &&
                row.hasOwnProperty('status') &&
                row.status !== 'Sent',
            style: { backgroundColor: 'pink' },
        },
        {
            // Unpaid transaction, sent to customer
            when: (row) =>
                row.hasOwnProperty('transactionId') &&
                !row.transactionId &&
                row.hasOwnProperty('status') &&
                (row.status === 'Sent' || row.status === 'Viewed'),
            style: { backgroundColor: '#ffd700' },
        },
        {
            // Totals row
            when: (row) => row.hasOwnProperty('isTotalRow') && row.isTotalRow,
            style: {
                // fontSize: '1.1rem',
                borderStyle: 'solid',
                borderColor: 'grey',
                borderWidth: '2px 0 0 0',
            },
        },
        {
            // Disabled row
            when: (row) =>
                stateProp === 'ros' && staleRepairOrderIds.includes(row.id),
            style: {
                cursor: 'wait',
                opacity: 0.5,
                pointerEvents: 'none',
            },
        },
    ];

    const handleGetRecords = () => {
        return getRecords(
            stateProp,
            {
                ...requestParams,
                pageLimit: PAGE_LIMIT,
            },
            false,
            !!forceClear
        );
    };

    useEffect(() => {
        const visibleColumns = fields.filter((field) => {
            return !field.tableOptions?.hidden;
        });
        const results = data?.results || [];
        const totals = fields.reduce(
            (row, col) => {
                row[col.selector] = col.total ?? '';
                return row;
            },
            { isTotalRow: true }
        );
        const toSetAsRows =
            totalsRow && results.length ? [...results, totals] : results;

        setColumns(visibleColumns);
        setRows(toSetAsRows);
    }, [data, fields, form]);

    useEffect(() => {
        // Ensure filter is empty if autocrud is used without filters
        if (!filterOptions?.length) setFilters([]);

        updateTableParameters({
            currentPage: 1,
            searchTerm: '',
            sortDirection: defaultSortDirection || null,
            sortField: defaultSortField || null,
        });
        setInitialized(true);
    }, [stateProp]);

    useEffect(() => {
        if (initialized) handleGetRecords();
    }, [currentPage, filters, requestParams, sortDirection, sortField]);

    const handleChangePage = (page) => {
        if (currentPage !== page) updateTableParameters({ currentPage: page });
    };

    const handleCreateRecord = (createdDate) => {
        createRecord(createdDate, stateProp, requestParams);
    };

    const handleSearch = () => {
        handleChangePage(1);
        handleGetRecords();
    };

    const handleSort = (row, direction) => {
        updateTableParameters({
            currentPage: 1,
            sortDirection: direction,
            sortField: row?.selector,
        });
    };

    const subHeaderComponent = (
        <div className="align-items-start d-flex flex-column justify-content-center w-100">
            <div className="align-items-center d-flex justify-content-between w-100">
                {!disableSearch ? (
                    <InputGroup style={{ width: 400 }}>
                        <SearchInput
                            handleChange={({ target }) => {
                                updateTableParameters({
                                    searchTerm: target.value,
                                });
                            }}
                            handleSearch={handleSearch}
                            placeholder="Search"
                            searchTerm={searchTerm}
                        />
                        <InputGroupAddon
                            addonType="append"
                            style={{ alignItems: 'center' }}
                        >
                            <Button color="success" onClick={handleSearch}>
                                <Search size={14} />
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                ) : (
                    <span></span> // This is here as a spaceholder so that "Add New" will always be on the right side
                )}
                {!!form && !disableAdd && (
                    <Button.Ripple
                        className="d-flex align-items-center"
                        color="primary"
                        onClick={() => {
                            setSelectedRow(null);
                            toggleCrudModal();
                        }}
                        style={{
                            justifyContent: 'center',
                            overflow: 'auto',
                        }}
                    >
                        <div style={{ whiteSpace: 'nowrap' }}>Add New</div>
                    </Button.Ripple>
                )}
                {!!customAddButton && !disableAdd && customAddButton}
            </div>
            {!!filterOptions?.length && (
                <DashboardRepairOrderFilter
                    filterOptions={filterOptions}
                    handleChangePage={handleChangePage}
                    stateProp={stateProp}
                />
            )}
            {headerChildren}
        </div>
    );
    return (
        <React.Fragment>
            {(!!form || !!customAddButton) && (
                <AutoFormModal
                    cancelFlag={cancelFlag}
                    createRecord={handleCreateRecord}
                    deleteRecord={(deleted) => {
                        deleteRecord(deleted, stateProp, requestParams);
                    }}
                    entityName={entityName}
                    fields={fields}
                    isOpen={isCrudModalOpen}
                    requestParams={requestParams}
                    selectedRow={selectedRow}
                    setCancelFlag={setCancelFlag}
                    toggle={() => {
                        toggleCrudModal();
                        setSelectedRow(null);
                    }}
                    updateRecord={(updated) => {
                        updateRecord(updated, stateProp, requestParams);
                    }}
                />
            )}
            <Card style={{ position: 'relative' }}>
                <CardBody className={bodyClassNames}>
                    {children}
                    <DataTable
                        style={{ minHeight: '300px' }}
                        columns={columns}
                        conditionalRowStyles={conditionalRowStyles}
                        data={rows}
                        highlightOnHover={highlightOnHover}
                        noHeader={!title && !additionalProps?.actions}
                        onSort={handleSort}
                        onRowClicked={(row) => {
                            if (disableEdit || (!form && !customAddButton)) {
                                return;
                            }
                            if (row !== selectedRow) {
                                setSelectedRow(row);
                            }
                            toggleCrudModal();
                        }}
                        pointerOnHover={pointerOnHover}
                        responsive
                        sortServer
                        striped
                        subHeader={
                            !!filterOptions?.length ||
                            !!headerChildren ||
                            !!customAddButton ||
                            !disableAdd ||
                            !disableSearch
                        }
                        fixedHeader
                        fixedHeaderScrollHeight	={'500px'}
                        subHeaderAlign="left"
                        subHeaderComponent={subHeaderComponent}
                        subHeaderWrap={false}
                        title={title}
                        {...additionalProps}
                    />
                </CardBody>
                <CardFooter className="d-flex justify-content-between">
                    <div className="w-100">
                        {!disableExport && (
                            <ExportButtons
                                dataArrayOfObjects={rows}
                                entityName={entityName}
                                fields={fields}
                                size={'sm'}
                            />
                        )}
                    </div>
                    <div className="w-100 text-center">
                        {data.totalResults &&
                            `Total records: ${data.totalResults}`}
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                        {!!pagination && (
                            <CustomPagination
                                currentPage={data.currentPage}
                                handleChangePage={handleChangePage}
                                resultsPerPage={PAGE_LIMIT}
                                totalRows={data.totalResults}
                            />
                        )}
                    </div>
                </CardFooter>
            </Card>
        </React.Fragment>
    );
};

AutoCrud.defaultProps = {
    disableAdd: false,
    disableEdit: false,
    disableExport: false,
    exportPosition: 'footer',
    form: false,
};

AutoCrud.propTypes = {
    additionalProps: PropTypes.any,
    children: PropTypes.node,
    data: PropTypes.object,
    disableAdd: PropTypes.bool,
    disableEdit: PropTypes.bool,
    disableExport: PropTypes.bool,
    entityName: PropTypes.string.isRequired,
    exportPosition: PropTypes.oneOf(['sub-header', 'footer']),
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    form: PropTypes.bool,
    parentEntityName: PropTypes.string,
    stateProp: PropTypes.string,
};

const mapStateToProps = (state) => ({
    currentPage: state.member.currentPage,
    filters: state.member.filters,
    isCrudModalOpen: state.member.isCrudModalOpen,
    searchTerm: state.member.searchTerm,
    sortDirection: state.member.sortDirection,
    sortField: state.member.sortField,
    staleRepairOrderIds: state.member.staleRepairOrderIds,
});

export default connect(mapStateToProps, {
    createRecord,
    deleteRecord,
    getRecords,
    setFilters,
    toggleCrudModal,
    updateRecord,
    updateTableParameters,
})(AutoCrud);
