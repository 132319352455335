import React from 'react';
import { X } from 'react-feather';
import DateRangePicker from '../atoms/DateRangePicker';

const DateFilterItem = ({ item, handleChange, handleDelete }) => {
    return (
        <div
            className="d-flex align-items-center border-primary rounded mr-1"
            style={{ height: '48px', padding: '0 0.25em 0 0.25em', marginBottom: '5px' }}
        >
            <label className="mr-1">
                {item.label}
                <DateRangePicker
                    endDate={item.value[1]}
                    style={{
                        border: 'none',
                        width: '150px',
                        padding: '3.5px',
                    }}
                    handleDateChange={(dates) => {
                        if (!dates[0] || !dates[1]) return;

                        handleChange(item, {
                            ...item,
                            value: [...dates],
                        });
                    }}
                    startDate={item.value[0]}
                    type="range"
                />
            </label>
            <X
                className="filter-item-x font-weight-bold"
                onClick={() => handleDelete(item)}
                size={20}
            />
        </div>
    );
};

export default DateFilterItem;
