import React from 'react';
import { Award, DollarSign, TrendingUp } from 'react-feather';
import { AuthorizedRoutes, UserRoles } from '../utility/constants';

const rewardsNavigationConfig = [
    {
        icon: <TrendingUp size={20} />,
        id: 'rewards-reporting',
        navLink: AuthorizedRoutes.REWARDS_REPORTING,
        permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
        title: 'Reporting',
        type: 'item',
    },
    {
        icon: <DollarSign size={20} />,
        id: 'rewards-billing',
        navLink: AuthorizedRoutes.REWARDS_BILLING,
        permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
        title: 'Billing',
        type: 'item',
    },
];

export default rewardsNavigationConfig;
