import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getMpiTemplates } from '../../../redux/actions/member';
import { X } from 'react-feather';
import SearchableAsyncSelect from './SearchableAsyncSelect';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';

const MpiFilterItem = ({
    item,
    handleChange,
    handleDelete,
    getMpiTemplates,
    handleAdd
}) => {
    const [openMpiItemDropdown, setOpenMpiItemDropdown] = useState(false);
    const [openStatusDropdown, setOpenStatusDropdown] = useState(false);
    const [currentMpiTemplate, setCurrentMpiTemplate] = useState({});
    const [currentMpiItem, setCurrentMpiItem] = useState({});
    const [currentStatus, setCurrentStatus] = useState('');

    const statuses = ['green', 'yellow', 'red', 'grey'];

    const mpiTemplateOptionsReducer = (total, mpiTemplate) => {
        const label = mpiTemplate.name;
        total.push({ label, value: mpiTemplate });
        return total;
    };

    const handleMpiItemClick = (mpiItem) => {
        setCurrentMpiItem(mpiItem);
        setCurrentStatus('');
    };

    const handleStatusClick = (status) => {
        setCurrentStatus(status);
        const newFilters = buildNewFilters(status);
        handleAdd(newFilters);
    };

    const buildNewFilters = (status) => {
        if (!currentMpiItem?.name || !status) return [];
        const newFilters = [{
            name: 'mpiItemId',
            value: currentMpiItem.id,
            label: 'By MPI Item: ' + currentMpiItem.name
        },
        {
            name: 'mpiItemStatus',
            value: status,
            label: 'By status: ' + status
        }];
        return newFilters;
    }

    const removeFilters = () => {
        const newFilters = buildNewFilters(currentStatus).concat(item);
        setCurrentMpiItem({});
        setCurrentStatus('');
        setCurrentMpiTemplate({});
        handleDelete(newFilters);
    };

    return (
        <div
            className="border rounded mr-1"
            style={{
                padding: '0.25em',
                width: '310px',
                marginBottom: '6px',
            }}
        >
            <div className="text-right">
                <X
                    className="filter-item-x font-weight-bold"
                    onClick={() => removeFilters()}
                    size={20}
                />
            </div>
            <div style={{
                padding: '5px',
                marginBottom: '2px'
            }}>
                <SearchableAsyncSelect
                    className="w-100 text-left border-none"
                    getOptions={getMpiTemplates}
                    optionsReducer={mpiTemplateOptionsReducer}
                    cancelAutoFocus="true"
                    placeholder={item.placeholder || 'Search MPI Templates'}
                    handleSelectedOption={(value) => {
                        const placeholder = `By MPI Template: ${value.name}`;
                        setCurrentMpiTemplate(value);
                        setCurrentMpiItem({});
                        setCurrentStatus('');
                        handleChange(item, {
                            ...item,
                            value: value.id,
                            placeholder,
                        });
                    }}
                    size="small"
                    sortField="id"
                />
            </div>
            
            <div
                style={{
                    padding: '0.25em',
                    width: '300px',
                    marginBottom: '2px',
                }}
            >
                <Dropdown
                    
                    isOpen={openMpiItemDropdown}
                    toggle={() => setOpenMpiItemDropdown(!openMpiItemDropdown)}
                    style={{ 'width': '100%' }}
                >
                    <DropdownToggle
                        disabled={!!currentMpiTemplate?.name === false}
                        color={currentMpiTemplate?.name ? 'primary' : 'light'}
                        style={{ 'width': '100%' }}
                        outline>
                        {currentMpiItem?.name || 'By MPI Items'}
                    </DropdownToggle>
                    <DropdownMenu
                        style={{
                            maxHeight: '300px',
                            overflowY: 'scroll',
                            width: '100%'
                        }}>
                        {currentMpiTemplate?.mpiGroups?.map(mpiGroup => (
                            <>
                            <DropdownItem 
                                header 
                                key={mpiGroup.id}>
                                    {mpiGroup.name}
                            </DropdownItem>
                            {mpiGroup?.mpiItems?.map(mpiItem => (
                                <DropdownItem 
                                    key={mpiItem.id}
                                    onClick={() => handleMpiItemClick(mpiItem)}
                                    style={{ 'width': '100%' }}>
                                    {mpiItem.name}
                                </DropdownItem>
                            ))}
                            </>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>
            <div
                style={{
                    padding: '0.25em',
                    width: '300px',
                    marginBottom: '2px',
                }}
            >
                <Dropdown
                    isOpen={openStatusDropdown}
                    toggle={() => setOpenStatusDropdown(!openStatusDropdown)}
                    style={{ 'width': '100%' }}
                >
                    <DropdownToggle
                        disabled={!!currentMpiItem?.name === false}
                        color={currentMpiItem?.name ? 'primary' : 'light'}
                        style={{ 'width': '100%' }}
                        outline>
                        {currentStatus || 'By Status'}
                    </DropdownToggle>
                    <DropdownMenu
                        style={{
                            maxHeight: '300px',
                            overflowY: 'scroll',
                            width: '100%'
                        }}>
                        {statuses?.map(status => (
                            <DropdownItem
                                key={status}
                                onClick={() => handleStatusClick(status)}
                                style={{ 'width': '100%' }}>
                                {status}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

export default connect(null, { getMpiTemplates })(MpiFilterItem);
