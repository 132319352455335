import React from 'react';
import * as Icon from 'react-feather';
import { AuthorizedRoutes, UserRoles } from '../utility/constants';

const navigationConfig = [
    {
        icon: <Icon.Eye size={20} />,
        id: 'advisor',
        navLink: AuthorizedRoutes.ADVISOR_DASHBOARD,
        permissions: [
            UserRoles.ADMIN,
            UserRoles.SERVICE_MANAGER,
            UserRoles.SERVICE_ADVISOR,
        ],
        title: 'Advisor',
        type: 'item',
        product: 'iservice'
    },
    {
        icon: <Icon.Tool size={20} />,
        id: 'technician',
        navLink: AuthorizedRoutes.TECHNICIAN_DASHBOARD,
        permissions: [
            UserRoles.ADMIN,
            UserRoles.SERVICE_MANAGER,
            UserRoles.TECHNICIAN,
        ],
        title: 'Technician',
        type: 'item',
        product: 'iservice'
    },
    {
        icon: <Icon.Cpu size={20} />,
        id: 'parts',
        navLink: AuthorizedRoutes.PARTS_DASHBOARD,
        permissions: [
            UserRoles.ADMIN,
            UserRoles.SERVICE_MANAGER,
            UserRoles.PARTS_ADVISOR,
        ],
        title: 'Parts',
        type: 'item',
        product: 'iservice'
    },
    {
        icon: <Icon.FileText size={20} />,
        id: 'management',
        navLink: AuthorizedRoutes.MANAGEMENT,
        permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
        title: 'Management',
        type: 'item',
        product: 'iservice'
    },
    {
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'item',
                icon: <Icon.TrendingUp size={20} />,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                navLink: AuthorizedRoutes.OVERVIEW_REPORT,
            },
            {
                id: 'advisor-usage-reporting',
                title: 'Advisor Usage',
                type: 'item',
                icon: <Icon.UserCheck size={20} />,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                navLink: AuthorizedRoutes.ADVISORS_USAGE_REPORTING,
            },
            {
                id: 'advisor-reporting',
                title: 'Advisors',
                type: 'item',
                icon: <Icon.Eye size={20} />,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                navLink: AuthorizedRoutes.ADVISOR_REPORTING,
            },
            {
                id: 'technician-reporting',
                title: 'Technicians',
                type: 'item',
                icon: <Icon.Tool size={20} />,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                navLink: AuthorizedRoutes.TECHNICIANS_REPORTING,
            },
            {
                id: 'customer-engagements-reporting',
                title: 'Customer Engagements',
                type: 'item',
                icon: <Icon.User size={20} />,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                navLink: AuthorizedRoutes.CUSTOMER_ENGAGEMENTS_REPORTING,
            },
            {
                id: 'closedRo-noVideo-reporting',
                title: 'No Video/RO',
                type: 'item',
                icon: <Icon.Aperture size={20} />,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                navLink: AuthorizedRoutes.CLOSED_RO_NO_VIDEO,
            },
            {
                id: 'utilization-reports',
                title: 'Utilization',
                type: 'item',
                icon: <Icon.UserCheck size={20} />,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                navLink: AuthorizedRoutes.UTILIZATION_REPORTING,
            },
            {
                id: 'recommendation-reports',
                title: 'Quote Recommendations',
                type: 'item',
                icon: <Icon.CheckCircle size={20} />,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                navLink: AuthorizedRoutes.ASR_REPORTING,
            },
            // {
            //     icon: <Icon.DollarSign size={20} />,
            //     id: 'myLoanReporting',
            //     navLink: AuthorizedRoutes.MY_LOAN_REPORTING,
            //     permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
            //     title: 'My Loan',
            //     type: 'item',
            // },
            // {
            //     id: 'upgrade-reporting',
            //     title: 'Upgrade',
            //     type: 'item',
            //     icon: <Icon.Gift size={20} />,
            //     permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
            //     navLink: AuthorizedRoutes.UPGRADE_REPORTING,
            // },
            // {
            //     id: 'follow-ups-reporting',
            //     title: 'Follow Ups',
            //     type: 'item',
            //     icon: <Icon.CheckSquare size={20} />,
            //     permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
            //     navLink: AuthorizedRoutes.FOLLOW_UP_REPORTING,
            // },
            {
                id: 'mpi-reporting',
                title: 'MPI',
                type: 'item',
                icon: <Icon.Edit size={20} />,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                navLink: AuthorizedRoutes.MPI_REPORTING,
            },
        ],
        icon: <Icon.BarChart size={20} />,
        id: 'reporting_main',
        permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
        title: 'Reporting',
        type: 'collapse',
        product: 'iservice'
    },
    // { hidden for now
    //     icon: <Icon.CheckSquare size={20} />,
    //     id: 'followUp',
    //     navLink: AuthorizedRoutes.FOLLOW_UP_TARGETS,
    //     permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
    //     title: 'FollowUp',
    //     type: 'item',
    // },
    // {
    //     children: [
    //         // {
    //         //     icon: <Icon.BarChart size={20} />,
    //         //     id: 'advisorReporting',
    //         //     navLink: AuthorizedRoutes.MY_REVIEW_ADVISOR_REPORTING,
    //         //     permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
    //         //     title: 'Advisor Reports',
    //         //     type: 'item',
    //         // },
    //         {
    //             icon: <Icon.BarChart2 size={20} />,
    //             id: 'roReporting',
    //             navLink: AuthorizedRoutes.RO_REPORTING,
    //             permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
    //             title: 'RO Reports',
    //             type: 'item',
    //         },
    //     ],
    //     icon: <Icon.PenTool size={20} />,
    //     id: 'myReview',
    //     permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
    //     title: 'myReview',
    //     type: 'collapse',
    // },
    {
        icon: <Icon.Check size={20} />,
        id: 'checkin',
        navLink: AuthorizedRoutes.CHECK_IN,
        permissions: [
            UserRoles.ADMIN,
            UserRoles.SERVICE_MANAGER,
            UserRoles.SERVICE_ADVISOR,
        ],
        title: 'CHECKin',
        type: 'item',
        product: 'iservice'
    },
    {
        children: [
            {
                id: 'users-advisors',
                navLink: AuthorizedRoutes.ADVISORS,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Advisors',
                type: 'item',
            },
            {
                id: 'users-managers',
                navLink: AuthorizedRoutes.MANAGERS,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Managers',
                type: 'item',
            },
            {
                id: 'users-parts-agents',
                navLink: AuthorizedRoutes.PARTS_AGENTS,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Parts Agents',
                type: 'item',
            },
            {
                id: 'users-sales-agents',
                navLink: AuthorizedRoutes.SALES_AGENTS,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Sales Agents',
                type: 'item',
            },
            {
                id: 'users-sales-managers',
                navLink: AuthorizedRoutes.SALES_MANAGERS,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Sales Managers',
                type: 'item',
            },
            {
                id: 'users-technicians',
                navLink: AuthorizedRoutes.TECHNICIANS,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Technicians',
                type: 'item',
            },
        ],
        icon: <Icon.User size={20} />,
        id: 'users',
        permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
        title: 'Users',
        type: 'collapse',
    },
    // {
    //     icon: <Icon.DollarSign size={20} />,
    //     id: 'myLoanDashboard',
    //     navLink: AuthorizedRoutes.MY_LOAN_DASHBOARD,
    //     permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
    //     title: 'My Loan',
    //     type: 'item',
    // },
    {
        icon: <Icon.Settings size={20} />,
        id: 'settings',
        navLink: AuthorizedRoutes.ISERVICE_SETTINGS,
        permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
        title: 'Settings',
        type: 'item',
    },
    {
        icon: <Icon.BookOpen size={20} />,
        id: 'training',
        navLink: 'http://iserviceauto.com/technician-training/',
        newTab: true,
        permissions: [
            UserRoles.ADMIN,
            UserRoles.SERVICE_MANAGER,
            UserRoles.SERVICE_ADVISOR,
            UserRoles.TECHNICIAN,
            UserRoles.PARTS_ADVISOR,
        ],
        title: 'Training',
        type: 'external-link',
        product: 'iservice'
    },
    // {
    //     icon: <Icon.Gift size={20} />,
    //     id: 'upgrade',
    //     navLink: AuthorizedRoutes.UPGRADE,
    //     permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
    //     title: 'Upgrade',
    //     type: 'item',
    // },
    {
        icon: <Icon.User size={20} />,
        id: 'rewards',
        navLink: AuthorizedRoutes.CUSTOMERS,
        permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
        title: 'Rewards',
        type: 'item',
    },
    {
        children: [
            {
                icon: <Icon.FilePlus size={20} />,
                id: 'text-campaign-create',
                navLink: AuthorizedRoutes.TEXT_CAMPAIGN_CREATE,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Create',
                type: 'item',
            },
            {
                icon: <Icon.BarChart2 size={20} />,
                id: 'text-campaign-report',
                navLink: AuthorizedRoutes.TEXT_CAMPAIGN_REPORT,
                permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
                title: 'Report',
                type: 'item',
            },
        ],
        icon: <Icon.Flag size={20} />,
        id: 'textCampaign',
        permissions: [UserRoles.ADMIN, UserRoles.SERVICE_MANAGER],
        title: 'Text Campaigns',
        type: 'collapse',
    },
];

export default navigationConfig;
