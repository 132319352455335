import React from 'react';
import { Input } from 'reactstrap';

const SelectInput = ({
    field,
    handleBlur,
    handleChange,
    isChanged,
    isValid,
    options,
    required,
    value,
}) => {
    return (
        <Input
            style={{
                borderWidth: isValid ? '1px' : '3px',
            }}
            className={`form-control ${
                !isValid ? 'border-danger' : isChanged ? 'border-warning' : ''
            }`}
            id={field.selector}
            onBlur={handleBlur}
            onChange={({ target }) =>
                handleChange(field.selector, target.value)
            }
            required={required}
            type="select"
            value={value || ''}
        >
            {options.map((option) => {
                return (
                    <option key={`option-${option.value}`} value={option.value}>
                        {option.text}
                    </option>
                );
            })}
        </Input>
    );
};

export default SelectInput;
