import React from 'react';
import { Input } from 'reactstrap';

const NumberInput = ({
    field,
    handleBlur,
    handleChange,
    isChanged,
    isValid,
    maximumLength,
    required = false,
    value,
}) => {
    return (
        <Input
            className={`form-control ${
                isValid === false
                    ? 'border-danger'
                    : isChanged
                    ? 'border-warning'
                    : ''
            }`}
            id={field.selector || Math.random()}
            onBlur={handleBlur || null}
            onKeyDown={(e) => {
                if (e.key === 'e') e.preventDefault();
            }}
            onChange={({ target }) => {
                const valid =
                    /^\d*\.?\d*$/g.test(target.value) &&
                    ((maximumLength && target.value.length <= maximumLength) ||
                        !maximumLength);

                if (valid) handleChange(field.selector, target.value);
            }}
            required={required}
            type="text"
            value={value ?? ''}
        />
    );
};

export default NumberInput;
