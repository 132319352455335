import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'reactstrap';
import { connect } from 'react-redux';
import ConfirmCancelModal from '../modals/ConfirmCancelModal';
import CustomModalBody from '../atoms/CustomModalBody';
import CustomModalHeader from '../atoms/CustomModalHeader';
import CustomForm from '../organisms/CustomForm';
import {
    updateAccountSecurityQuestion,
    updateRecord,
} from '../../../redux/actions/member/index';
import { showToast } from '../../../utility/toast';

const AccountInformationModal = ({
    isOpen,
    toggle,
    updateAccountSecurityQuestion,
    updateRecord,
}) => {
    const [userData, setUserData] = useState({});
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const [cancelFlag, setCancelFlag] = useState(false);

    useEffect(() => {
        const user = Cookies.get('iservice_member_app_user');
        if (!user) return;
        setUserData(JSON.parse(user));
    }, [isOpen]);

    const handleCancel = () => {
        setShowConfirmCancel(false);
        toggle();
    };

    const handleSave = async (newUserData) => {
        if (
            !!newUserData.password &&
            (!newUserData.confirmPassword ||
                newUserData.password !== newUserData.confirmPassword)
        )
            return showToast({
                message: 'Passwords must match',
                type: 'error',
            });
        if (
            (newUserData.securityQuestion && !newUserData.securityAnswer) ||
            (!newUserData.securityQuestion &&
                !userData.securityQuestion &&
                newUserData.securityAnswer)
        )
            return showToast({
                message:
                    'Security question must have an answer, and vice versa',
                type: 'error',
            });
        if (newUserData.securityAnswer) {
            !newUserData.securityQuestion &&
                (newUserData.securityQuestion = userData.securityQuestion);
            await updateAccountSecurityQuestion(newUserData);
        }
        const updateWasSuccessful = await updateRecord(
            { ...userData, ...newUserData },
            'users',
            { role: userData.role }
        );
        const sensitiveKeys = ['confirmPassword', 'password', 'securityAnswer'];
        const sanitizedUserData = { ...userData, ...newUserData };
        sensitiveKeys.map((key) => delete sanitizedUserData[key]);
        if (updateWasSuccessful) {
            Cookies.set(
                'iservice_member_app_user',
                JSON.stringify(sanitizedUserData)
            );
            const user = Cookies.get('iservice_member_app_user');
            setUserData(JSON.parse(user));
        }
    };

    const fields = [
        {
            formOptions: { required: true },
            name: 'First Name',
            selector: 'firstName',
            type: 'text',
        },
        {
            formOptions: { required: true },
            name: 'Last Name',
            selector: 'lastName',
            type: 'text',
        },
        {
            formOptions: { required: true, validator: 'phone' },
            name: 'Phone',
            selector: 'phone',
            type: 'number',
        },
        {
            name: 'Security Question',
            selector: 'securityQuestion',
            type: 'text',
            formOptions: {
                hidden: userData.securityQuestion,
            },
        },
        {
            name: 'Security Answer',
            selector: 'securityAnswer',
            type: 'text',
            formOptions: {
                hidden: userData.securityQuestion,
            },
        },
        {
            name: 'Password',
            selector: 'password',
            type: 'text',
        },
        {
            name: 'Confirm Password',
            selector: 'confirmPassword',
            type: 'text',
        },
    ];

    return (
        <>
            <ConfirmCancelModal
                handleCancel={handleCancel}
                isOpen={showConfirmCancel}
                toggle={() => setShowConfirmCancel(false)}
            />
            <Modal
                isOpen={isOpen}
                scrollable
                toggle={() =>
                    cancelFlag ? setShowConfirmCancel(true) : handleCancel()
                }
            >
                <CustomModalHeader
                    title="Account Information"
                    toggle={() =>
                        cancelFlag ? setShowConfirmCancel(true) : handleCancel()
                    }
                />
                <CustomModalBody>
                    <CustomForm
                        cancelFlag={cancelFlag}
                        fields={fields}
                        handleCancel={handleCancel}
                        handleSubmit={handleSave}
                        record={userData}
                        setCancelFlag={setCancelFlag}
                        setShowConfirmCancel={setShowConfirmCancel}
                        submitChangesOnly
                    />
                    {!!alert.message && (
                        <Alert type={alert.type}>{alert.message}</Alert>
                    )}
                </CustomModalBody>
            </Modal>
        </>
    );
};
export default connect(null, { updateAccountSecurityQuestion, updateRecord })(
    AccountInformationModal
);
