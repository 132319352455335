import { showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';
import { getRoById, handleError } from './index';

export const toggleMobileValidationConfirmModal = () => (dispatch) => {
    dispatch({ type: 'TOGGLE_MOBILE_VALIDATION_CONFIRM_MODAL' });
};

export const createCustomer = (
    customer,
    addToRO,
    skipPhoneValidation
) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'CREATE_CUSTOMER' });

    // Hold onto your butts
    if (!skipPhoneValidation) {
        try {
            dispatch({ type: 'VALIDATING_MOBILE_PHONE' });

            const response = await clientApi.post(
                Endpoints.validateMobilePhone,
                {
                    phone: customer.phone,
                }
            );
            const isMobile = response.data.isMobile;

            dispatch({
                type: 'MOBILE_PHONE_VALIDATED',
                data: isMobile,
            });
            dispatch({ type: 'LOADING_END' });

            if (!isMobile) return false;
        } catch (error) {
            dispatch(handleError(error));
            return false;
        }
    }

    try {
        const response = await clientApi.post(Endpoints.customers, customer);

        if (response.status === 200) {
            dispatch({ type: 'CUSTOMER_CREATED', data: response.data });

            if (addToRO) {
                await dispatch(addCustomerToRO(response.data));
            }

            dispatch({ type: 'LOADING_END' });
            showToast({ message: 'Successfully created new customer.' });
            return response.data;
        } else {
            throw new Error(`Failed to create new customer.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

// This is for the async select; needs work to be general use
export const getCustomers = (params) => async (dispatch) => {
    dispatch({ type: 'GET_CUSTOMERS' });

    try {
        const response = await clientApi.get(Endpoints.customers, { params });

        if (response.status === 200) {
            dispatch({ type: 'RECEIVE_CUSTOMERS_NO_STATE_CHANGE' });

            return response;
        } else {
            throw new Error(`Failed to get customers.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return []; // evil
    }
};

export const updateCustomer = (customer) => async (dispatch, getState) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'UPDATE_CUSTOMER' });

    try {
        const response = await clientApi.put(
            `${Endpoints.customers}/${customer.id}`,
            customer
        );

        if (response.status === 200) {
            dispatch({ type: 'CUSTOMER_UPDATED' });
            dispatch(getRoById(getState().member.selectedRow.id));

            showToast({ message: 'Successfully updated customer.' });
            return response.data;
        } else {
            throw new Error(`Failed to update customer.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const deleteCustomer = (customerId) => async (dispatch, getState) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'DELETE_CUSTOMER' });

    try {
        const response = await clientApi.delete(
            `${Endpoints.customers}/${customerId}`
        );

        if (response.status === 200) {
            dispatch({ type: 'CUSTOMER_DELETED' });
            dispatch(getRoById(getState().member.selectedRow.id));

            showToast({ message: 'Successfully deleted customer.' });
            return true;
        } else {
            throw new Error(`Failed to add customer.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const addCustomerToRO = (customer) => async (dispatch, getState) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'ADD_CUSTOMER_TO_RO' });

    try {
        const repairOrderId = getState().member.selectedRow.id;
        const response = await clientApi.post(Endpoints.repairOrderCustomer, {
            repairOrderId,
            customerId: customer.id,
        });

        if (response.status === 200) {
            dispatch({ type: 'CUSTOMER_ADDED_TO_RO' });
            dispatch(getRoById(repairOrderId));

            showToast({ message: 'Successfully added customer' });
            return true;
        } else {
            throw new Error(`Failed to add customer.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const removeCustomerFromRO = (relationshipId) => async (
    dispatch,
    getState
) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'REMOVE_CUSTOMER_FROM_RO' });

    try {
        const response = await clientApi.delete(
            `${Endpoints.repairOrderCustomer}/${relationshipId}`
        );

        if (response.status === 200) {
            dispatch({ type: 'CUSTOMER_REMOVED_FROM_RO' });
            dispatch(getRoById(getState().member.selectedRow.id));

            showToast({
                message: 'Customer removed from repair order.',
            });
            return true;
        } else {
            throw new Error(`Failed to remove customer.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const getCustomerROs = (customer) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'GET_ROS_BY_CUSTOMER_PHONE' });

    try {
        const response = await clientApi.get(`${Endpoints.ros}`, {
            params: { searchTerm: customer.phone, page: 1, pageLimit: 9999 },
        });

        if (response.status === 200) {
            dispatch({
                type: 'RECEIVE_ROS_BY_CUSTOMER_PHONE',
                data: response.data.results,
            });
            return true;
        } else {
            dispatch({
                type: 'RECEIVE_ROS_BY_CUSTOMER_PHONE',
                data: [],
            });
            throw new Error(
                `Failed to get customer's repair orders.${CONTACT_MESSAGE}`
            );
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const setSelectedRecipient = (user) => (dispatch) => {
    dispatch({ type: 'SET_SELECTED_RECIPIENT', data: user });
};
