import { combineReducers } from 'redux';
import customizer from './customizer/';
import auth from './auth/';
import member from './member';
import navbar from './navbar/Index';

const rootReducer = combineReducers({
    customizer,
    auth,
    member,
    navbar,
});

export default rootReducer;
