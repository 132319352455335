import Cookies from 'js-cookie';
import { store } from '../../storeConfig/store';
import { showErrorToast, showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import {
    CONTACT_MESSAGE,
    Endpoints,
    UserRoles,
} from '../../../utility/constants';
import { getRoById } from '.';

export * from './customers';
export * from './messages';
export * from './mpi';
export * from './note';
export * from './payments';
export * from './quote';
export * from './ros';
export * from './video';
export * from './images';
export * from './platform-settings';
export * from './support';

// in getRecords & pollRos
export const generateParams = () => {
    const {
        filters,
        currentPage,
        searchTerm,
        sortDirection,
        sortField,
    } = store.getState().member;

    const { id, role } = store.getState().auth.login.user;

    const params = {
        page: currentPage,
    };

    if (filters?.length) {
        // Pull the filter objects out of the array and into params
        filters.forEach((filter) => {
            if (filter.name.includes('date')) {
                const start = filter.value[0]?.toISOString().slice(0, 10);
                let end = filter.value[1]?.toISOString().slice(0, 10);

                if (!end) {
                    end = start;
                }

                const startParamName =
                    filter.name === 'dateRange'
                        ? 'startDate'
                        : filter.name + 'Start';

                const endParamName =
                    filter.name === 'dateRange'
                        ? 'endDate'
                        : filter.name + 'End';

                params[startParamName] = start + ' 00:00:00';
                params[endParamName] = end + ' 23:59:59';
            } else {
                params[filter.name] = filter.value;
            }
        });
    }

    if (searchTerm) {
        params.searchTerm = searchTerm;
    }

    if (sortField) {
        params.sortField = sortField;
        params.sortDirection = sortDirection;
    }

    return params;
};
// autocrud & dashboard
export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: 'SET_FILTERS', data: filters });
};

export const setState = (key, value) => (dispatch) => {
    dispatch({ type: 'SET_STATE', key, value });
};

export const setLoading = (isLoading) => (dispatch) => {
    if (isLoading === true) dispatch({ type: 'LOADING_START' });
    if (isLoading === false) dispatch({ type: 'LOADING_END' });
};

// in Home.js
export const dismissError = () => (dispatch) =>
    dispatch({ type: 'DISMISS_ERROR' });

export const handleError = (error) => {
    console.error(error);
    return (dispatch) => {
        dispatch({ type: 'HANDLE_ERROR', error });
        if (error?.response?.status === 401) {
            Cookies.remove('iservice_member_app_user');
            Cookies.remove('iservice_member_app_token');
            return window.location.assign('/login');
        } else if (error?.response?.status === 500) {
            return showErrorToast({ message: '500 Server Error' });
        } else {
            const objectContainingMessage = error?.response?.data?.message
                ? error.response.data
                : error?.message
                ? error
                : { message: `Something went wrong${CONTACT_MESSAGE}` };
            showErrorToast(objectContainingMessage);
        }
    };
};
// used everywhere
export const setSelectedRow = ({ row }) => (dispatch) => {
    console.log('SELECTED ROW', row);
    // Stops payments from another RO blipping on screen before fresh load
    dispatch({ type: 'CLEAR_PAYMENTS' });
    dispatch({ type: 'SET_SELECTED_ROW', data: row });
};

// AutoCrud Actions
// autocrud, autoform, newRepairOrder, matrixModal
export const createRecord = (createdData, stateProp, params) => async (
    dispatch
) => {
    dispatch({ type: 'CREATE_RECORD' });
    try {
        const response = await clientApi.post(
            Endpoints[stateProp],
            createdData
        );
        if (response.status === 200) {
            showToast({ message: 'Successfully created' });
            dispatch({ type: 'RECORD_CREATED' });

            // ROs needs separate CRUD
            if (stateProp === 'ros') {
                dispatch({ type: 'RO_OUTDATED', forceUpdate: true });
            } else {
                dispatch(getRecords(stateProp, params));
            }
            return response;
        } else
            throw new Error(`Failed to create new record.${CONTACT_MESSAGE}`);
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};
// used in dashboard, autocrud, mpiTemplate, autoform
export const deleteRecord = (deleted, stateProp, params) => async (
    dispatch
) => {
    const { id } = deleted;
    dispatch({ type: 'DELETE_RECORD' });
    try {
        const response = await clientApi.delete(
            `${Endpoints[stateProp]}/${id}`
        );
        if (response.status === 200) {
            showToast({ message: 'Successfully deleted' });
            dispatch({ type: 'RECORD_DELETED' });
            // ROs needs separate CRUD
            if (stateProp === 'ros') {
                dispatch({ type: 'RO_OUTDATED' });
            } else {
                dispatch(getRecords(stateProp, params));
            }
            return true;
        } else {
            throw new Error(`Failed to delete record.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};
// not used
export const receiveRecords = (data, stateProp) => (dispatch) => {
    dispatch({ type: 'RECEIVE_RECORDS', data, stateProp });
};
//chat & searchable async
export const setCurrentPage = (page) => (dispatch) => {
    dispatch({ type: 'SET_CURRENT_PAGE', page });
};
// autocrud
export const toggleCrudModal = () => (dispatch) => {
    dispatch({ type: 'TOGGLE_CRUD_MODAL' });
};

// used everywhere
export const updateRecord = (updated, stateProp, params) => async (
    dispatch
) => {
    const { id } = updated;
    dispatch({ type: 'UPDATE_RECORD' });
    try {
        const response = await clientApi.put(
            `${Endpoints[stateProp]}/${id}`,
            updated
        );
        if (response.status === 200) {
            showToast({ message: 'Successfully updated' });
            dispatch({ type: 'RECORD_UPDATED' });
            if (stateProp === 'ros') {
                dispatch(getRoById(id));
            } else {
                dispatch(getRecords(stateProp, params));
            }
            return response;
        } else {
            throw new Error(`Failed to update record.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

// Return true if successful, false if not
// used everywhere
export const getRecord = (stateProp, id) => async (dispatch) => {
    dispatch({ type: 'GET_RECORDS', data: { stateProp } });
    try {
        const response = await clientApi.get(`${Endpoints[stateProp]}/${id}`);
        if (response.status === 200) {
            dispatch({
                type: 'RECEIVE_RECORD',
            });
            return response.data;
        } else {
            throw new Error(`Failed to get records.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

// used everywhere
export const getRecords = (
    stateProp,
    additionalParams,
    doNotReceive,
    forceClear
) => async (dispatch) => {
    let params = generateParams();
    // additionalParams will overwrite params from global state
    if (additionalParams) {
        params = Object.assign({}, params, additionalParams);
    }

    dispatch({
        type: 'GET_RECORDS',
        stateProp: forceClear ? stateProp : undefined,
    });
    try {
        // Need to make sure we always have settings before showing records
        if (!store.getState().member.settings) {
            dispatch({ type: 'GET_SETTINGS' });

            const settingsResponse = await clientApi.get(Endpoints.settings);

            if (settingsResponse.status !== 200) {
                throw new Error(
                    `Failed to get config. Please try refreshing the page and try again.`
                );
            }

            dispatch({ type: 'RECEIVE_SETTINGS', data: settingsResponse.data });
            dispatch({ type: 'LOADING_START' }); //  RECEIVE_SETTINGS sets isLoading to false but we want isLoading to stay true until we RECEIVE_RECORDS
        }

        const response = await clientApi.get(Endpoints[stateProp], { params });
        if (response.status === 200) {
            if (doNotReceive) {
                dispatch({ type: 'LOADING_END' });
            } else {
                dispatch({
                    type: 'RECEIVE_RECORDS',
                    data: response.data,
                    stateProp,
                });
            }
            return response;
        } else {
            throw new Error(`Failed to get records.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};
// maybe quote?
export const getOpCodes = (params) => async (dispatch) => {
    dispatch({ type: 'GET_OP_CODES' });

    try {
        const response = await clientApi.get(Endpoints.opCodes, {
            params,
        });
        if (response.status === 200) {
            return response;
        } else throw new Error(`Failed to get Op. Codes.${CONTACT_MESSAGE}`);
    } catch (error) {
        dispatch(handleError(error));
        return [];
    }
};
// maybe to quote?
export const getParts = (params) => async (dispatch) => {
    dispatch({ type: 'GET_PARTS' });

    try {
        const response = await clientApi.get(Endpoints['parts'], {
            params,
        });
        if (response.status === 200) {
            return response;
        } else throw new Error(`Failed to get users.${CONTACT_MESSAGE}`);
    } catch (error) {
        dispatch(handleError(error));
        return [];
    }
};
// dashboard
export const getUsers = (params) => async (dispatch) => {
    try {
        const response = await clientApi.get(Endpoints['users'], {
            params,
        });
        if (response.status === 200) {
            return response;
        } else throw new Error(`Failed to get users.${CONTACT_MESSAGE}`);
    } catch (error) {
        dispatch(handleError(error));
        return [];
    }
};

//Settings
export const getSettings = () => async (dispatch) => {
    dispatch({ type: 'GET_SETTINGS' });
    try {
        const response = await clientApi.get(Endpoints.settings);
        if (response.status === 200) {
            dispatch({ type: 'RECEIVE_SETTINGS', data: response.data });
        } else {
            throw new Error(`Failed to get settings.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};
//maybe got to quote?
export const getPriceMatrix = () => async (dispatch) => {
    dispatch({ type: 'GET_PRICE_MATRIX' });

    try {
        const response = await clientApi.get(Endpoints.priceMatrix);

        if (response.status === 200) {
            dispatch({
                type: 'RECEIVE_PRICE_MATRIX',
                data: response.data || [],
            });
        } else {
            throw new Error(`Failed to get price matrix.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const updateAccountSecurityQuestion = ({
    id,
    securityAnswer: answer,
    securityQuestion: question,
}) => async (dispatch) => {
    try {
        const response = await clientApi.patch(
            `${Endpoints.security}/${id}/set`,
            {
                answer,
                question,
            }
        );
        if (response.status === 200) {
            return showToast({
                message: 'Security question and answer successfully updated',
            });
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};
// dashboard
export const resendWaiver = (repairOrderId) => async (dispatch) => {
    dispatch({ type: 'RESEND_WAIVER' });
    try {
        const response = await clientApi.post(Endpoints.resendWaiver, {
            repairOrderId,
        });
        if (response.status === 200) {
            showToast({ message: 'Successfully sent waiver' });
            dispatch({ type: 'WAIVER_SENT' });
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const updateSettings = (settings, fields) => async (dispatch) => {
    dispatch({ type: 'UPDATE_SETTINGS' });

    // It is what it is
    for (let key in settings) {
        const field = fields.find((field) => field.selector === key);
        if (field?.type === 'checkbox' || field?.type === 'switch') {
            settings[key] =
                settings[key] === true ||
                settings[key] === 'true' ||
                settings[key] === '1'
                    ? '1'
                    : '0';
        }
    }

    try {
        const response = await clientApi.post(Endpoints.settings, settings);
        if (response.status === 200) {
            showToast({ message: 'Settings updated' });
            dispatch(getSettings());
        } else {
            throw new Error(`Failed to update settings.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};
// autocrud
export const updateTableParameters = (params) => async (dispatch) => {
    dispatch({ type: 'UPDATE_TABLE_PARAMETER', params });
};
// not used
export const resetMemberState = () => async (dispatch) => {
    dispatch({ type: 'RESET_MEMBER_STATE' });
};

export const setOpenModal = (name) => (dispatch, getState) => {
    // If the selected RO was updated, get ROs as well to ensure they're in sync
    const { selectedRow, ros } = getState().member;
    const matchingRO = ros.results?.find((ro) => ro.id === selectedRow?.id);
    if (!name && matchingRO !== selectedRow) {
        dispatch({ type: 'RO_OUTDATED' });
    }

    dispatch({ type: 'SET_OPEN_DASHBOARD_MODAL', name });
};

export const setprintableDoc = (data) => (dispatch) => {
    dispatch({ type: 'SET_PRINTABLE_DOC_DATA', data });
};

export const toggleChatModal = () => (dispatch) => {
    dispatch({ type: 'TOGGLE_CHAT_MODAL' });
};

export const updateMessageType = (type) => (dispatch) => {
    dispatch({ type: 'SET_MESSAGE_TYPE', data: type });
};

export const setIsLockedOut = ({ repairOrderId, lock }) => async (dispatch) => {
    dispatch({ type: 'SETTING_LOCKOUT' });
    try {
        const response = await clientApi.post(Endpoints.quoteInProgress, {
            repairOrderId,
            lock,
        });

        if (response.status !== 200) {
            throw new Error('Failed to lock quote');
        }

        dispatch({ type: 'SET_IS_LOCKED_OUT', data: false });
        return true;
    } catch (err) {
        dispatch({ type: 'SET_IS_LOCKED_OUT', data: true });
        dispatch(handleError(err));
    }
};

export const resetIsLockedOut = () => (dispatch) => {
    dispatch({ type: 'RESET_IS_LOCKED_OUT' });
};

export const getAdvisorReport = (
    stateProp,
    additionalParams,
    doNotReceive
) => async (dispatch) => {
    let params = additionalParams;
    if (additionalParams) {
        const start = additionalParams.startDate?.toISOString().slice(0, 10);
        let end = additionalParams.endDate.toISOString().slice(0, 10);

        if (!end) {
            end = start;
        }
        params['startDate'] = start + ' 00:00:00';
        params['endDate'] = end + ' 23:59:59';
    }

    try {
        // Need to make sure we always have settings before showing records
        if (!store.getState().member.settings) {
            dispatch({ type: 'GET_SETTINGS' });

            const settingsResponse = await clientApi.get(Endpoints.settings);

            if (settingsResponse.status !== 200) {
                throw new Error(
                    `Failed to get config. Please try refreshing the page and try again.`
                );
            }

            dispatch({ type: 'RECEIVE_SETTINGS', data: settingsResponse.data });
            dispatch({ type: 'LOADING_START' }); //  RECEIVE_SETTINGS sets isLoading to false but we want isLoading to stay true until we RECEIVE_RECORDS
        }

        const response = await clientApi.get(Endpoints[stateProp], { params });
        if (response.status === 200) {
            if (doNotReceive) {
                dispatch({ type: 'LOADING_END' });
            } else {
                dispatch({
                    type: 'RECEIVE_RECORDS',
                    data: response.data,
                    stateProp,
                });
            }
            return response;
        } else {
            throw new Error(`Failed to get records.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};
