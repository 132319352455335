import validate from 'validate.js';
import { showToast } from '../toast';
import { authorizedUserRoles } from '../constants';

// See http://validatejs.org

// If validating with AutoCrud, DO NOT use presence prop here to require values
// Instead, set required: true in form options.
const constraints = {
    email: {
        email: true,
    },
    money: {
        format: {
            pattern: /^\d*\.?(\d{1,2})?$/,
            // Prefix custom format messages with ^ otherwise the message will be prepended with the validators key
            // For this entry, without prepending the message with ^ it would read
            // "Money Please enter a valid dollar amount"
            message: '^Please enter a valid dollar amount',
        },
    },
    number: {
        numericality: {
            notLessThan: 0,
            message: '^Please enter a number greater than zero.',
        },
    },
    password: {},
    personName: {},
    phone: {
        format: {
            pattern: /^(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})$/,
            message: '^Please enter a valid phone number.',
        },
    },
    url: { url: true },
    zip: {
        format: {
            pattern: /^\d{5,10}$/,
            message: '^Please enter a valid zip code',
        },
    },
};

const validator = ({ type, value, enableToast }) => {
    if (!type && !value && value !== 0) {
        throw new Error('Validator must be passed a type');
    }
    // Validator does NOT check for required
    if (value === '') return true;

    const invalidMessageObject = validate(
        { [type]: value?.toString() },
        constraints
    );
    if (invalidMessageObject) {
        const invalidMessage = invalidMessageObject[type][0];
        if (enableToast) showToast({ message: invalidMessage, type: 'error' });
        return false;
    }
    return true;
};
export default validator;
