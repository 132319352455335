import React from 'react';
import { connect } from 'react-redux';
import PaymentModalNmi from './PaymentModalNmi';
import { showErrorToast } from '../../../utility/toast';
import { Button, Modal } from 'reactstrap';
import CustomModalHeader from '../atoms/CustomModalHeader';
import PaymentModalHeartland from './PaymentModalHeartland';
import { format } from '@jmdigital/iservice3-utils';
import CustomModalBody from '../atoms/CustomModalBody';
import CustomModalFooter from '../atoms/CustomModalFooter';

const PaymentModal = ({
    isOpen,
    payment,
    repairOrder,
    settings,
    toggle,
    toggleParent,
    type,
}) => {
    if (!payment || !repairOrder) return null;

    const dollarAmount = parseInt(payment.amount?.replace(/\D/g, '')) / 100;
    if (!dollarAmount || dollarAmount.toFixed(2) !== payment.amount) {
        showErrorToast({
            message:
                'Something is wrong with this payment. Please cancel and try creating a new one.',
        });

        toggle();
        return null;
    }

    const isForRo = !type;
    const dollarsText = format.money(payment?.amount);
    const PaymentModalBody =
        settings.heartland === '1' ? PaymentModalHeartland : PaymentModalNmi;

    return (
        <Modal isOpen={isOpen} toggle={toggle} scrollable>
            <CustomModalHeader
                title={
                    isForRo
                        ? `Make a Payment for RO# ${repairOrder.number}`
                        : `Payment # ${payment.number}`
                }
                toggle={toggle}
            />
            <CustomModalBody>
                <h5>{`Make a payment of ${dollarsText}`}</h5>
                <PaymentModalBody
                    isForRo={isForRo}
                    isOpen={isOpen}
                    payment={payment}
                    repairOrder={repairOrder}
                    settings={settings}
                    toggle={toggle}
                    toggleParent={toggleParent}
                    type={type}
                />
            </CustomModalBody>
            <CustomModalFooter>
                <Button color="primary" onClick={toggle}>
                    Cancel
                </Button>
            </CustomModalFooter>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    settings: state.member.settings,
});

export default connect(mapStateToProps)(PaymentModal);
