import { handleError } from './index';
import { showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { Endpoints } from '../../../utility/constants';

export const performCommand = (command) => async (dispatch) => {
    const whitelist = [
        'addRepairOrders',
        'closeRepairOrders',
        'syncOperationCodes',
        'syncParts',
    ];

    try {
        if (!whitelist.includes(command)) {
            throw new Error(`Command not available`);
        }

        dispatch({ type: `PERFORMING_${command.toUpperCase()}` });
        dispatch({ type: 'LOADING_START' });

        await clientApi.post(Endpoints.command, { command });
        dispatch({ type: 'LOADING_END' });
        dispatch({ type: `${command.toUpperCase()}_COMPLETE` });
        showToast({ message: 'Command performed successfully' });
        return true;
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const getEnvironmentSettings = () => async (dispatch) => {
    try {
        dispatch({ type: 'GET_ENVIRONMENT_SETTINGS' });

        const response = await clientApi.get(Endpoints.environment);

        dispatch({ type: `RECEIVE_ENVIRONMENT_SETTINGS`, data: response.data });
        return true;
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const updateEnvironmentSettings = (updatedEnvironmentSettings) => async (
    dispatch
) => {
    try {
        dispatch({ type: 'UPDATE_ENVIRONMENT_SETTINGS' });
        dispatch({ type: 'LOADING_START' });

        const response = await clientApi.post(
            Endpoints.environment,
            updatedEnvironmentSettings
        );
        dispatch({ type: 'LOADING_END' });
        dispatch({ type: `ENVIRONMENT_SETTINGS_UPDATED`, data: response });
        dispatch(getEnvironmentSettings());
        showToast({ message: 'Settings updated' });
        return true;
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};
