import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';
import { XCircle } from 'react-feather';

const AutoCreateInputItems = ({
    additionalProps,
    handleChange,
    id,
    label,
    name,
    placeholder,
}) => {
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);

    const deleteItem = (listItem) => {
        const filterDeleted = items.filter((item) => item !== listItem);
        setItems(filterDeleted);
        handleChange(filterDeleted);
    };

    const updateItemsList = (e) => {
        let targetVal = e.target.value;
        e.preventDefault();
        if (targetVal !== '' && targetVal !== ',') {
            if (e.key === ',') {
                const newItem = value.trim().split(',')[0];
                if (!items.includes(newItem) && newItem !== '') {
                    const newList = [...items, newItem];
                    setItems(newList);
                    handleChange(newList);
                }
                setValue('');
            } else if (e.key === 'Enter') {
                const newItem = value.trim();
                if (!items.includes(newItem) && newItem !== '') {
                    const newList = [...items, newItem];
                    setItems(newList);
                    handleChange(newList);
                }
                setValue('');
            }
        }
    };

    return (
        <React.Fragment>
            {!!label && (
                <Label className="settings-label" for={id ? id : name}>
                    {label}
                </Label>
            )}
            <div style={styles.itemsList}>
                {items.map((item, index) => (
                    <div className="item" key={`item-${index}`}>
                        {item}
                        <span onClick={() => deleteItem(item)}>
                            <XCircle size={14} style={styles.xIcon} />
                        </span>
                    </div>
                ))}
                <Input
                    autoComplete="off"
                    className="form-control mb-2"
                    id={id ? id : name}
                    name={name}
                    onChange={({ target }) => setValue(target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                    onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
                    onKeyUp={updateItemsList}
                    placeholder={placeholder}
                    type="text"
                    value={value}
                    {...additionalProps}
                />
            </div>
        </React.Fragment>
    );
};

AutoCreateInputItems.propTypes = {
    additionalProps: PropTypes.any,
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};

export default AutoCreateInputItems;

const styles = {
    itemsList: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    xIcon: {
        cursor: 'pointer',
        marginLeft: '3px',
    },
};
