import React, { useEffect, useState } from 'react';

const VideoFileInput = ({
    field,
    handleChange,
    isChanged,
    isValid,
    required,
    value,
}) => {
    const [videoSrc, setVideoSrc] = useState('');
    const [isBrokenUrl, setIsBrokenUrl] = useState(false);

    useEffect(() => {
        setIsBrokenUrl(false);
        if (typeof value === 'object') {
            const videoObjectUrl = URL.createObjectURL(value);
            setVideoSrc(videoObjectUrl);
        }
        if (value && typeof value === 'string') {
            // String values should be URLs
            setVideoSrc(value);
        }
    }, [value]);

    return (
        <div>
            {isBrokenUrl ? (
                <p className="text-danger">
                    (existing video link is broken; try uploading again)
                </p>
            ) : !!videoSrc ? (
                <div className="w-100 text-center">
                    <video
                        className="mt-1"
                        controls
                        onError={() => setIsBrokenUrl(true)}
                        height="420"
                    >
                        <source src={videoSrc} type="video/mp4" />
                        <source src={videoSrc} type="video/webm" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
            ) : null}
            <input
                accept="video/webm, video/mp4"
                className={
                    !isValid
                        ? 'border-danger form-control'
                        : isChanged
                        ? 'border-warning form-control'
                        : ''
                }
                id={field.selector}
                onChange={({ target }) => {
                    const file = target.files[0];
                    handleChange(field.selector, file);
                }}
                required={required}
                type="file"
            />
        </div>
    );
};

export default VideoFileInput;
