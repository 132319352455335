import { quoting } from '@jmdigital/iservice3-utils';
import { getRoById, handleError } from './index';
import { store } from '../../storeConfig/store';
import { showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';

export const completeQuote = (repairOrderQuoteID, recommendations) => async (
    dispatch
) => {
    dispatch({ type: 'LOADING_START' });

    const customerResponses = recommendations
        .filter((rec) => !rec.preApproved)
        .map((rec) => {
            return {
                repairOrderQuoteRecommendationId: rec.id,
                approved: rec.approved === true,
            };
        });

    try {
        const response = await clientApi.post(`${Endpoints.quoteComplete}`, {
            repairOrderQuoteID,
            recommendations: JSON.stringify(customerResponses),
        });
        dispatch({ type: 'LOADING_END' });

        if (response.status === 200) {
            dispatch({ type: 'QUOTE_COMPLETED' });
            dispatch({ type: 'RO_OUTDATED' });
            dispatch(getRoById(store.getState().member.selectedRow.id));

            showToast({ message: 'Quote responses successfully submitted.' });
            return true;
        } else {
            throw new Error(
                'Failed to submit quote; please try again or contact your service advisor.'
            );
        }
    } catch (err) {
        dispatch(handleError(err));
    }
};

export const confirmQuote = (repairOrderQuoteID) => async (dispatch) => {
    dispatch({ type: 'CONFIRM_QUOTE' });

    try {
        const response = await clientApi.post(`${Endpoints.confirmQuote}`, {
            repairOrderQuoteID,
        });
        dispatch({ type: 'QUOTE_CONFIRMED' });
        dispatch({ type: 'RO_OUTDATED' });
        return response;
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const deleteQuote = (repairOrderQuoteID) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    try {
        const response = await clientApi.delete(
            `${Endpoints.quote}/${repairOrderQuoteID}`
        );
        dispatch({ type: 'LOADING_END' });
        dispatch({ type: 'RO_OUTDATED' });
        return response;
    } catch (error) {
        dispatch({ type: 'LOADING_END' });
        return dispatch(handleError(error));
    }
};

export const getLaborRates = () => async (dispatch) => {
    dispatch({ type: 'FETCH_LABOR_RATES' });
    try {
        const response = await clientApi.get(`${Endpoints.laborRates}`);
        if (response.status === 200) {
            return dispatch({
                data: response.data,
                type: 'RECEIVE_LABOR_RATES',
            });
        }
        throw new Error('Failed to fetch labor rates');
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const sendQuote = (repairOrderQuoteID) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    try {
        const response = await clientApi.post(`${Endpoints.sendQuote}`, {
            repairOrderQuoteID,
        });
        if (response?.status === 200) {
            showToast({
                message: 'Quote successfully sent',
            });
            dispatch({ type: 'LOADING_END' });
            dispatch({ type: 'RO_OUTDATED' });
            return true;
        }
        throw new Error('Failed to send quote');
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const syncQuote = (repairOrderId) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    try {
        const syncResponse = await clientApi.post(`${Endpoints.syncQuote}`, {
            id: repairOrderId,
        });
        if (syncResponse.status !== 200) {
            throw new Error(
                'Quote sync failed; please try again or contact support.'
            );
        }

        const rosResponse = await clientApi.get(
            `${Endpoints.ros}/${repairOrderId}`
        );
        const { data } = rosResponse;
        if (rosResponse.status !== 200 || !data.repairOrderQuote.id) {
            throw new Error(
                'Quote sync failed; please try again or contact support.'
            );
        }

        // Calculate tax on each by doing phantom rec updates
        // REMOVE THIS STUFF ONCE BACKEND IS CALCULATING TAX
        const { settings } = store.getState().member;
        const recsWithTax = data.repairOrderQuote.repairOrderQuoteRecommendations.map(
            (rec) => {
                // Tax is calculated regardless of whether there are changes to the rec
                const { updatedRec } = quoting.updateRecommendation(
                    rec,
                    rec,
                    [],
                    settings
                );

                return updatedRec;
            }
        );
        // Mutate the response AND attempt to save before saving it to state
        data.repairOrderQuote.repairOrderQuoteRecommendations = recsWithTax;
        const saveQuoteResponse = await clientApi.put(
            `${Endpoints.quote}/${data.repairOrderQuote.id}`,
            { recommendations: JSON.stringify(recsWithTax) }
        );

        if (saveQuoteResponse.status !== 200) {
            throw new Error(
                'Quote sync failed; please try again or contact support.'
            );
        }

        dispatch({ type: 'SET_SELECTED_ROW', data });
        dispatch({ type: 'LOADING_END' });
        showToast({
            message: 'Quote Refreshed',
        });

        return true;
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const transferQuote = (repairOrderQuoteID, status) => async (
    dispatch
) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'TRANSFER_QUOTE' });
    try {
        const response = await clientApi.post(`${Endpoints.transferQuote}`, {
            repairOrderQuoteID,
            status,
        });
        dispatch({ type: 'LOADING_END' });
        dispatch({ type: 'RO_OUTDATED' });

        showToast({
            message: 'Quote transferred',
        });
        return response;
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const submitQuote = (quote, params) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'SUBMIT_QUOTE' });
    try {
        const recommendationsWithParts = quote.recommendations
            .filter(recommendation => recommendation.parts.length > 0)

        // create custom parts array
        const customParts = recommendationsWithParts.map(recommendation => {
            return recommendation.parts.filter(part => part.isCustomPart)
        }).flat()

        quote.recommendations = JSON.stringify(quote.recommendations);
        const response =
            params.type === 'put'
                ? await clientApi.put(`${Endpoints.quote}/${params.id}`, quote)
                : await clientApi.post(Endpoints.quote, quote);

        if (response.status === 200) {
            showToast({ message: 'Quote saved' });
            dispatch(getRoById(store.getState().member.selectedRow.id));

            // update custom parts api
            if (customParts.length > 0) {
                Promise.all(
                    customParts.map(customPart => {
                        const body = { ...customPart, available: customPart.quantity }
                        return clientApi.post(Endpoints.parts, body)
                    })
                )
            }
            return true;
        } else {
            throw new Error(`Failed to save quote.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};
