import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';

const PercentageInput = ({
    field,
    handleBlur,
    handleChange,
    isChanged,
    isValid,
    required = false,
    value,
}) => {
    const [localValue, setLocalValue] = useState(Math.round(value * 100));

    useEffect(() => {
        // These only ever match on mount, no need to run handleChange
        if (value === localValue / 100) return;
        handleChange(field.selector, localValue / 100);
    }, [localValue]);
    return (
        <Input
            className={`form-control ${
                isValid === false
                    ? 'border-danger'
                    : isChanged
                    ? 'border-warning'
                    : ''
            }`}
            invalid={!isValid}
            id={field.selector}
            onBlur={handleBlur || null}
            onKeyDown={(e) => {
                if (e.key === 'e') e.preventDefault();
                if (e.key === '.') e.preventDefault();
            }}
            onChange={({ target }) => {
                setLocalValue(target.value === '' 
                    ? ''
                    : Math.floor(target.value));
            }}
            required={required}
            type="number"
            value={localValue}
        />
    );
};

export default PercentageInput;
