import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';
import { showToast } from '../../../utility/toast';
import { store } from '../../storeConfig/store';
import { generateParams, handleError, setSelectedRow } from './index';

// in customers, note, & quote
export const getRoById = (id) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'GET_RO_BY_ID' });

    try {
        const response = await clientApi.get(`${Endpoints.ros}/${id}`);
        if (response.status === 200) {
            dispatch({ type: 'RECEIVE_RO_BY_ID' });
            dispatch(
                setSelectedRow({
                    row: response.data,
                })
            );
            dispatch({ type: 'LOADING_END' });
            return true;
        } else {
            throw new Error(`Failed to get repair order.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const deleteRosWithinDateRange = (dateRange) => async (dispatch) => {
    dispatch({ type: 'DELETE_ROS_WITHIN_DATE_RANGE' });

    try {
        if (!dateRange[0] || !dateRange[1]) {
            throw new Error('Date range must have a start and end.');
        }
        const openDateStart = dateRange[0].toISOString().slice(0, 10);
        const openDateEnd = dateRange[1].toISOString().slice(0, 10);

        // Params serializer may not work for DELETE
        await clientApi.delete(
            `${Endpoints.ros}?openDateStart=${openDateStart}&openDateEnd=${openDateEnd}`
        );

        showToast({ message: 'ROs Deleted Successfully' });
        return true;
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const closeRepairOrder = (id) => async (dispatch) => {
    dispatch({ type: 'CLOSE_REPAIR_ORDER' });
    try {
        const response = await clientApi.put(
            `${Endpoints.ros}/${id}/close`,
            {}
        );
        if (response.status === 200) {
            dispatch({ type: 'REPAIR_ORDER_CLOSED' });
            dispatch({ type: 'RO_OUTDATED' });

            showToast({ message: 'Repair order closed successfully' });
        } else {
            throw new Error(`Failed to close Repair Order.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const getRos = (requestParams) => async (dispatch) => {
    dispatch({ type: 'GET_ROS' });

    const params = Object.assign(
        { ...generateParams(), pageLimit: 50 },
        requestParams
    );
    try {
        const response = await clientApi.get(Endpoints.ros, { params });
        dispatch({
            type: 'RECEIVE_ROS',
            data: response.data,
        });
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const pollForROs = (requestParams) => async (dispatch) => {
    try {
        const {
            openModal: openModalBefore,
            isLoading: isLoadingBefore,
            isFetchingROs: isFetchingROsBefore,
        } = store.getState().member;
        if (isLoadingBefore || isFetchingROsBefore || openModalBefore) {
            return dispatch({ type: 'POLL_ROS_CANCEL' });
        }
        dispatch({ type: 'POLL_ROS_GET' });
        const params = Object.assign(
            { ...generateParams(), pageLimit: 50 },
            requestParams
        );
        const response = await clientApi.get(Endpoints.ros, { params });

        const {
            openModal: openModalAfter,
            isLoading: isLoadingAfter,
        } = store.getState().member;
        if (isLoadingAfter || openModalAfter) {
            return dispatch({ type: 'POLL_ROS_CANCEL' });
        }
        if (response?.status === 200) {
            dispatch({
                type: 'POLL_ROS_RECEIVE',
                data: response.data,
            });
        }
    } catch (error) {
        dispatch({ type: 'POLL_ROS_CANCEL' });
        return dispatch(handleError(error));
    }
};

export const roOutdated = () => (dispatch) => {
    dispatch({ type: 'RO_OUTDATED' });
};

export const getRepairOrderInteractions = (repairOrderId) => async (
    dispatch
) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'GET_REPAIR_ORDER_INTERACTIONS' });

    try {
        const response = await clientApi.get(
            `${Endpoints.repairOrderInteractions}`,
            {
                params: { repairOrderId },
            }
        );

        if (response.status === 200) {
            dispatch({
                type: 'RECEIVE_REPAIR_ORDER_INTERACTIONS',
                data: response.data,
            });
            dispatch({ type: 'LOADING_END' });
            return true;
        } else {
            throw new Error(
                `Failed to get repair order interactions.${CONTACT_MESSAGE}`
            );
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const clearSelectedRepairOrder = () => (dispatch) => {
    dispatch({ type: 'CLEAR_SELECTED_REPAIR_ORDER' });
};
