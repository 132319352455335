import { format } from '@jmdigital/iservice3-utils';
import { states } from '../../../utility/constants';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import {
    addCustomerToRO,
    createCustomer,
    removeCustomerFromRO,
    toggleMobileValidationConfirmModal,
    updateCustomer,
} from '../../../redux/actions/member';
import validator from '../../../utility/helpers/validation';
import { showErrorToast } from '../../../utility/toast';

const CustomerForm = ({
    createCustomer,
    createOnly,
    customer,
    hideSubmitUntilChanges,
    isPhoneMobile,
    isReadOnly,
    newName,
    newPhone,
    handleSelectCustomer,
    toggle = () => null,
    toggleMobileValidationConfirmModal,
    updateCustomer,
}) => {
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [doNotContact, setDoNotContact] = useState('');
    const [hasChanges, setHasChanges] = useState(false);
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

    const resetState = () => {
        const cust = customer || {};

        setAddress1(cust.address1 || '');
        setAddress2(cust.address2 || '');
        setCity(cust.city || '');
        setDoNotContact(cust.doNotContact || false);
        setEmail(cust.email || '');
        setName(cust.name || newName || '');
        setPhone(cust.phone || newPhone || '');
        setState(cust.state || '');
        setZip(cust.zip || '');
        setHasAttemptedSubmit(false);
        setHasChanges(false);
    };

    useEffect(() => {
        resetState();
    }, [customer]);

    const handleSave = async (skipMobileValidation = false) => {
        setHasAttemptedSubmit(true);

        const isEmailValid = validator({
            type: 'email',
            value: email,
            enableToast: true,
        });
        const isPhoneValid = validator({
            type: 'phone',
            value: phone,
            enableToast: true,
        });

        if (!!email && !isEmailValid) {
            return;
        }
        if (!isPhoneValid) {
            return;
        }
        if (!name.trim()) {
            return showErrorToast({ message: 'Name is required' });
        }
        if (!phone.trim()) {
            return showErrorToast({ message: 'Phone number is required' });
        }

        const updated = {
            address1,
            address2,
            city,
            doNotContact,
            email,
            name,
            phone,
            state,
            zip,
        };

        if (!customer?.id) {
            const response = await createCustomer(
                updated,
                !createOnly && !handleSelectCustomer,
                skipMobileValidation
            );
            if (response) {
                if (!!handleSelectCustomer) handleSelectCustomer(response);
                setHasChanges(false);
                setHasAttemptedSubmit(false);
                return toggle();
            }

            return;
        }

        if (!hasChanges || !!handleSelectCustomer) return;

        updated.id = customer.id;

        const response = await updateCustomer(updated);
        if (response) {
            setHasChanges(false);
            setHasAttemptedSubmit(false);
        }
    };

    return (
        <>
            <Modal isOpen={isPhoneMobile === false}>
                <ModalHeader>
                    {`${format.phoneNumber(
                        phone
                    )} cannot be verified as mobile`}
                </ModalHeader>
                <ModalBody>
                    <p>
                        A phone number that can receive text messages on a
                        device with internet access is required.
                    </p>
                    <p>
                        Please double-check the phone number.{' '}
                        <strong>{`If it is correct and is able to receive text messages, click "Create Anyway." If not, please cancel and ask for a different number.`}</strong>
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="mr-2"
                        color="primary"
                        onClick={() => {
                            toggleMobileValidationConfirmModal();
                        }}
                    >
                        Try a Different Number
                    </Button>
                    <Button color="primary" onClick={() => handleSave(true)}>
                        Create Anyway
                    </Button>
                </ModalFooter>
            </Modal>
            <>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                                disabled={isReadOnly}
                                invalid={hasAttemptedSubmit && !name.trim()}
                                name="name"
                                onChange={({ target }) => {
                                    setName(target.value);
                                    setHasChanges(true);
                                }}
                                placeholder="Name"
                                required
                                type="text"
                                value={name}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="phone">Phone</Label>
                            <Input
                                disabled={isReadOnly}
                                invalid={
                                    hasAttemptedSubmit &&
                                    !validator({
                                        type: 'phone',
                                        value: phone,
                                    })
                                }
                                name="phone"
                                onChange={({ target }) => {
                                    const value = target.value?.replace(
                                        /[^\d]/gi,
                                        ''
                                    );
                                    setPhone(value);
                                    setHasChanges(true);
                                }}
                                placeholder="Phone"
                                required
                                type="text"
                                value={
                                    isReadOnly
                                        ? format.phoneNumber(phone)
                                        : phone
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="align-items-center d-flex mb-1">
                        <hr style={{ width: '40%' }} />
                        <span className="text-grey">
                            <small>OPTIONAL</small>
                        </span>
                        <hr style={{ width: '40%' }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                disabled={isReadOnly}
                                invalid={
                                    hasAttemptedSubmit &&
                                    !validator({
                                        type: 'email',
                                        value: email,
                                    })
                                }
                                name="email"
                                onChange={({ target }) => {
                                    setEmail(target.value);
                                    setHasChanges(true);
                                }}
                                placeholder="Email"
                                type="text"
                                value={email}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="doNotContact">Do not contact</Label>
                            <InputGroup>
                                <input
                                    disabled={isReadOnly}
                                    checked={doNotContact}
                                    name="doNotContact"
                                    onChange={() => {
                                        setDoNotContact(!doNotContact);
                                        setHasChanges(true);
                                    }}
                                    style={{
                                        marginLeft: '0.65em',
                                        height: '3em',
                                        transform: 'scale(2)',
                                    }}
                                    type="checkbox"
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="address1">Address</Label>
                            <Input
                                disabled={isReadOnly}
                                name="address1"
                                onChange={({ target }) => {
                                    setAddress1(target.value);
                                    setHasChanges(true);
                                }}
                                placeholder="Address"
                                type="text"
                                value={address1}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="address2">Address 2</Label>
                            <Input
                                disabled={isReadOnly}
                                name="address2"
                                onChange={({ target }) => {
                                    setAddress2(target.value);
                                    setHasChanges(true);
                                }}
                                placeholder="Address 2"
                                type="text"
                                value={address2}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="city">City</Label>
                            <Input
                                disabled={isReadOnly}
                                name="city"
                                onChange={({ target }) => {
                                    setCity(target.value);
                                    setHasChanges(true);
                                }}
                                placeholder="City"
                                type="text"
                                value={city}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="3">
                        <FormGroup>
                            <Label for="state">State</Label>
                            <Input
                                disabled={isReadOnly}
                                name="state"
                                onChange={({ target }) => {
                                    setState(target.value);
                                    setHasChanges(true);
                                }}
                                type="select"
                                value={state}
                            >
                                <option disabled value={''}>
                                    Select...
                                </option>
                                {states?.map((current) => (
                                    <option key={`state-${current}`}>
                                        {current}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="zip">Zip</Label>
                            <Input
                                disabled={isReadOnly}
                                name="zip"
                                onChange={({ target }) => {
                                    setZip(target.value);
                                    setHasChanges(true);
                                }}
                                placeholder="Zip"
                                type="number"
                                value={zip}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
            {(!!hasChanges || !hideSubmitUntilChanges) && (
                <Row className="mb-1 text-right">
                    <Col>
                        <Button
                            color="primary"
                            onClick={() => {
                                resetState();
                                toggle();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-2"
                            color="success"
                            disabled={isReadOnly || !hasChanges}
                            onClick={handleSave}
                            type="submit"
                        >
                            {!!customer?.id ? 'Save' : 'Create'}
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    repairOrder: state.member.selectedRow,
    isPhoneMobile: state.member.isPhoneValid,
});
export default connect(mapStateToProps, {
    addCustomerToRO,
    createCustomer,
    removeCustomerFromRO,
    toggleMobileValidationConfirmModal,
    updateCustomer,
})(CustomerForm);
