import { format } from '@jmdigital/iservice3-utils';
import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import ChatSideBar from './ChatSidebar';
import MainChatScreen from './MainChatScreen';
import CustomModalHeader from '../../components/custom/atoms/CustomModalHeader';
import CreateNewChatModal from './CreateNewChatModal';

import { setSelectedRecipient } from '../../redux/actions/member';
import { connect } from 'react-redux';

const ChatModal = ({
    isOpen,
    messageType,
    selectedRecipient,
    setSelectedRecipient,
    toggle,
}) => {
    const [bypassThreadReload, setBypassThreadReload] = useState(false);
    const [isNewChatModalOpen, setIsNewChatModalOpen] = useState(false);
    const [resetThreads, setResetThreads] = useState(null);

    const handleSelectThread = (thread) => {
        setSelectedRecipient(thread.user);
        setBypassThreadReload(true);
    };
    return (
        <Modal isOpen={isOpen} size="lg" toggle={toggle}>
            <CreateNewChatModal
                isOpen={isNewChatModalOpen}
                messageType={messageType}
                setResetThreads={setResetThreads}
                toggle={() => setIsNewChatModalOpen(false)}
            />
            <CustomModalHeader
                title={`${messageType}${
                    selectedRecipient
                        ? ` - [ ${
                              selectedRecipient.firstName ||
                              selectedRecipient.name
                          }${
                              messageType === 'Service SMS'
                                  ? ''
                                  : ` ${selectedRecipient.lastName || ''}`
                          } - ${format.phoneNumber(selectedRecipient.phone)} ]`
                        : ''
                }`}
                toggle={toggle}
            />
            <div className="d-flex">
                <ChatSideBar
                    bypassThreadReload={bypassThreadReload}
                    handleSelectThread={handleSelectThread}
                    messageType={messageType}
                    reset={resetThreads}
                    setBypassThreadReload={setBypassThreadReload}
                    setIsNewChatModalOpen={setIsNewChatModalOpen}
                />
                <MainChatScreen
                    messageType={messageType}
                    setIsNewChatModalOpen={setIsNewChatModalOpen}
                    setResetThreads={setResetThreads}
                />
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    selectedRecipient: state.member.selectedRecipient,
});
export default connect(mapStateToProps, {
    setSelectedRecipient,
})(ChatModal);
