import React from 'react';
import { Button } from 'reactstrap';
import XLSX from 'xlsx';

const downloadExcel = (arrayOfObjects, filename) => {
    const header = Object.keys(arrayOfObjects[0]);
    const headerFormat = header.map((e) =>
        e
            .split('_')
            .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
            .join(' ')
    );
    const worksheet = XLSX.utils.aoa_to_sheet([headerFormat]);

    XLSX.utils.sheet_add_json(worksheet, arrayOfObjects, {
        skipHeader: true,
        origin: 'A2',
        cellDates: true,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'export');
    XLSX.writeFile(workbook, `${filename}.xlsx`);
};

const ExcelExportButton = ({ dataArrayOfObjects, filename, size }) => {
    return (
        <Button.Ripple
            className="mr-1"
            color="primary"
            onClick={() => downloadExcel(dataArrayOfObjects, filename)}
            size={size}
        >
            Excel
        </Button.Ripple>
    );
};

export default ExcelExportButton;
