import React from 'react';
import MoneyInput from '../../molecules/MoneyInput';

// NON RO ONLY TODO RENAME
const WrappedMoneyInput = ({ field, handleChange, record, value }) => {
    // Create allows editing
    if (!record)
        return (
            <MoneyInput
                amountCents={value * 100}
                handleChange={(integerCents) => {
                    handleChange(field.selector, integerCents / 100);
                }}
                labelText={''}
            />
        );

    const { refundedAmount, transactionId } = record;
    const fullyRefunded =
        refundedAmount == record.amount && refundedAmount != '0.00';
    const partiallyRefunded = refundedAmount !== '0.00' && !fullyRefunded;
    const balance = parseFloat(value) - parseFloat(refundedAmount);

    return (
        <>
            <MoneyInput
                inputDisabled={!!transactionId}
                amountCents={!transactionId ? value * 100 : balance * 100}
                handleChange={(integerCents) => {
                    handleChange(field.selector, integerCents / 100);
                }}
                labelText={''}
            />
            {fullyRefunded ? (
                <h4 className="text-danger m-0">{`$${value} FULLY REFUNDED`}</h4>
            ) : partiallyRefunded ? (
                <h4 className="text-danger m-0">{`$${refundedAmount} REFUNDED of $${value}`}</h4>
            ) : null}
        </>
    );
};

export default WrappedMoneyInput;
