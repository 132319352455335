import { getRoById, handleError } from './index';
import { store } from '../../storeConfig/store';
import { showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';

export const requestSupport = ( data ) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    const { name, email, message, phone } = data;
    try {
        const response = await clientApi.post(Endpoints.support, {name, email, message, phone}); // uncomment when utils updated with endpoint
        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });

            showToast({ message: 'Support request sent' });
            return true;
        } else throw new Error(`Failed to send request.${CONTACT_MESSAGE}`);
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};