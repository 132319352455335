import { format } from '@jmdigital/iservice3-utils';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'reactstrap';
import CustomModalBody from '../atoms/CustomModalBody';
import CustomModalFooter from '../atoms/CustomModalFooter';
import CustomModalHeader from '../atoms/CustomModalHeader';
import MoneyInput from '../molecules/MoneyInput';
import getMyUser from '../../../utility/helpers/getMyUser';
import { Permissions, UserRoles } from '../../../utility/constants';

const RefundModal = ({
    amountCents,
    handleConfirm,
    handleChange,
    isOpen,
    isReadOnly,
    payment,
    toggle,
}) => {
    const [hasPermission, setHasPermission] = useState();
    const refundedAmountCents = parseInt(
        payment?.refundedAmount?.replace(/\D/g, '') || 0
    );
    const paymentAmountCents = parseInt(
        payment?.amount?.replace(/\D/g, '') || 0
    );
    const availableAmountCents = paymentAmountCents - refundedAmountCents;
    const amountInvalid =
        amountCents > availableAmountCents || amountCents <= 0;

    useEffect(() => {
        const user = getMyUser();
        const isRolePermitted = Permissions.refundPayments.includes(user.role);

        setHasPermission(user.processRefund || isRolePermitted);
    }, []);

    useEffect(() => {
        if (isOpen) handleChange(paymentAmountCents - refundedAmountCents);
    }, [payment]);

    if (!payment) return null;

    const refundedAmountFormatted = format.money(refundedAmountCents / 100);
    const paymentAmountFormatted = format.money(paymentAmountCents / 100);
    const amountEnteredFormatted = format.money(amountCents / 100);
    const availableAmountFormatted = format.money(availableAmountCents / 100);
    return (
        <Modal isOpen={isOpen} toggle={toggle} scrollable>
            <CustomModalHeader title={`Refund Payment`} toggle={toggle} />
            {hasPermission ? (
                <CustomModalBody>
                    <h5>
                        {`You are refunding ${amountEnteredFormatted} to the customer`}
                    </h5>
                    <p>
                        This payment was for {paymentAmountFormatted}
                        {refundedAmountCents > 0 && (
                            <>
                                <span>
                                    , of which {refundedAmountFormatted} has
                                    already been refunded.
                                    <br />
                                    <br />
                                    The remaining payment available to refund is{' '}
                                    <strong>{availableAmountFormatted}</strong>
                                </span>
                                .
                            </>
                        )}
                    </p>
                    <MoneyInput
                        buttonColor="danger"
                        buttonDisabled={
                            isReadOnly || !amountCents || amountInvalid
                        }
                        buttonText="Refund"
                        invalid={amountInvalid}
                        handleChange={handleChange}
                        handleClick={() => {
                            if (!amountInvalid) handleConfirm();
                        }}
                        labelText=""
                        amountCents={amountCents}
                    />
                    {amountInvalid && (
                        <p className="text-danger">
                            Enter a nonzero value less than the remaining
                            payment amount.
                        </p>
                    )}
                </CustomModalBody>
            ) : (
                <CustomModalBody>
                    <h5>
                        Please contact a service advisor to gain access to
                        refund permission
                    </h5>
                </CustomModalBody>
            )}
            <CustomModalFooter>
                <Button color="primary" onClick={toggle}>
                    {hasPermission ? 'Cancel' : 'Ok'}
                </Button>
            </CustomModalFooter>
        </Modal>
    );
};

export default RefundModal;
