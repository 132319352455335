import { handleError } from './index';
import { store } from '../../storeConfig/store';
import clientApi from '../../../api/clientApi';
import {
    CONTACT_MESSAGE,
    Endpoints,
    PageLimits,
} from '../../../utility/constants';
import { showToast } from '../../../utility/toast';

export const setCurrentMessagePage = (page) => (dispatch) => {
    dispatch({ type: 'SET_CURRENT_MESSAGE_PAGE', data: page });
};

export const pollForNewMessage = (messageType, otherUserId) => async (
    dispatch,
    getState
) => {
    const { isSignout } = store.getState().auth.login;
    if (isSignout) return;

    const params = { page: 1, pageLimit: PageLimits.messages };
    const otherUserIdPropName =
        messageType === 'Internal Messages'
            ? 'otherUserId'
            : messageType === 'Service SMS'
            ? 'customer'
            : 'unknown';
    params[otherUserIdPropName] = otherUserId;
    dispatch({ type: 'POLL_MESSAGES' });

    try {
        const response = await clientApi.get(
            `${Endpoints[messageType]}/messages`,
            { params }
        );
        if (response.status === 200) {
            const totalExisting = getState().member.messages?.totalResults;
            const totalIncoming = response.data.totalResults;
            const hasNew = totalExisting < totalIncoming;

            if (hasNew) {
                dispatch(
                    receiveMessages(response.data, messageType, params.page)
                );
            }

            // else do nothing
        } else {
            throw new Error(
                `Failed to poll unread messages.${CONTACT_MESSAGE}`
            );
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

// Internal Messaging
export const pollUnreadMessageCounts = () => async (dispatch) => {
    const { isSignout } = store.getState().auth.login;
    if (isSignout) return;
    try {
        const response = await clientApi.get(Endpoints.unreadMessageCounts);
        if (response.status === 200) {
            dispatch({
                type: 'UPDATE_UNREAD_MESSAGE_COUNTS',
                data: response.data,
            });
        } else {
            throw new Error(
                `Failed to poll unread messages.${CONTACT_MESSAGE}`
            );
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};
export const sendMessage = (messageType, toId, message) => async (dispatch) => {
    dispatch({ type: 'SEND_MESSAGE' });

    const body = { message };
    const toPropName =
        messageType === 'Internal Messages'
            ? 'toId'
            : messageType === 'Service SMS'
            ? 'customerID'
            : 'unknown';
    body[toPropName] = toId;

    try {
        const response = await clientApi.post(Endpoints[messageType], body);

        if (response.status === 200) {
            return true;
        } else {
            throw new Error(`Failed to send message.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};
export const getThreads = (messageType, page = 1, pageLimit = 10) => async (
    dispatch
) => {
    const params = { page, pageLimit };
    dispatch({ type: 'GET_THREADS' });
    try {
        const response = await clientApi.get(
            `${Endpoints[messageType]}/threads`,
            { params }
        );
        if (response.status === 200) {
            // Transform if not internal, because this is our life now
            if (messageType === 'Service SMS') {
                response.data.results = response.data.results.map((thread) => {
                    return {
                        ...thread,
                        user: {
                            id: thread.id,
                            firstName: thread.name,
                            phone: thread.phone,
                        },
                        unreads: thread.unread,
                        message: { message: thread.message },
                    };
                });
            }

            dispatch({ type: 'RECEIVE_THREADS', data: response.data });
            return response.data;
        } else {
            throw new Error(`Failed to get conversations.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const resetMessages = () => (dispatch) => {
    dispatch({ type: 'RESET_MESSAGES' });
};

export const getMessages = (messageType, otherUserId, page = 1) => async (
    dispatch,
    getState
) => {
    const { selectedRecipient } = getState().member;
    if (selectedRecipient?.id != otherUserId) return;

    const params = { page, pageLimit: PageLimits.messages };
    const otherUserIdPropName =
        messageType === 'Internal Messages'
            ? 'otherUserId'
            : messageType === 'Service SMS'
            ? 'customer'
            : 'unknown';
    params[otherUserIdPropName] = otherUserId;

    try {
        dispatch({ type: 'GET_MESSAGES' });
        if (!otherUserId)
            throw new Error(`Unable to request messages.${CONTACT_MESSAGE}`);
        const response = await clientApi.get(
            `${Endpoints[messageType]}/messages`,
            { params }
        );

        if (response.status === 200) {
            dispatch(receiveMessages(response.data, messageType, params.page));
            return true;
        } else {
            throw new Error(`Failed to get messages.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        dispatch(handleError(error));
        return false;
    }
};

export const receiveMessages = (messages, messageType, page) => (
    dispatch,
    getState
) => {
    if (!messages.results.length) {
        messages.results = [];
    } else if (messageType === 'Service SMS') {
        messages.results = messages.results.map((msg) => {
            const customer = {
                id: msg.customer.id,
                firstName: msg.customer.name,
                phone: msg.customer.phone,
            };

            return {
                ...msg,
                from: msg.incoming ? customer : msg.user,
                to: msg.incoming ? msg.user : customer,
                unread: msg.isRead === '0' || msg.unread === 'false',
            };
        });
    }
    messages.pageLimit = PageLimits.messages;
    const { results } = JSON.parse(JSON.stringify(messages));
    messages.results.reverse();
    dispatch({
        type: 'RECEIVE_MESSAGES',
        data: messages,
    });

    dispatch(getThreads(messageType));
    dispatch(pollUnreadMessageCounts());

    if (page === 1) {
        const { latestMessage, selectedRecipient } = getState().member;
        const hasNewMessage =
            selectedRecipient?.id === latestMessage?.otherUserId &&
            results[0]?.id !== latestMessage?.id &&
            results[0]?.from?.id === selectedRecipient?.id;
        dispatch({
            type: 'UPDATE_LATEST_MESSAGE',
            data: {
                hasNewMessage,
                message: {
                    otherUserId: selectedRecipient?.id,
                    id: results[0]?.id,
                },
            },
        });
    }
};

export const createNewUserAndSendMessage = (customerInfo, message) => async (
    dispatch
) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'CREATE_NEW_CUSTOMER' });
    try {
        const response = await clientApi.post(
            Endpoints.customers,
            customerInfo
        );
        if (response.status === 200) {
            const { id } = response.data;
            await dispatch(sendMessage('Service SMS', id, message));
            dispatch({ type: 'LOADING_END' });

            return response;
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const markThreadAsRead = (customerID) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'MARK_THREAD_AS_READ' });

    try {
        const response = await clientApi.post(Endpoints.serviceSMSMarkRead, {
            customerID,
        });
        if (response.status === 200) {
            showToast({
                type: 'success',
                message: 'Successfully marked thread as read.',
            });
            await dispatch(getThreads('Service SMS'));
            await dispatch(getMessages('Service SMS', customerID));
            dispatch({ type: 'LOADING_END' });
        } else {
            throw new Error('Failed to mark thread as read.');
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};
