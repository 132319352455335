import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Form,
    FormGroup,
    Row,
    Col,
    Input,
    Label,
    Spinner,
} from 'reactstrap';
import CustomModalBody from '../../components/custom/atoms/CustomModalBody';
import CustomModalHeader from '../../components/custom/atoms/CustomModalHeader';
import CustomerAsyncSelect from '../../components/custom/molecules/CustomerAsyncSelect';
import UserAsyncSelect from '../../components/custom/molecules/UserAsyncSelect';
import {
    createNewUserAndSendMessage,
    getCustomers,
    getUsers,
    sendMessage,
    setSelectedRecipient,
} from '../../redux/actions/member';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import getMyUser from '../../utility/helpers/getMyUser';

const CreateNewChat = ({
    getUsers,
    isOpen,
    messageType,
    selectedRecipient,
    sendMessage,
    setResetThreads,
    setSelectedRecipient,
    toggle,
}) => {
    const [inputText, setInputText] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [userId, setUserId] = useState();

    useEffect(() => {
        const user = getMyUser();
        setUserId(user?.id);
    }, []);

    useEffect(() => {
        setInputText('');
        setIsSending(false);
    }, [isOpen]);

    const handleSendMessage = async () => {
        setIsSending(true);

        const success = await sendMessage(
            messageType,
            selectedRecipient.id,
            inputText
        );

        if (success) {
            setResetThreads((prevState) => !prevState);
            return toggle();
        }

        setIsSending(false);
    };

    return (
        <Modal
            style={{ zIndex: 99999999 }}
            size="lg"
            isOpen={isOpen}
            toggle={toggle}
        >
            <CustomModalHeader
                className="text-white"
                title={`Compose New Message${
                    selectedRecipient
                        ? ` - ${
                              selectedRecipient.name ||
                              selectedRecipient.lastName +
                                  ' ' +
                                  selectedRecipient.lastName
                          }`
                        : ''
                }`}
                toggle={toggle}
            />
            <CustomModalBody>
                {isSending ? (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Spinner />
                    </div>
                ) : (
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            handleSendMessage();
                        }}
                    >
                        Choose a recipient:
                        <Row>
                            <Col>
                                {messageType === 'Service SMS' ? (
                                    <CustomerAsyncSelect
                                        canCreateNew
                                        handleSelectedOption={
                                            setSelectedRecipient
                                        }
                                        sortField="id"
                                    />
                                ) : (
                                    <UserAsyncSelect
                                        handleSelectedOption={
                                            setSelectedRecipient
                                        }
                                        sortField="id"
                                    />
                                )}
                            </Col>
                            <Col />
                        </Row>
                        <FormGroup className="mt-2">
                            <Label
                                for="chatStartMsg"
                                className="settings-label"
                            >
                                Message
                            </Label>
                            {selectedRecipient?.doNotContact ? (
                                <div className="p-1">
                                    <h4>
                                        This customer is marked "do not
                                        contact."
                                    </h4>
                                    <p>
                                        This can be changed in the Customer
                                        section of RO Details.
                                    </p>
                                </div>
                            ) : (
                                <Input
                                    className="form-control"
                                    id="chatStartMsg"
                                    type="text"
                                    placeholder="Enter your message here..."
                                    value={inputText}
                                    onChange={({ target }) => {
                                        setInputText(target.value);
                                    }}
                                    onKeyPress={(event) => {
                                        event.persist();
                                        const { keyCode } = event.nativeEvent;
                                        if (
                                            keyCode === 13 &&
                                            !!selectedRecipient &&
                                            !!inputText &&
                                            !selectedRecipient?.doNotContact
                                        )
                                            return handleSendMessage();
                                    }}
                                />
                            )}
                        </FormGroup>
                    </Form>
                )}
            </CustomModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Cancel
                </Button>
                <Button
                    color="success"
                    disabled={!inputText || !selectedRecipient}
                >
                    Send
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    selectedRecipient: state.member.selectedRecipient,
});

export default connect(mapStateToProps, {
    createNewUserAndSendMessage,
    getCustomers,
    getUsers,
    sendMessage,
    setSelectedRecipient,
})(CreateNewChat);
