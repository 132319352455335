import { format } from '@jmdigital/iservice3-utils';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import ThreadItem from './ThreadItem';
import { getThreads, setSelectedRecipient } from '../../redux/actions/member';
import CustomPagination from '../../components/custom/atoms/CustomPagination';
import UserAsyncSelect from '../../components/custom/molecules/UserAsyncSelect';
import CustomerAsyncSelect from '../../components/custom/molecules/CustomerAsyncSelect';
import getMyUser from '../../utility/helpers/getMyUser';

const PAGE_LIMIT = 50;
const POLLING_INTERVAL = 60 * 1000 + Math.floor(Math.random() * 1000);

const ChatSideBar = ({
    getThreads,
    handleSelectThread,
    messageType,
    reset,
    selectedRecipient,
    setSelectedRecipient,
    threads,
}) => {
    const timer = useRef();

    const [currentPage, setCurrentPage] = useState([1]);
    const [isLoading, setIsLoading] = useState(true);
    const [userId, setUserId] = useState();

    useEffect(() => {
        cancelPollThreads();
        pollThreads();
        const user = getMyUser();
        setUserId(user.id);
        return cancelPollThreads;
    }, []);

    // Step 1: Turn to page one
    useEffect(() => {
        setCurrentPage([1]);
    }, [messageType]);

    // Step 2: On changing page, get new threads
    useEffect(() => {
        if (currentPage === null) return;
        fetchThreads();
        pollThreads();
    }, [currentPage]);

    // Step 3: When a new conversation is entered, get threads
    useEffect(() => {
        if (reset === null) return;
        setCurrentPage([1]);
    }, [reset]);

    const cancelPollThreads = () => {
        if (timer.current) clearInterval(timer.current);
        timer.current = null;
    };

    const fetchThreads = async () => {
        setIsLoading(true);
        const response = await getThreads(
            messageType,
            currentPage[0],
            PAGE_LIMIT
        );
        if (response) setIsLoading(false);
    };

    const pollThreads = () => {
        clearInterval(timer.current);
        timer.current = setInterval(() => {
            getThreads(messageType, currentPage[0], PAGE_LIMIT);
        }, POLLING_INTERVAL);
    };

    return (
        <Card className="sidebar-content ">
            <div className="w-100">
                {userId &&
                    (messageType === 'Service SMS' ? (
                        <CustomerAsyncSelect
                            canCreateNew
                            placeholder="Search for a recipient"
                            handleSelectedOption={setSelectedRecipient}
                        />
                    ) : (
                        <UserAsyncSelect
                            placeholder="Search for a recipient"
                            handleSelectedOption={setSelectedRecipient}
                        />
                    ))}
            </div>
            {isLoading ? (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <b>Loading...</b>
                    <Spinner />
                </div>
            ) : (
                <>
                    <div
                        className="chat-user-list list-group"
                        style={{
                            margin: 0,
                            overflowY: 'scroll',
                            width: '100%',
                        }}
                    >
                        {threads?.results?.length ? (
                            <ul className="chat-users-list-wrapper media-list">
                                {threads?.results.map((thread, index) => (
                                    <ThreadItem
                                        handleSelectThread={handleSelectThread}
                                        key={`thread-${index}`}
                                        thread={thread}
                                        selectedRecipient={selectedRecipient}
                                    />
                                ))}
                            </ul>
                        ) : (
                            <div className="d-flex align-items-center">
                                <h3 className="danger p-1 mb-0">No Threads</h3>
                            </div>
                        )}
                        <div>
                            {!!threads?.results?.length &&
                                threads?.totalPages > 1 && (
                                    <CustomPagination
                                        currentPage={currentPage[0]}
                                        handleChangePage={(page) =>
                                            setCurrentPage([page])
                                        }
                                        resultsPerPage={PAGE_LIMIT}
                                        totalRows={threads.totalResults}
                                        style={{ alignSelf: 'center' }}
                                    />
                                )}
                        </div>
                    </div>
                </>
            )}
        </Card>
    );
};

const mapStateToProps = (state) => ({
    threads: state.member.threads,
    selectedRecipient: state.member.selectedRecipient,
});
export default connect(mapStateToProps, {
    getThreads,

    setSelectedRecipient,
})(ChatSideBar);
