import React, { useEffect, useRef, useState } from 'react';
import {
    Col,
    Button,
    Form,
    Input,
    PaginationItem,
    PaginationLink,
    Row,
    Spinner,
} from 'reactstrap';
import { MessageSquare, Printer, Send } from 'react-feather';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import {
    getMessages,
    markThreadAsRead,
    pollForNewMessage,
    resetMessages,
    sendMessage,
    setCurrentMessagePage,
    setprintableDoc,
} from '../../redux/actions/member';
import { Permissions } from '../../utility/constants';
import CustomPagination from '../../components/custom/atoms/CustomPagination';
import getMyUser from '../../utility/helpers/getMyUser';
import PrintableDoc from '../../components/custom/organisms/PrintableDoc';

const MAX_CHARACTER_LENGTH = 160;
const POLLING_INTERVAL = 60 * 1000 + Math.floor(Math.random() * 1000);

const MainChatScreen = ({
    detailsModal,
    getMessages,
    hasNewMessage,
    markThreadAsRead,
    messages,
    messageType,
    pollForNewMessage,
    resetMessages,
    selectedRecipient,
    sendMessage,
    setResetThreads,
    setCurrentMessagePage,
    setprintableDoc,
    settings,
    userRole,
}) => {
    const timer = useRef();
    const messagesRef = useRef();

    // currentPage is non-native so that it refreshes even when set to the same page
    const [currentPage, setCurrentPage] = useState([1]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isPrinting, setIsPrinting] = useState(false);
    const [myUser, setMyUser] = useState([]);
    const [scrollReference, setScrollReference] = useState(null);

    const history = useHistory();

    useEffect(() => {
        cancelPollForNewMessage();
        const user = getMyUser();
        setMyUser(user);
        return cancelPollForNewMessage;
    }, []);

    useEffect(() => {
        if (!isPrinting) return;
        let jsxComponent = (
            <PrintableDoc
                settings={settings}
                typeText="Messages"
                close={() => setIsPrinting(false)}
            >
                {messageBubbles}
            </PrintableDoc>
        );
        if (isPrinting) {
            setprintableDoc(jsxComponent);
            history.push('/print');
        }
    }, [isPrinting]);

    // Step 1: Turn to page one
    useEffect(() => {
        cancelPollForNewMessage();
        if (!selectedRecipient) {
            resetMessages();
            return setIsLoading(false);
        }

        timer.current = setInterval(() => {
            pollForNewMessage(messageType, selectedRecipient.id);
        }, POLLING_INTERVAL);
        setIsLoading(true);
        setCurrentPage([1]);
    }, [selectedRecipient]);

    // Step 2: On changing page, get new messages
    useEffect(() => {
        if (!selectedRecipient) return;
        fetchMessages();
        setCurrentMessagePage(currentPage[0]);
    }, [currentPage]);

    useEffect(() => {
        if (!scrollReference) return;
        scrollReference.scrollIntoView();
    }, [messages, scrollReference]);

    const cancelPollForNewMessage = () => {
        clearInterval(timer.current);
        timer.current = null;
    };

    const fetchMessages = async () => {
        setIsLoading(true);
        const response = await getMessages(
            messageType,
            selectedRecipient.id,
            currentPage[0]
        );
        if (response) {
            setIsLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (inputText === '') return;
        setIsLoading(true);

        const messageText = inputText;
        setInputText('');

        await sendMessage(messageType, selectedRecipient?.id, messageText);
        setCurrentPage([1]);
        !!selectedRecipient &&
            setResetThreads &&
            setResetThreads((prevState) => !prevState);
    };

    let doNotContact = false;
    if (messages?.results?.length) {
        doNotContact = !!messages.results[0].customer?.doNotContact;
    }

    const messageBubbles = messages?.results?.map((message, index) => {
        return (
            <ChatMessage
                isOutgoing={myUser.id === message.from?.id}
                key={`chat-message-${index}`}
                message={message}
                user={message.from}
            />
        );
    });

    return (
        <div className={detailsModal ? '' : 'content-right'}>
            <div
                className="chat-app-window"
                style={{ height: '100%', position: 'relative' }}
            >
                <div
                    className="iservice-start-chat-area border border-light"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        position: 'relative',
                    }}
                >
                    {hasNewMessage && (
                        <h4
                            className="bg-danger text-white"
                            onClick={() => setCurrentPage([1])}
                            style={{ cursor: 'pointer', top: 0 }}
                        >
                            New Message! (click to view)
                        </h4>
                    )}
                    {messages?.results ? (
                        <>
                            <div
                                className="chat-user-list h-100 list-group"
                                ref={messagesRef}
                                style={{
                                    overflowY: 'scroll',
                                }}
                            >
                                {!isLoading && messages?.totalPages > 1 && (
                                    <div
                                        className="d-flex h-100 justify-content-center"
                                        style={{ listStyle: 'none' }}
                                    >
                                        <PaginationItem
                                            disabled
                                            style={{
                                                borderRadius: 0,
                                                padding: 0,
                                            }}
                                            key={`pagination-newer`}
                                        >
                                            <PaginationLink
                                                style={{
                                                    borderRadius: 0,
                                                    color: 'black',
                                                    padding:
                                                        '0.65rem 0.911rem !important',
                                                }}
                                            >
                                                Newer
                                            </PaginationLink>
                                        </PaginationItem>
                                        <CustomPagination
                                            currentPage={currentPage[0]}
                                            handleChangePage={(page) =>
                                                setCurrentPage([page])
                                            }
                                            resultsPerPage={messages.pageLimit}
                                            totalRows={messages.totalResults}
                                        />
                                        <PaginationItem
                                            disabled
                                            style={{
                                                borderRadius: 0,
                                                padding: 0,
                                            }}
                                            key={`pagination-older`}
                                        >
                                            <PaginationLink
                                                style={{
                                                    borderRadius: 0,
                                                    color: 'black',
                                                    padding:
                                                        '0.65rem 0.911rem !important',
                                                }}
                                            >
                                                Older
                                            </PaginationLink>
                                        </PaginationItem>
                                    </div>
                                )}
                                {isLoading ? (
                                    <div className="align-items-center d-flex flex-column h-100 justify-content-center position-relative w-100">
                                        <b>Loading...</b>
                                        <Spinner />
                                    </div>
                                ) : (
                                    <div
                                        className="user-chats h-100"
                                        style={{
                                            minHeight: '100%',
                                        }}
                                    >
                                        {messageBubbles}
                                        <div ref={setScrollReference} />
                                    </div>
                                )}
                            </div>
                            {doNotContact ? (
                                <div className="p-1">
                                    <h4>
                                        This customer is marked "do not
                                        contact."
                                    </h4>
                                    <p>
                                        This can be changed in the Customer
                                        section of RO Details.
                                    </p>
                                </div>
                            ) : (
                                <div
                                    className="chat-app-form"
                                    style={{
                                        padding: 10,
                                        width: '100%',
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            <Form
                                                className="d-flex align-items-center w-100"
                                                onSubmit={(event) => {
                                                    event.preventDefault();
                                                    handleSubmit();
                                                }}
                                            >
                                                <Input
                                                    className="message mr-1"
                                                    onChange={(event) => {
                                                        if (
                                                            event.target.value
                                                                .length >=
                                                            MAX_CHARACTER_LENGTH
                                                        )
                                                            return setInputText(
                                                                event.target.value.substr(
                                                                    0,
                                                                    MAX_CHARACTER_LENGTH
                                                                )
                                                            );
                                                        setInputText(
                                                            event.target.value
                                                        );
                                                    }}
                                                    placeholder="Type your message"
                                                    type="text"
                                                    value={inputText || ''}
                                                />
                                                {messageType !==
                                                'Internal Messages' ? (
                                                    <small
                                                        className="mx-1"
                                                        style={{
                                                            color:
                                                                inputText.length >=
                                                                MAX_CHARACTER_LENGTH
                                                                    ? 'red'
                                                                    : 'black',
                                                        }}
                                                    >
                                                        {inputText.length}/
                                                        {MAX_CHARACTER_LENGTH}
                                                    </small>
                                                ) : null}
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                >
                                                    <Send
                                                        className="d-lg-none"
                                                        size={15}
                                                    />
                                                    <span className="d-lg-block d-none">
                                                        Send
                                                    </span>
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            <Row className="bg-white mx-0 pb-1 pt-1">
                                {messageBubbles?.length > 0 && (
                                    <Col>
                                        <Button
                                            block
                                            color="primary"
                                            onClick={() => setIsPrinting(true)}
                                        >
                                            <Printer size={14} /> Print Messages
                                        </Button>
                                    </Col>
                                )}
                                {Permissions.canMarkMessageAsRead.includes(
                                    userRole
                                ) &&
                                    messageType === 'Service SMS' && (
                                        <Col>
                                            <Button
                                                block
                                                color="success"
                                                disabled={messages?.results?.every(
                                                    (message) => message.isRead
                                                )}
                                                onClick={() =>
                                                    markThreadAsRead(
                                                        selectedRecipient.id
                                                    )
                                                }
                                            >
                                                Mark Thread as Read
                                            </Button>
                                        </Col>
                                    )}
                            </Row>
                        </>
                    ) : (
                        <div className="d-flex flex-column w-50 justify-content-center align-items-center m-auto">
                            <span className="mb-1 start-chat-icon">
                                <MessageSquare size={50} />
                            </span>
                            {!selectedRecipient && (
                                <h4 className="start-chat-text py-25 px-1 sidebar-toggle">
                                    Select a thread or search for a recipient
                                </h4>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    hasNewMessage: state.member.hasNewMessage,
    messages: state.member.messages,
    selectedRecipient: state.member.selectedRecipient,
    settings: state.member.settings,
    userRole: state.auth.login.userRole,
});
export default connect(mapStateToProps, {
    getMessages,
    markThreadAsRead,
    pollForNewMessage,
    resetMessages,
    sendMessage,
    setCurrentMessagePage,
    setprintableDoc,
})(MainChatScreen);
