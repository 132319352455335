import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
const CustomPagination = ({
    currentPage = 1,
    handleChangePage,
    resultsPerPage = 50,
    totalRows,
}) => {
    const changePage = (page) => {
        if (page === currentPage) return;
        handleChangePage(page);
    };

    const lastPage = Math.ceil(totalRows / resultsPerPage) || 1;
    const pageLinks = [
        <PaginationItem
            style={{ borderRadius: 0, padding: 0 }}
            active={currentPage === 1}
            key={`pagination-1`}
        >
            <PaginationLink
                onClick={() => changePage(1)}
                style={{
                    borderRadius: 0,
                    padding: '0.65rem 0.911rem !important',
                }}
            >
                1
            </PaginationLink>
        </PaginationItem>,
    ];
    if (currentPage > 3) {
        pageLinks.push(
            <PaginationItem
                style={{ borderRadius: 0, padding: 0 }}
                disabled
                key="pagination-...-1"
            >
                <PaginationLink
                    style={{
                        borderRadius: 0,
                        padding: '0.65rem 0.911rem !important',
                    }}
                >
                    ...
                </PaginationLink>
            </PaginationItem>
        );
    }
    for (
        let page = currentPage - 1;
        page <= (currentPage + 1 >= lastPage ? lastPage : currentPage + 1);
        page++
    ) {
        if (page <= 1 || page >= lastPage) continue;
        pageLinks.push(
            <PaginationItem
                style={{ borderRadius: 0, padding: 0 }}
                active={currentPage === page}
                key={`pagination-${page}`}
            >
                <PaginationLink
                    onClick={() => changePage(page)}
                    style={{
                        borderRadius: 0,
                        padding: '0.65rem 0.911rem !important',
                    }}
                >
                    {page}
                </PaginationLink>
            </PaginationItem>
        );
    }
    if (currentPage < lastPage - 2) {
        pageLinks.push(
            <PaginationItem
                style={{ borderRadius: 0, padding: 0 }}
                disabled
                key="pagination-...-2"
            >
                <PaginationLink
                    style={{
                        borderRadius: 0,
                        padding: '0.65rem 0.911rem !important',
                    }}
                >
                    ...
                </PaginationLink>
            </PaginationItem>
        );
    }
    if (lastPage > 1) {
        pageLinks.push(
            <PaginationItem
                style={{ borderRadius: 0, padding: 0 }}
                active={currentPage === lastPage}
                key={`pagination-${lastPage}`}
            >
                <PaginationLink
                    onClick={() => changePage(lastPage)}
                    style={{
                        borderRadius: 0,
                        padding: '0.65rem 0.911rem !important',
                    }}
                >
                    {lastPage}
                </PaginationLink>
            </PaginationItem>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Pagination aria-label="Page navigation">{pageLinks}</Pagination>
        </div>
    );
};

export default CustomPagination;
