import Cookies from 'js-cookie';
import { history } from '../../../history';
import clientApi from '../../../api/clientApi';
import { showErrorToast } from '../../../utility/toast';

export const changeRole = (role) => {
    return (dispatch) => dispatch({ type: 'CHANGE_ROLE', userRole: role });
};

export const forgotPassword = (email) => (dispatch) => {
    dispatch({
        type: 'FORGOT_PASSWORD',
    });
};

export const signin = ({ username, password }) => async (dispatch) => {
    dispatch({ type: 'AUTHENTICATING' });
    try {
        const authResponse = await clientApi.post(
            '/authentication/authenticate',
            {
                username,
                password,
            }
        );

        if (authResponse.status !== 200) {
            throw new Error('Login failed');
        }

        Cookies.set(
            'iservice_member_app_user',
            JSON.stringify(authResponse.data.user)
        );
        Cookies.set('iservice_member_app_token', authResponse.data.token, {
            expires: 8 / 24,
        });

        dispatch({
            type: 'SIGNIN',
            payload: authResponse.data.token,
            user: authResponse.data.user,
            username,
        });
        dispatch(changeRole(authResponse.data.user.role));

        const loginLink = Cookies.get('login_link_route_path')

        // eslint-disable-next-line no-restricted-globals
        history.push(location.state?.requestedPath ?? loginLink ?? '/iservice/advisor');

        Cookies.remove('login_link_route_path')
    } catch (error) {
        dispatch(signout());

        showErrorToast({
            message:
                'Invalid username or password. Please check your login credentials and try again.',
        });
        dispatch({
            type: 'ADD_ERROR',
            payload: 'Something went wrong with login',
        });
    }
};

export const signout = () => (dispatch) => {
    dispatch({ type: 'RESET_AUTH_STATE' });
    dispatch({ type: 'RESET_MEMBER_STATE' });
    Cookies.remove('iservice_member_app_token');
    Cookies.remove('iservice_member_app_user');
    dispatch({ type: 'SIGNOUT' });
};

export const tryLocalSignIn = () => (dispatch) => {
    dispatch({ type: 'RESET_AUTH_STATE' });
    dispatch({ type: 'RESET_MEMBER_STATE' });
    dispatch({ type: 'AUTHENTICATING' });
    try {
        const token = Cookies.get('iservice_member_app_token');
        const user = JSON.parse(Cookies.get('iservice_member_app_user'));

        if (token && user) {
            dispatch({
                type: 'SIGNIN',
                payload: token,
                user,
                username: user.email,
            });
            dispatch(changeRole(user.role));
        } else dispatch(signout());
    } catch (error) {
        dispatch(signout());
        dispatch({
            type: 'ADD_ERROR',
            payload: 'Something went wrong with stored credentials',
        });
    }
};
