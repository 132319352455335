import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import AutoCreateInputItems from './AutoCreateInputItems';
import CheckboxInput from '../atoms/form-inputs/CheckboxInput';
import CustomSwitch from '../atoms/form-inputs/CustomSwitch';
import ImageFileInput from '../atoms/form-inputs/ImageFileInput';
import SelectInput from '../atoms/form-inputs/SelectInput';
import TextareaInput from '../atoms/form-inputs/TextareaInput';
import TextInput from '../atoms/form-inputs/TextInput';
import NumberInput from '../atoms/form-inputs/NumberInput';
import PaymentActions from '../atoms/form-inputs/PaymentActions';
import PercentageInput from '../atoms/form-inputs/PercentageInput';
import VideoFileInput from '../atoms/form-inputs/VideoFileInput';
import WrappedMoneyInput from '../atoms/form-inputs/WrappedMoneyInput';
import IconButton from '../atoms/IconButton';
import ConfirmationModal from '../modals/ConfirmationModal';
import validator from '../../../utility/helpers/validation';
import CustomerAsyncSelect from '../molecules/CustomerAsyncSelect';
import ColorPicker from '../molecules/ColorPicker';

const CustomForm = ({
    cancelFlag,
    fields,
    handleCancel,
    handleDelete,
    handleSubmit,
    record,
    requestParams,
    setCancelFlag = () => null,
    updateChanges = () => null,
    setShowConfirmCancel,
    submitChangesOnly = false,
    toggle,
}) => {
    const [formData, setFormData] = useState(record || {});
    const [areChanged, setAreChanged] = useState([]);
    const [areInvalid, setAreInvalid] = useState([]);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(
        false
    );
    const [placeholder, setplaceholder] = useState();

    useEffect(() => {
        if (record) {
            setFormData(record);
            setAreChanged([]);
            return;
        }
        let data = {};
        let changed = [];
        fields.forEach((field) => {
            if (field.selector && field.formOptions?.default !== undefined) {
                data[field.selector] = field.formOptions?.default;
                changed.push(field.selector);
            }
        });
        setAreChanged(changed);
        setFormData(data);
    }, [record]);

    const handleChange = (key, value) => {
        const updated = Object.assign({}, formData, { [key]: value });
        setFormData(updated);
        setCancelFlag(true);
        updateChanges(key, value);

        if (!areChanged.includes(key)) {
            setAreChanged([...areChanged, key]);
        } else if (
            record &&
            (record[key] === value ||
                (record[key] === undefined && value === ''))
        ) {
            const filtered = areChanged.filter((item) => item !== key);
            setAreChanged(filtered);
        }
    };

    const validateForm = (enableToast = true) => {
        const invalidFields = fields
            .filter((field) => {
                const value = formData[field.selector];
                return (
                    !!field.formOptions?.validator &&
                    !validator({
                        type: field.formOptions?.validator,
                        value,
                        enableToast,
                    })
                );
            })
            .map((field) => field.selector);
        setAreInvalid(invalidFields);

        // Returns true for valid
        return !invalidFields.length;
    };

    const inputs = fields.map((field) => {
        const { name, formOptions, selector, type } = field;

        // Nameless and/or selectorless fields are hidden in forms, even if hidden=false in formOptions
        if (!selector || !name || formOptions?.hidden) {
            return null;
        }
        if (field.custom) {
            return <div key={selector}>{field.custom}</div>;
        }
        const maximumLength = field.maxLength;
        const value = field.hasOwnProperty('value')
            ? field.value(record)
            : formData[selector];
        const required = !!formOptions?.required;
        const isValueValid = !areInvalid.includes(selector);
        const isChanged = areChanged.includes(selector);
        const InputComponent =
            formOptions?.hideOnCreate && !record?.id
                ? null
                : formOptions?.hideOnEdit && !!record?.id
                ? null
                : type === 'image'
                ? ImageFileInput
                : type === 'select'
                ? SelectInput
                : type === 'checkbox'
                ? CheckboxInput
                : type === 'video'
                ? VideoFileInput
                : type === 'number'
                ? NumberInput
                : type === 'array'
                ? AutoCreateInputItems
                : type === 'textarea'
                ? TextareaInput
                : type === 'customer'
                ? CustomerAsyncSelect
                : type === 'percentage'
                ? PercentageInput
                : type === 'money'
                ? WrappedMoneyInput
                : type === 'payment-actions'
                ? PaymentActions
                : type === 'switch'
                ? CustomSwitch
                : type === 'color'
                ? ColorPicker
                : TextInput;

        if (!InputComponent) return null;

        return (
            <FormGroup key={selector}>
                <Label for={selector} className="settings-label">
                    {name}
                    {required && <span className="text-danger">*</span>}
                </Label>
                <InputComponent
                    canCreateNew
                    disabled={!!formOptions?.disabled}
                    field={field}
                    handleBlur={(e) => e.preventDefault()}
                    handleChange={handleChange}
                    handleSelectCustomer={(customer) => {
                        setplaceholder(customer.name);
                        handleChange(field.selector, customer.id);
                    }}
                    isChanged={isChanged}
                    isValid={isValueValid}
                    maximumLength={maximumLength}
                    options={field.formOptions?.selectOptions}
                    placeholder={placeholder}
                    requestParams={requestParams}
                    required={required}
                    record={record}
                    setSelectedOption={(e) => handleChange('id', e.id)}
                    toggle={toggle}
                    value={value}
                />
            </FormGroup>
        );
    });

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                if (!areChanged.length || !validateForm()) return;
                setCancelFlag(false);
                if (submitChangesOnly) {
                    const changesOnly = areChanged.reduce((acc, key) => {
                        acc[key] = formData[key];
                        return acc;
                    }, {});
                    if (formData.id) {
                        changesOnly.id = formData.id;
                    }
                    return handleSubmit(changesOnly, fields);
                }
                handleSubmit(formData, fields);
            }}
        >
            <ConfirmationModal
                handleConfirm={() => {
                    setIsConfirmationModalOpen(false);
                    handleDelete(record);
                }}
                isOpen={isConfirmationModalOpen}
                message="Are you sure you want to delete this item?"
                toggle={() => setIsConfirmationModalOpen(false)}
            />
            {inputs}
            {!!areInvalid.length && (
                <div className="text-danger">
                    One or more fields contains invalid info.
                </div>
            )}
            <div className="d-flex justify-content-between">
                {!!handleDelete && (
                    <span
                        className="text-danger"
                        color="danger"
                        onClick={() => setIsConfirmationModalOpen(true)}
                        style={{ cursor: 'pointer' }}
                    >
                        <IconButton
                            style={{ alignItems: 'center' }}
                            type="delete"
                        />
                    </span>
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}
                >
                    {!!handleCancel && (
                        <Button
                            className="mr-2"
                            color="secondary"
                            onClick={() =>
                                cancelFlag
                                    ? setShowConfirmCancel(true)
                                    : handleCancel()
                            }
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        color="success"
                        disabled={!areChanged.length}
                        type="submit"
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default CustomForm;
