import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SearchableAsyncSelect from '../atoms/SearchableAsyncSelect';
import { getUsers } from '../../../redux/actions/member';
import getMyUser from '../../../utility/helpers/getMyUser';

const CustomerAsyncSelect = ({
    cancelAutoFocus,
    getUsers,
    pageLimit = 10,
    placeholder = 'Search',
    reset = null,
    handleSelectedOption,
    sortField,
    ...rest
}) => {
    const [userId, setUserId] = useState();

    useEffect(() => {
        const user = getMyUser();
        setUserId(user.id);
    }, []);

    const usersReducer = (total, user) => {
        if (user.id === userId) return total; // Skip displaying logged in user
        const label = `${user.id} - ${user.firstName} ${user.lastName}`;
        total.push({ label, value: user });
        return total;
    };

    return (
        <SearchableAsyncSelect
            cancelAutoFocus={cancelAutoFocus}
            getOptions={getUsers}
            optionsReducer={usersReducer}
            pageLimit={pageLimit}
            placeholder={placeholder}
            reset={reset}
            handleSelectedOption={handleSelectedOption}
            sortField={sortField}
            {...rest}
        />
    );
};

export default connect(null, { getUsers })(CustomerAsyncSelect);
