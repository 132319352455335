import React from 'react';
import { Button, Modal } from 'reactstrap';
import CustomModalBody from '../atoms/CustomModalBody';
import CustomModalFooter from '../atoms/CustomModalFooter';
import CustomModalHeader from '../atoms/CustomModalHeader';

const ConfirmationModal = ({
    handleConfirm,
    handleNo,
    hideCancel,
    isOpen,
    message = 'Are you sure?',
    title = 'This action cannot be undone',
    toggle,
}) => {
    return (
        <Modal isOpen={isOpen} keyboard scrollable toggle={toggle}>
            <CustomModalHeader title={title} toggle={toggle} />
            <CustomModalBody>{message}</CustomModalBody>
            <CustomModalFooter>
                <div className="w-100">
                    {!hideCancel && (
                        <Button
                            color="secondary"
                            onClick={toggle}
                            style={{ float: 'left' }}
                        >
                            Cancel
                        </Button>
                    )}
                    <div style={{ float: 'right' }}>
                        {!!handleNo && (
                            <Button
                                className="mr-1"
                                color="danger"
                                onClick={handleNo}
                            >
                                No
                            </Button>
                        )}
                        <Button color="success" onClick={handleConfirm}>
                            Yes
                        </Button>
                    </div>
                </div>
            </CustomModalFooter>
        </Modal>
    );
};

export default ConfirmationModal;
