import { format } from '@jmdigital/iservice3-utils';
import { Button, Modal } from 'reactstrap';
import React from 'react';
import { connect } from 'react-redux';
import CustomModalBody from '../atoms/CustomModalBody';
import CustomModalFooter from '../atoms/CustomModalFooter';
import CustomModalHeader from '../atoms/CustomModalHeader';
import AutoCrud from '../organisms/AutoCrud';

const ClosedRoNoVideoModal = ({
    data,
    isOpen,
    toggle,
    requestParams,
    user,
}) => {
    const fieldsConfig = [
        {
            cell: (row) => format.datetime(row.dateClosed),
            name: 'Date',
            selector: 'dateClosed',
        },
        {
            name: 'RO Number',
            selector: 'number',
        },
        {
            cell: (row) =>
                `${row.primaryAdvisor?.firstName} ${row.primaryAdvisor?.lastName}`,
            name: 'Advisor Name',
            selector: 'primaryAdvisor',
            exportValueGetter: (row) => `${row.primaryAdvisor.firstName} ${row.primaryAdvisor.lastName}`
        },
        {
            cell: (row) =>
                `${row.primaryTechnician?.firstName ?? 'None Assigned'} ${
                    row.primaryTechnician?.lastName ?? ''
                }`,
            name: 'Technician Name',
            selector: 'primaryTechnician',
            exportValueGetter: (row) => !!row.primaryTechnician ? `${row.primaryTechnician.firstName} ${row.primaryTechnician.lastName}` : 'None Assigned'
        },
    ];

    const title = `${user?.name} - Closed ROs without Video`;
    return (
        <Modal isOpen={isOpen} size="lg" toggle={toggle}>
            <CustomModalHeader title={title} toggle={toggle} />
            <CustomModalBody>
                <AutoCrud
                    data={data || {}}
                    disableSearch
                    entityName="No Videos Report"
                    fields={fieldsConfig}
                    forceClear
                    requestParams={requestParams}
                    stateProp="ros"
                />
            </CustomModalBody>
            <CustomModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </CustomModalFooter>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    data: state.member.ros,
    openModal: state.member.openModal,
    selectedRow: state.member.selectedRow,
});

export default connect(mapStateToProps)(ClosedRoNoVideoModal);
