import React from 'react';
import { CardHeader } from 'reactstrap';
import { X, ArrowLeftCircle, ArrowRightCircle } from 'react-feather';

const styles = {
    xStyle: {
        cursor: 'pointer',
        fontWeight: 'bold',
        marginTop: '-1em',
    },
};

const CustomModalHeader = ({ back, skip, subtitle, title, toggle }) => {
    return (
        <CardHeader className="align-items-center card-header d-flex font-weight-bold h4 justify-content-between pb-1 text-center ">
            {!!back && (
                <ArrowLeftCircle
                    className="mr-1 float-left"
                    onClick={back}
                    size={25}
                />
            )}
            {title}
            {!!subtitle && (
                <h5 className="text-secondary mb-0 mt-1">{subtitle}</h5>
            )}
            {!!toggle && (
                <X
                    className="float-right"
                    onClick={toggle}
                    size={25}
                    style={styles.xStyle}
                />
            )}
            {!!skip && (
                <ArrowRightCircle
                    className="ml-1 float-right"
                    onClick={skip}
                    size={25}
                />
            )}
        </CardHeader>
    );
};

export default CustomModalHeader;
