import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { showErrorToast } from '../../../utility/toast';
import { getPayments, makePayment } from '../../../redux/actions/member';
import { makePayment as makePaymentNonRO } from '../../../redux/actions/member/payments-nonro';

const PaymentModal = ({
    getPayments,
    isForRo,
    makePayment,
    makePaymentNonRO,
    payment,
    repairOrder,
    toggle,
    toggleParent,
    type,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const finishSubmit = async (response) => {
        if (isForRo) {
            await makePayment(payment.id, response.token);
            getPayments(repairOrder.id);
        } else {
            await makePaymentNonRO(payment.id, response.token, type);
            if (toggleParent) toggleParent();
        }

        setIsSubmitting(false);
        toggle();
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setIsSubmitting(true);
        window.CollectJS.startPaymentRequest();
    };

    useEffect(() => {
        if (!payment || !repairOrder) return;

        setIsSubmitting(false);

        if (!window.CollectJS) {
            return showErrorToast({
                message:
                    'Payment system unavailable. Please contact iService support.',
            });
        }

        window.CollectJS.configure({
            variant: 'lightbox',
            callback: (token) => {
                finishSubmit(token);
            },
        });
    }, [payment, repairOrder]);

    return (
        <>
            <Button
                block
                color="success"
                type="submit"
                disabled={isSubmitting || !window.CollectJS}
                onClick={handleSubmit}
            >
                Confirm Amount and Enter Credit Card
            </Button>
        </>
    );
};

export default connect(null, {
    getPayments,
    makePayment,
    makePaymentNonRO,
})(PaymentModal);
