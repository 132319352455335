import { format } from '@jmdigital/iservice3-utils';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setprintableDoc } from '../../../redux/actions/member';

const PrintableDoc = ({
    children,
    repairOrder = {},
    setprintableDoc,
    settings = {},
    typeText,
}) => {
    const history = useHistory();

    useEffect(() => {
        window.onafterprint = () => {
            const printElem = document.getElementById('print-print');
            printElem.innerHTML = '';
        };
        print();
        return () => (window.onafterprint = null);
    }, []);

    const printDocument = () => {
        window.print();
        setprintableDoc(null);
        history.goBack();
    };

    const {
        generalAddress = '',
        generalAddress2 = '',
        generalCity = '',
        generalEmail = '',
        generalLogo = '',
        generalName,
        generalPhone = '',
        generalState = '',
        generalWebsiteUrl = '',
        generalZip = '',
    } = settings;
    const {
        make = '',
        model = '',
        primaryAdvisor,
        primaryCustomer,
        primaryTechnician,
        vin = '',
        year = '',
    } = repairOrder;

    const print = () => {
        const printElem = document.getElementById('print-print');
        const printableDoc = document.getElementById('printable-doc');

        printElem.innerHTML = printableDoc.innerHTML;
        printDocument();
    };

    return (
        <div id="printable-doc">
            <div
                style={{
                    position: 'absolute',
                    background: '#fff',
                    color: '#000',
                    top: 0,
                    left: 0,
                    minHeight: '100vh',
                    width: '100vw',
                    zIndex: 99999,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {!!generalName && (
                        <>
                            <div style={{ minWidth: '33%' }}>
                                <h3>{generalName}</h3>
                                <div>{generalAddress}</div>
                                {!!generalAddress2 && (
                                    <div>{generalAddress2}</div>
                                )}
                                <div>{`${generalCity}, ${generalState} ${generalZip}`}</div>
                                <div style={{ marginTop: '1em' }}>
                                    {generalWebsiteUrl}
                                </div>
                                <div>{generalEmail}</div>
                                <div>{format.phoneNumber(generalPhone)}</div>
                            </div>
                            {!!generalLogo && (
                                <img
                                    style={{
                                        maxHeight: '150px',
                                        maxWidth: '33%',
                                    }}
                                    src={generalLogo}
                                />
                            )}
                        </>
                    )}
                    {!!primaryCustomer && (
                        <div style={{ textAlign: 'right', minWidth: '33%' }}>
                            <div>
                                <strong>{typeText} prepared for:</strong>
                            </div>
                            <div>{primaryCustomer.name}</div>
                            <div>
                                {format.phoneNumber(primaryCustomer.phone)}
                            </div>
                            <div style={{ marginTop: '1em' }}>
                                <div>{`${year || ''} ${make || ''} ${
                                    model || ''
                                }`}</div>
                                <div>
                                    {!!vin && <span>{`VIN: ${vin}`}</span>}
                                </div>
                            </div>
                            <div style={{ marginTop: '1em' }}>
                                {!!primaryAdvisor && (
                                    <strong>{`Service Advisor: ${
                                        primaryAdvisor.firstName || ''
                                    } ${
                                        primaryAdvisor.lastName || ''
                                    }`}</strong>
                                )}
                            </div>
                            <div>
                                {!!primaryTechnician && (
                                    <strong>{`Technician: ${
                                        primaryTechnician.firstName || ''
                                    } ${
                                        primaryTechnician.lastName || ''
                                    }`}</strong>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <h3 className="text-center">{typeText}</h3>
                <div style={{ marginTop: '1em' }}>{children}</div>
            </div>
        </div>
    );
};

export default connect(null, { setprintableDoc })(PrintableDoc);
