const initialState = {
    errorMessage: '',
    isSignout: false,
    username: '',
    userRole: '',
    user: {},
    token: '',
};

export const login = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTHENTICATING': {
            return { ...state, isAuthenticating: true };
        }
        case 'GETTING_INIT_SETTINGS': {
            return state;
        }
        case 'CHANGE_ROLE': {
            return { ...state, userRole: action.userRole };
        }
        case 'RESET_AUTH_STATE':
            return initialState;
        case 'SIGNIN': {
            return {
                ...state,
                errorMessage: '',
                isAuthenticating: false,
                token: action.payload,
                user: action.user,
                username: action.username,
            };
        }
        case 'SIGNOUT': {
            return {
                ...state,
                isAuthenticating: false,
                isSignout: true,
                errorMessage: '',
                token: null,
                user: {},
                username: '',
                userRole: '',
            };
        }
        case 'ADD_ERROR': {
            return {
                ...state,
                errorMessage: action.payload,
                isAuthenticating: false,
            };
        }
        default: {
            return state;
        }
    }
};
