import React from 'react';
import { ModalBody } from 'reactstrap';

const styles = {
    defaultBg: {
        backgroundColor: '#fff',
    },
};

const CustomModalBody = ({ additionalProps, children }) => {
    return (
        <ModalBody
            {...additionalProps}
            className="pt-2"
            style={styles.defaultBg}
        >
            {children}
        </ModalBody>
    );
};

export default CustomModalBody;
