import moment from 'moment';
import {
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setFilters } from '../../../redux/actions/member';
import AdvisorFilterItem from '../atoms/AdvisorFilterItem';
import DateFilterItem from '../atoms/DateFilterItem';
import SwitchFilterItem from '../atoms/SwitchFilterItem';
import TechnicianFilterItem from '../atoms/TechnicianFilterItem';
import MpiFilterItem from '../atoms/MpiFilterItem';

const blacklistedFilterNameList = [
    'mpiItemId',
    'mpiItemStatus'
]

const DashboardsRepairOrderFilter = ({
    filters,
    filterOptions,
    handleChangePage,
    setFilters,
    stateProp,
}) => {
    useEffect(() => {
        const initialFilters = filterOptions.filter((filterOption) => {
            // This sets the default date range to MTD
            if (
                filterOption.name.includes('date') &&
                Array.isArray(filterOption.value)
            ) {
                filterOption.value = [
                    moment().startOf('month').toDate(),
                    moment().endOf('month').toDate(),
                ];
            }

            return filterOption.isAddedByDefault;
        });

        setFilters(initialFilters);
    }, [filterOptions, setFilters]);

    const handleChange = (oldFilter, newFilter) => {

        const updated = filters.map((filter) => {
            return filter === oldFilter ? newFilter : filter;
        });

        if(newFilter.name === 'open' && newFilter.value === false){
           const monthToDateFilter = { name: 'dateRange', value: [moment().startOf('month').toDate(), moment().toDate()], label: 'By Date' }
           setFilters([...updated, monthToDateFilter]);
           return
        }

        setFilters(updated);
    };

    const handleMpiTemplateChange = (oldFilter, newFilter) => {

        // remove mpi item and status filters
        const toDelete = ['mpiItemId', 'mpiItemStatus'];
        let updated = filters.filter((filter) => {
            return !toDelete.includes(filter.name);
        });

        updated = updated.map((filter) => {
            return filter === oldFilter ? newFilter : filter;
        });

        setFilters(updated);
    };

    const handleDelete = (toDelete) => {
        let updated;

        if (Array.isArray(toDelete)) {
              
            updated = filters.filter((filter) => {
                return !toDelete.find((toDeleteFilter) => {
                    return filter.name === toDeleteFilter.name;
                })
            });
              
        } else {
            updated = filters.filter((filter) => filter !== toDelete);
        }

        handleChangePage(1);
        setFilters(updated);
    };

    const handleAdd = (toAdd) => {
        let updated;
        if (Array.isArray(toAdd)) {
            updated = filters.concat(toAdd)
        } else {
            updated = [...filters, toAdd];
        }
        handleChangePage(1);
        setFilters(updated);
    };

    const filterOptionsList = filterOptions.map((filter, index) => {
        const disabled = !!filters.find((cur) => cur.name === filter.name);

        return (
            <DropdownItem
                key={`filter-option-${index}`}
                disabled={disabled}
                onClick={() => handleAdd(filter)}
            >
                {filter.label}
            </DropdownItem>
        );
    });

    return (
        <div className="d-flex justify-content-start mt-1 w-100">
            <div>
                <UncontrolledButtonDropdown
                    className="dropdown"
                    style={{ height: '48px' }}
                >
                    <DropdownToggle color="outline-primary" caret>
                        Add Filter
                        <ChevronDown size={16} />
                    </DropdownToggle>
                    <DropdownMenu>{filterOptionsList}</DropdownMenu>
                </UncontrolledButtonDropdown>
            </div>
            <div
                className={`ml-1 text-center ${
                    filters.length === 0 ? 'd-none' : 'bg-white'
                } w-100`}
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >
                {filters.map((item, index) => {
                    if (blacklistedFilterNameList.includes(item.name)) return;
                    return item.name.includes('date') ? (
                        <DateFilterItem
                            key={`filter-item-${index}-${stateProp}`}
                            handleChange={handleChange}
                            handleDelete={handleDelete}
                            item={item}
                        />
                    ) : item.name.toLowerCase().includes('technician') ? (
                        <TechnicianFilterItem
                            key={`filter-item-${index}-${stateProp}`}
                            handleChange={handleChange}
                            handleDelete={handleDelete}
                            item={item}
                        />
                    ) : item.name.toLowerCase().includes('advisor') ? (
                        <AdvisorFilterItem
                            key={`filter-item-${index}-${stateProp}`}
                            handleChange={handleChange}
                            handleDelete={handleDelete}
                            item={item}
                        />
                    ) : item.name.includes('mpiTemplateId') ? (
                        <MpiFilterItem
                            key={`filter-item-${index}-${stateProp}`}
                            handleChange={handleMpiTemplateChange}
                            handleDelete={handleDelete}
                            handleAdd={handleAdd}
                            item={item}
                        />
                    ) : (
                        <SwitchFilterItem
                            key={`filter-item-${index}-${stateProp}`}
                            handleChange={handleChange}
                            handleDelete={handleDelete}
                            item={item}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    filters: state.member.filters,
});
export default connect(mapStateToProps, { setFilters })(
    DashboardsRepairOrderFilter
);
