import React from 'react';
import { Input } from 'reactstrap';

const TextInput = ({
    disabled,
    field,
    handleBlur,
    handleChange,
    isChanged,
    isValid,
    maximumLength,
    required,
    value,
}) => {
    return (
        <Input
            disabled={disabled}
            style={{
                boxShadow: isValid ? 'none' : '0 0 0 2px red',
            }}
            className={`form-control ${
                !isValid ? 'border-danger' : isChanged ? 'border-warning' : ''
            }`}
            id={field.selector}
            onBlur={handleBlur}
            onChange={({ target }) =>
                handleChange(field.selector, target.value)
            }
            maxLength={maximumLength ? maximumLength : null}
            required={required}
            type="text"
            value={value || ''}
        />
    );
};

export default TextInput;
