import { toast } from 'react-toastify';

export const showToast = ({ message = '', type = 'success' }) => {
    if (document.hidden) return;
    toast[type](message, {
        position: 'top-right',
        autoClose: type === 'error' ? 10000 : type === 'info' ? 2000 : 4000,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
    });
};

export const showErrorToast = (err) => {
    const maxMsgLength = 256;

    if (!('response' in err || 'message' in err)) return;

    if (Array.isArray(err.response?.data)) {
        return err.response.data.forEach((errObj) => {
            if (
                typeof errObj.msg === 'string' &&
                errObj.msg.length < maxMsgLength
            ) {
                showToast({ message: errObj.msg, type: 'error' });
            }
        });
    }

    if (err.response?.status === 404) {
        return showToast({ message: 'Resource not found', type: 'error' });
    }

    if (
        typeof err.response?.data === 'string' &&
        err.response.data.length < maxMsgLength
    ) {
        return showToast({ message: err.response.data, type: 'error' });
    }

    if (typeof err.message === 'string' && err.message.length < maxMsgLength) {
        return showToast({ message: err.message, type: 'error' });
    }

    showToast({
        message:
            'Something went wrong. Please contact support if the problem persists.',
        type: 'error',
    });
};
