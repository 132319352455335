import { handleError } from './index';
import { showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';

export const deleteVideo = (roId, videoId) => async (dispatch) => {
    dispatch({ type: 'DELETE_RECORD' });

    try {
        const response = await clientApi.delete(
            `${Endpoints.roVideo}/${videoId}`
        );

        if (response.status === 200) {
            showToast({ message: 'Successfully deleted' });
            dispatch({ type: 'RECORD_DELETED' });
            dispatch(getVideosByRoId(roId));
            dispatch({ type: 'RO_OUTDATED' });
        } else {
            throw new Error(`Failed to delete video.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const getVideosByRoId = (roId) => async (dispatch) => {
    dispatch({ type: 'GET_VIDEOS_BY_RO_ID' });

    try {
        const response = await clientApi.get(
            `${Endpoints.roVideo}?repairOrderID=${roId}`
        );

        if (response.status === 200) {
            dispatch({ type: 'RECEIVE_VIDEOS_BY_RO_ID', data: response.data });
        } else {
            throw new Error(`Failed to get video(s).${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const resendVideo = (repairOrderVideoID) => async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    dispatch({ type: 'RESEND_VIDEO' });
    const endpoint = '/repair-order-video/send';

    try {
        const response = await clientApi.post(`${Endpoints.resendVideo}`, {
        // const response = await clientApi.post(`${endpoint}`, {
            repairOrderVideoID,
        });
        if (response.status === 200) {
            showToast({ message: 'Successfully Sent Video' });
            dispatch({ type: 'VIDEO_SENT' });
            dispatch({ type: 'LOADING_END' });
        } else {
            throw new Error(`Failed to send video.${CONTACT_MESSAGE}`);
        }
        return response;
    } catch (error) {
        return dispatch(handleError(error));
    }
};
