import { format } from '@jmdigital/iservice3-utils';
import { getRecords, handleError } from './index';
import { showErrorToast, showToast } from '../../../utility/toast';
import clientApi from '../../../api/clientApi';
import { CONTACT_MESSAGE, Endpoints } from '../../../utility/constants';

export const confirmPayment = (paymentId, type) => async (dispatch) => {
    dispatch({ type: 'CONFIRM_PAYMENT' });
    const endpoint = `${Endpoints.paymentsNonRO}/confirm`;

    try {
        const body = { id: paymentId };
        const response = await clientApi.post(endpoint, body);
        if (response.status !== 200) {
            throw new Error(`Failed to confirm payment.${CONTACT_MESSAGE}`);
        }

        dispatch({ type: 'PAYMENT_CONFIRMED' });
        if (type) dispatch(getRecords('paymentsNonRO', { type }));
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const getPayment = (linkHash) => async (dispatch) => {
    dispatch({ type: 'GET_PAYMENTS' });
    dispatch({ type: 'LOADING_START' });

    try {
        const response = await clientApi.get(
            `${Endpoints.paymentsNonRO}/link-hash/${linkHash}`
        );

        if (response.status === 200) {
            dispatch({ type: 'RECEIVE_PAYMENT', data: response.data });
            dispatch({ type: 'LOADING_END' });
        } else {
            throw new Error(`Failed to get payments.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const makePayment = (paymentId, paymentToken, type, card) => async (
    dispatch
) => {
    dispatch({ type: 'MAKE_PAYMENT' });
    dispatch({ type: 'LOADING_START' });

    try {
        const endpoint = `${Endpoints.paymentsNonRO}/pay`;
        const body = { id: paymentId, paymentToken };

        if (card) {
            body.cardNumber = card.number;
            body.cardType = card.cardType;
        }

        const response = await clientApi.post(endpoint, body);

        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });
            showToast({ message: 'Payment success' });

            dispatch(confirmPayment(paymentId, type));
        } else {
            throw new Error(`Failed to send payment.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const paymentFormErrored = () => (dispatch) => {
    showErrorToast({
        message:
            'Unable to contact payments server. To enable payments, try refreshing the page in a few minutes. If you continue to see this message, please contact support.',
    });
    dispatch({ type: 'PAYMENT_FORM_ERRORED' });
};

export const paymentFormLoaded = () => (dispatch) => {
    dispatch({ type: 'PAYMENT_FORM_LOADED' });
};

export const refundPayment = (paymentId, amountCents, type) => async (
    dispatch
) => {
    dispatch({ type: 'REFUND_PAYMENT' });
    dispatch({ type: 'LOADING_START' });

    try {
        const endpoint = `${Endpoints.paymentsNonRO}/refund`;
        const amount = (amountCents / 100).toString();
        const body = {
            id: paymentId,
            amount,
        };
        const response = await clientApi.post(endpoint, body);
        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });
            showToast({ message: `${format.money(amount)} refunded` });

            dispatch(getRecords('paymentsNonRO', { type }));
        } else {
            throw new Error(`Failed to refund payment.${CONTACT_MESSAGE}`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const sendPaymentRequest = (paymentId, type) => async (dispatch) => {
    dispatch({ type: 'SEND_PAYMENT_REQUEST' });
    dispatch({ type: 'LOADING_START' });

    try {
        const endpoint = `${Endpoints.paymentsNonRO}/send`;
        const body = { id: paymentId };
        const response = await clientApi.post(endpoint, body);

        if (response.status === 200) {
            dispatch({ type: 'LOADING_END' });
            showToast({ message: 'Payment request sent' });

            dispatch(getRecords('paymentsNonRO', { type }));
        } else {
            throw new Error(
                `Failed to send payment request.${CONTACT_MESSAGE}`
            );
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};
