import { format } from '@jmdigital/iservice3-utils';
import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import ClosedRoNoVideoModal from '../modals/ClosedRoNoVideoModal';
import { getAdvisorReport } from '../../../redux/actions/member';
import moment from 'moment';

const requestParams = { closed: true, startDate: moment().toDate(), endDate: moment().toDate() };

const QuickViewTable = ({ currentActiveGroup, getAdvisorReport }) => {
    const [user, setUser] = useState(null);
    const [data, setData] = useState([]);

    const getData = async () => {
        return await getAdvisorReport('advisorReport', requestParams);
    };

    useEffect(() => {
        if (!data.length) {
            getData().then((res) => {
                setData(res.data)
            });
        }
    }, []);

    return (
        <>
            <ClosedRoNoVideoModal
                toggle={() => setUser(null)}
                isOpen={!!user}
                user={user}
                requestParams={requestParams}
            />
            <div className='text-center mb-1 pt-1 font-weight-bold border-top-white '>Today's Quick View</div>
            <Table responsive size='sm' className='px-5'>
                <thead style={{ color: 'white' }}>
                    <tr>
                        <th>Advisor Name</th>
                        <th># Of Jobs</th>
                        {/* <th>Initial RO Total</th>
                        <th>Final RO Total</th> */}
                        <th>Sales</th>
                    </tr>
                </thead>
                <tbody style={{color: 'white'}} className='mr-2'>
                    {data?.results &&
                        data.results.map((row) => (
                            <tr key={row?.serviceAdvisor.id}>
                                <td className='my-1'>{`${row.serviceAdvisor?.firstName || ''} ${
                                    row.serviceAdvisor?.lastName || ''
                                }`}</td>
                                <td>{row.totalClosedRepairOrders}</td>
                                {/* <td>{row.totalStartValues}</td>
                                <td>{row.totalFinalValues}</td> */}
                                <td>{format.money(row.totalUpsellAmount)}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </>
    );
};

export default connect(null, { getAdvisorReport })(QuickViewTable);
