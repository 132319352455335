import React from 'react';
import { Badge } from 'reactstrap';

const ThreadItem = ({ handleSelectThread, thread, selectedRecipient }) => {
    return (
        <li
            onClick={() => handleSelectThread(thread)}
            className={`${
                thread.user?.id === selectedRecipient?.id ? 'active' : ''
            }`}
        >
            <div className="user-chat-info">
                <div className="contact-info">
                    <h5 className="mb-0">
                        {`${thread.user?.firstName || ''} ${
                            thread.user?.lastName || ''
                        }`}
                    </h5>
                    <p className="truncate">{thread.message?.message || ''}</p>
                </div>
                {!!parseInt(thread.unreads) && (
                    <div>
                        <Badge className="mr-1" color="danger">
                            {`${thread.unreads} New`}
                        </Badge>
                    </div>
                )}
            </div>
        </li>
    );
};

export default ThreadItem;
