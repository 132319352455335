import React from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/dark.css';

/*
 *   Props:
 *       type: string (default = 'single', 'multiple', 'range')
 *       handleDateChange: function
 *       startDate: Date (Date Object, Timestamps, ISO Date Strings, date strings in 'm-d-Y' format, 'today')
 *       endDate: Date (Date Object, Timestamps, ISO Date Strings, date strings in 'm-d-Y' format, 'today')
 *
 *       https://flatpickr.js.org/options/
 *
 *
 */

const DateRangePicker = ({
    endDate,
    handleDateChange,
    startDate,
    style = {},
    type,
}) => {
    const dateRange = endDate ? [startDate, endDate] : startDate;
    return (
        <Flatpickr
            style={style}
            onChange={handleDateChange}
            options={{
                dateFormat: 'm/d/Y',
                defaultDate: dateRange,
                mode: type,
            }}
        />
    );
};

export default DateRangePicker;
