import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';
import { baseURL } from '../utility/constants';

const clientApi = axios.create({ baseURL });

clientApi.interceptors.request.use(
    (config) => {
        const token = Cookies.get('iservice_member_app_token');
        if (token) config.headers.Authorization = `Bearer ${token}`;

        if (config.method === 'post') {
            config.transformRequest = (data) => {
                const formData = new FormData();
                for (const key in data) {
                    formData.append(key, data[key]);
                }
                return formData;
            };
        }

        if (config.method === 'put') {
            config.transformRequest = (data) => qs.stringify(data);
        }

        if (config.method === 'patch') {
            config.transformRequest = (data) => qs.stringify(data);
        }

        if (config.method === 'get') {
            config.paramsSerializer = (params) => qs.stringify(params);
        }

        return config;
    },
    (response) => response
);

export default clientApi;
