import { connect } from 'react-redux';
import React from 'react';
import { getUsers } from '../../../redux/actions/member';
import { UserRoles } from '../../../utility/constants';
import { X } from 'react-feather';
import SearchableAsyncSelect from './SearchableAsyncSelect';

const DashboardRoleFilterItem = ({
    item,
    handleChange,
    handleDelete,
    getUsers,
}) => {
    const userOptionsReducer = (total, user) => {
        const label = `${user.firstName} ${user.lastName}`;
        total.push({ label, value: user });
        return total;
    };

    return (
        <div
            className="d-flex align-items-center border-primary rounded mr-1"
            style={{
                padding: '0.25em',
                width: '300px',
                marginBottom: '5px',
            }}
        >
            <SearchableAsyncSelect
                className="w-100 text-left border-none mr-1"
                getOptions={getUsers}
                getOptionsParams={{
                    role: UserRoles.TECHNICIAN,
                }}
                optionsReducer={userOptionsReducer}
                cancelAutoFocus="true"
                placeholder={item.placeholder || 'Search Technicians'}
                handleSelectedOption={(value) => {
                    const fullName = `${value.firstName || ''}${
                        value.firstName ? ' ' : ''
                    }${value.lastName || ''}`;
                    const placeholder = fullName ? `By Tech: ${fullName}` : '';

                    handleChange(item, {
                        ...item,
                        value: value.id,
                        placeholder,
                    });
                }}
                size="small"
                sortField="id"
            />
            <X
                className="filter-item-x font-weight-bold"
                onClick={() => handleDelete(item)}
                size={20}
            />
        </div>
    );
};

export default connect(null, { getUsers })(DashboardRoleFilterItem);
