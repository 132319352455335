import { format } from '@jmdigital/iservice3-utils';
import React from 'react';
import { Button, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';

const MoneyInput = ({
    amountCents,
    buttonColor = 'primary',
    buttonDisabled = false,
    buttonText,
    inputDisabled = false,
    invalid = false,
    handleChange,
    handleClick,
    labelText = 'Amount',
    placeholder = '0.00',
    size = 'lg',
    width = '75',
}) => {
    const inputId = `pmt-input-${Math.random()}`;

    const valueFormatted = format.money(
        // There's such a thing as -0 and when we calculate total remaining balance, the total amountdue can be fractions of pennies more than total amount paid, which gives us `-$0.00`
        (Math.round(amountCents) === 0 ? 0 : amountCents) / 100
    );

    return (
        <>
            <Label for={inputId}>{labelText}</Label>
            <InputGroup className={`w-${width}`}>
                <Input
                    disabled={inputDisabled}
                    name={inputId}
                    id={inputId}
                    invalid={
                        invalid ||
                        isNaN(amountCents) ||
                        amountCents === undefined ||
                        amountCents === null
                    }
                    onChange={({ target }) => {
                        const integerCents = parseInt(
                            target.value.replace(/\D/g, '') || 0
                        );
                        if (integerCents >= 1000000000) return;

                        handleChange(integerCents);
                    }}
                    bsSize={size}
                    placeholder={placeholder}
                    value={valueFormatted}
                />
                {!!buttonText && !!handleClick && (
                    <InputGroupAddon addonType="append">
                        <Button
                            color={buttonColor}
                            disabled={buttonDisabled}
                            onClick={handleClick}
                        >
                            {buttonText}
                        </Button>
                    </InputGroupAddon>
                )}
            </InputGroup>
        </>
    );
};

export default MoneyInput;
